import styled from "@emotion/styled"

export const SecondaryButtonInline = styled.button({
  border: "none",
  background: "none",
  textAlign: "center",
  margin: "0 auto",
  textDecoration: "underline",
  color: "inherit",
  fontSize: "inherit",
  fontWeight: "inherit",
  cursor: "pointer",
  fontFamily: "'Barlow', sans-serif",
})

// Give this button a default type of "button"
SecondaryButtonInline.defaultProps = {
  type: "button",
}

export const MinimalSecondaryButton = styled(SecondaryButtonInline)({
  padding: 0,
  display: "flex",
  margin: 0,
  textDecoration: "none",
})
