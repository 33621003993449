import styled from "@emotion/styled"
import { useFormik } from "formik"
import {
  API_ROOT,
  USING_DEFAULT_API_ROOT,
  getApiRootFromLocalstorage,
  setApiRootInLocalstorage,
  clearApiRootInLocalstorage,
} from "../../utils/oviss"
import { Box } from "../../components/Box"
import { Text } from "../../components/Text"
import {
  PrimaryButton,
  CancelButton,
} from "../../components/Buttons/PrimaryButton"
import { SecondaryButton } from "../../components/Buttons/SecondaryButton"
import { NavLink } from "react-router-dom"
import { landingPath } from "../../paths"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"

export function ConfigScreen() {
  useDocumentTitle(`Config`)
  const formik = useFormik<{ apiRoot: string }>({
    initialValues: {
      apiRoot: getApiRootFromLocalstorage() || "",
    },
    onSubmit: ({ apiRoot }) => {
      setApiRootInLocalstorage(apiRoot)
      window.location.reload()
    },
  })

  return (
    <Box mh="sm">
      <Box mb="sm">
        <NavLink to={landingPath()}>Return to login page</NavLink>
      </Box>

      <form onSubmit={formik.handleSubmit}>
        <Box>
          {USING_DEFAULT_API_ROOT && (
            <Box mb="lg">
              <Text color="green" fontWeight="bold">
                Using default API root {API_ROOT}
              </Text>
            </Box>
          )}
          <Box display="flex">
            <label htmlFor="apiRoot">
              <Box display="flex">
                <Box mr="sm">API Root</Box>
                <input
                  type="text"
                  name="apiRoot"
                  id="apiRoot"
                  value={formik.values.apiRoot}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Box>
            </label>
            <StyledSecondaryButton
              onClick={() => {
                clearApiRootInLocalstorage()
                window.location.reload()
              }}
            >
              Use ENV default
            </StyledSecondaryButton>
          </Box>
        </Box>

        <Box display="flex" mt="xxl" justifyContent="space-between">
          <CancelButton onClick={() => formik.resetForm()}>
            Discard changes
          </CancelButton>

          <ButtonDivider />

          <PrimaryButton type="submit" disabled={!formik.dirty}>
            Save changes
          </PrimaryButton>
        </Box>
      </form>
    </Box>
  )
}

const StyledSecondaryButton = styled(SecondaryButton)({
  margin: 0,
})

const ButtonDivider = styled.div({
  width: "5%",
})
