import styled from "@emotion/styled"
import { useState } from "react"
import { RadioClearButton, RadioLine } from "../components/RadioLine"
import { calendar } from "../utils/icons"
import {
  getVisibleDateFilterOptions,
  OrderListGroup,
} from "../utils/orderListFilters"
import { formatDatetimeString } from "../utils/utils"
import { Box } from "./Box"
import { DateRangeInput } from "./DateRangeInput/DateRangeInput"
import { Text } from "./Text"

type DateRangeSelectorProps = {
  value: string
  name: string
  from: string
  to: string
  onCustomRangeSelected: any
  onClear: any
  values: Record<string, string>
  orderGroup: OrderListGroup
}

export function DateRangeSelector({
  value: selectedDateRangeType,
  name,
  onCustomRangeSelected,
  onClear,
  from,
  to,
  values,
  orderGroup,
}: DateRangeSelectorProps) {
  const [showRangeSelector, setShowRangeSelector] = useState(false)
  const visibleDateFilterOptions = getVisibleDateFilterOptions(orderGroup)

  return (
    <>
      {visibleDateFilterOptions.map(({ displayName, value }) => (
        <RadioLine
          name={name}
          key={value}
          displayName={displayName}
          value={value}
          checked={values[name] === value}
        />
      ))}

      <RadioLine
        name={name}
        displayName="Custom Date Range"
        value="custom"
        checked={selectedDateRangeType === "custom"}
        onClick={() => (!from || !to) && setShowRangeSelector(true)}
      />
      {selectedDateRangeType === "custom" &&
        (showRangeSelector ? (
          <DateRangeInput
            from={from}
            to={to}
            onSave={({
              from: newFrom,
              to: newTo,
            }: {
              from: string
              to: string
            }) => {
              onCustomRangeSelected({
                from: newFrom,
                to: newTo,
              })
              setShowRangeSelector(false)
            }}
            disabledDays={
              orderGroup === "history" ? { after: new Date() } : undefined
            }
          />
        ) : (
          from &&
          to && (
            <div onClick={() => setShowRangeSelector(true)}>
              <DateRangePreview from={from} to={to} />
            </div>
          )
        ))}
      {selectedDateRangeType !== "custom" && (
        <RadioClearButton onClick={onClear} displayName="Clear" />
      )}
    </>
  )
}

const DateRangePreviewWrapper = styled.button((props) => ({
  marginTop: props.theme.space.xs,
  marginLeft: props.theme.space.xl,
  border: "1px solid black",
  padding: props.theme.space.sm,
  display: "inline-flex",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: "unset",
  cursor: "pointer",
}))

function DateRangePreview({ from, to }: { from: string; to: string }) {
  return (
    <DateRangePreviewWrapper>
      <img src={calendar} alt="date range" />
      <Box ml="sm">
        <Text fontSize="med" fontWeight="heavy" color="blue">
          {formatDatetimeString(from)} - {formatDatetimeString(to)}
        </Text>
      </Box>
    </DateRangePreviewWrapper>
  )
}
