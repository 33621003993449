import styled from "@emotion/styled"
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox"
import "@reach/combobox/styles.css"
import { useState } from "react"
import { useQuery } from "react-query"
import { useCurrentUserQuery } from "../utils/hooks/useCurrentUserQuery"
import { search as searchIcon } from "../utils/icons"
import { useRequest } from "../utils/oviss"
import { ClientAutoCompleteResult } from "../utils/types"
import { Box } from "./Box"

export const parseResultName = (value: string) => {
  const match = value.match(/\[(.*?)\]/)
  if (match == null) return { name: value, address: "" }
  let name = value
    .replace(match[0], "")
    .replace(/\s+(Active|ActiveVC|Pending)$/, "")
  return {
    name,
    address: match[1] || "",
  }
}

export const ResultName = ({ name }: { name: string }) => {
  const { name: resultName, address } = parseResultName(name)
  return (
    <div>
      {resultName}
      <div>
        <small>{address}</small>
      </div>
    </div>
  )
}

export function ClientAutocompleteInput({
  clientType,
  orderStatuses,
  onSelect,
  selected,
}: {
  clientType: string
  orderStatuses: string[]
  onSelect: (label: string, id: string) => void
  selected: { label: string; id: string }
}) {
  const [searchQuery, setSearchQuery] = useState("")
  const request = useRequest()
  const { data: user } = useCurrentUserQuery()
  const { data, isLoading } = useQuery<ClientAutoCompleteResult[]>(
    [
      "ClientAutoComplete",
      {
        path: `/api/v2/Clients/ClientAutoComplete`,
        query: {
          term: searchQuery,
          clientType: clientType,
          searchType: "contains",
          onlyClientsOnOrdersForConsignorId: user?.Client.ClientId,
          onlyClientsOnOrdersWithStatus: orderStatuses,
        },
        method: "GET",
      },
    ],
    request,
    { enabled: searchQuery?.length >= 3 },
  )

  return (
    <Combobox
      openOnFocus={true}
      aria-label={`Search by ${clientType}`}
      onSelect={(value: string) => {
        setSearchQuery("")
        const selectedId = data?.find((item) => item.Name === value)?.Id
        onSelect(value, selectedId || "")
      }}
    >
      <Box
        display="flex"
        position="relative"
        alignItems="center"
        justifyItems="center"
      >
        <Box position="absolute" ml="sm">
          <img height="14" width="14" src={searchIcon} alt="" />
        </Box>
        <StyledComboboxInput
          value={searchQuery}
          placeholder={`Search by ${clientType}`}
          onChange={(e: any) => setSearchQuery(e.target.value)}
        />
      </Box>
      <ComboboxPopover>
        <ComboboxList>
          {data && data.length > 0 ? (
            data?.map(({ Name }, idx) => {
              return (
                <ComboboxOption key={idx} value={Name || ""}>
                  <ResultName name={Name || ""} />
                </ComboboxOption>
              )
            })
          ) : isLoading ? (
            <Box m="xs">Loading...</Box>
          ) : (
            <Box m="xs">No matches found</Box>
          )}
        </ComboboxList>
      </ComboboxPopover>
      {!!selected.label && (
        <SelectedLabel>
          {" "}
          <ResultName name={selected.label} />
          <RemoveButton
            onClick={() => onSelect("", "")}
            title="Remove"
            type="button"
          >
            ✕
          </RemoveButton>
        </SelectedLabel>
      )}
    </Combobox>
  )
}

const StyledComboboxInput = styled(ComboboxInput)((props) => ({
  paddingLeft: props.theme.space.xl,
  paddingRight: props.theme.space.sm,
  paddingTop: props.theme.space.xs,
  paddingBottom: props.theme.space.xs,
  fontSize: props.theme.text.sizes.sm,
  width: "100%",
}))

const SelectedLabel = styled.div((props) => ({
  marginTop: props.theme.space.xs,
  display: "flex",
}))

const RemoveButton = styled.button({
  border: 0,
  background: "transparent",
  marginLeft: 5,
  cursor: "pointer",
})
