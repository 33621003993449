type UnitType = "partial" | "load"

type MileageBand = {
  distanceMin: number
  distanceMax: number | null
  unitType: UnitType
  /** number of days */
  transitTime: number
}

export function getUnitType(unitCount: number): UnitType {
  if (!Number.isFinite(unitCount)) {
    throw new Error(`unit count must be a number`)
  }

  if (unitCount >= 0 && unitCount < 5) {
    return "partial"
  }

  if (unitCount >= 5) {
    return "load"
  }

  throw new Error(`unit count out of bounds`)
}

type DataRow = [UnitType, number, number | null, number]

const noRushData: Array<DataRow> = [
  ["partial", 0, 250, 3],
  ["partial", 251, 500, 5],
  ["partial", 501, 750, 6],
  ["partial", 751, 1000, 7],
  ["partial", 1001, 1250, 8],
  ["partial", 1251, 1500, 9],
  ["partial", 1501, 1750, 10],
  ["partial", 1751, 2000, 11],
  ["partial", 2001, null, 12],

  ["load", 0, 250, 3],
  ["load", 251, 500, 4],
  ["load", 501, 750, 5],
  ["load", 751, 1000, 6],
  ["load", 1001, 1500, 7],
  ["load", 1501, 2000, 8],
  ["load", 2000, null, 10],
]

const rushData: Array<DataRow> = [
  ["partial", 0, 250, 1],
  ["partial", 251, 500, 2],
  ["partial", 501, 750, 3],
  ["partial", 751, 1000, 4],
  ["partial", 1001, 1250, 5],
  ["partial", 1251, 1500, 6],
  ["partial", 1501, 1750, 7],
  ["partial", 1751, 2000, 8],
  ["partial", 2001, null, 9],

  ["load", 0, 250, 1],
  ["load", 251, 500, 2],
  ["load", 501, 750, 3],
  ["load", 751, 1000, 4],
  ["load", 1001, 1500, 5],
  ["load", 1501, 2000, 6],
  ["load", 2001, 2500, 7],
  ["load", 2501, null, 8],
]

export function mileageBandByUnits(
  distanceInMiles: number,
  unitCount: number,
  isRush: boolean,
): MileageBand {
  let unitType = getUnitType(unitCount)

  let data = isRush ? rushData : noRushData

  for (let [ut, min, max, tt] of data) {
    if (
      ut === unitType &&
      distanceInMiles >= min &&
      (max == null || (max != null && distanceInMiles <= max))
    ) {
      return {
        distanceMin: min,
        distanceMax: max,
        transitTime: tt,
        unitType: ut,
      }
    }
  }

  throw new Error(`no mileage bands match`)
}
