import React from "react"
import { useQuery } from "react-query"
import { useRequest } from "../utils/oviss"
import { ErrorIndicator } from "./ErrorIndicator"
import { Text } from "./Text"

export function CustomerCareLink() {
  const request = useRequest()
  const { data, error } = useQuery<{
    CustomerCarePhoneNumber: string
    FeedbackEmail: string
  }>(
    [
      ["supportContactInfo"],
      {
        path: `/api/Lookups/CustomerPortalSupportContactInfo`,
        method: "GET",
      },
    ],
    request,
  )

  return (
    <>
      {data?.CustomerCarePhoneNumber ? (
        <a href={`tel:${data.CustomerCarePhoneNumber}`}>
          {data.CustomerCarePhoneNumber}
        </a>
      ) : error ? (
        <ErrorIndicator error={error} />
      ) : (
        <Text color="medGray" as="span">
          loading…
        </Text>
      )}
    </>
  )
}
