import styled from "@emotion/styled"
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  useDisclosureContext,
} from "@reach/disclosure"
import { BackArrow } from "../../components/BackArrow"
import { Box } from "../../components/Box"
import { Container } from "../../components/Container"
import { Text } from "../../components/Text"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import { faqArrow } from "../../utils/icons"

export function FAQScreen() {
  useDocumentTitle("FAQ")

  return (
    <Box>
      <Box bg="blue" display="flex" alignItems="center" p="sm">
        <BackArrow theme="light" />
        <Box ml="sm">
          <Text fontSize="sm" color="white">
            RESOURCES AND FAQS
          </Text>
        </Box>
      </Box>
      <Container>
        <Box mt="lg" mh="sm">
          <Box mv="sm">
            <SectionTitle>Resources</SectionTitle>
            <SectionDescription>
              Get the most out of United Road with these custom resources.
            </SectionDescription>
          </Box>
          <Divider />
          <BasicQuestionDropdown question="Coming soon!" answer="" />
        </Box>
        <Box mt="lg" mh="sm">
          <Box mv="sm">
            <SectionTitle>FAQs</SectionTitle>
            <SectionDescription>
              Get answers to common questions.
            </SectionDescription>
          </Box>
          <Divider />
          <BasicQuestionDropdown
            question="What orders will I be able to see in the Customer Portal?"
            answer="Once you register and log into the portal, you will be able to see any order(s)  associated with your Client ID."
          />
          <BasicQuestionDropdown
            question="What documents are available to me through this portal?"
            answer={
              <>
                The Customer Portal provides visibility into your orders. You
                can access the following documents within the portal:
                <ul>
                  <li>Pictures</li>
                  <li>Pick Up Receipt</li>
                  <li>Delivery Receipt</li>
                </ul>
              </>
            }
          />
          <BasicQuestionDropdown
            question="Can I share a link to one specific order with my customers so they can track their deliveries from our dealership even if they do not have their own login?"
            answer="Only customers with a client ID can register for and view/track their orders within the portal. You have the option to email order details or even download and share with your customers a CSV file that will show order details."
          />
          <BasicQuestionDropdown
            question="Is there a way to mark orders as a favorite for easy tracking?"
            answer="Yes, you can easily flag orders as a favorite. Favorite status within the portal provides a quick and easy way to monitor specific orders that are important to you. When you click the flag next to an order, it marks the order as a favorite for easier tracking and notifications. You can even receive pick up or delivery alerts via email or SMS for your favorite orders through the Notification settings. Favorite status is only a feature within the portal and does not influence delivery times."
          />
          <BasicQuestionDropdown
            question="I need to make a change to my order after it has been placed. How do I do that?"
            answer={
              <>
                Please reach out to your dedicated Sales Consultant with whom
                you booked the order. If you do not have their contact
                information or do not know who your dedicated Sales Consultant
                is, please send an email to{" "}
                <a href="Info@UnitedRoad.com">Info@UnitedRoad.com</a> or call
                our Customer Care team at{" "}
                <a href="tel:800.432.5183">(800) 432-5183</a>, Monday-Friday 8
                am - 5 pm ET.
              </>
            }
          />
          <BasicQuestionDropdown
            question="I need to change my pick up or delivery instructions/hours. How can I do that?"
            answer={
              <>
                You can do that by sending an email to our customer care team at{" "}
                <a href="mailto:Info@UnitedRoad.com">Info@UnitedRoad.com</a> or
                by calling Customer Care team at{" "}
                <a href="tel:800.432.5183">(800) 432-5183</a>, Monday-Friday 8
                am - 5 pm ET. for more information.
              </>
            }
          />
          <BasicQuestionDropdown
            question="What do the Order Statuses mean?"
            answer={
              <>
                If you have any questions regarding the status of your order,
                please contact your Sales Consultant at{" "}
                <a href="tel:800.432.5184">(800) 432-5184</a> or our Customer
                Care team at <a href="tel:800.432.5183">(800) 432-5183</a>,
                Monday-Friday 8 am - 5 pm ET. for more information.
                <StyledTable>
                  <tr>
                    <StyledTd>ACTIVE (not dispatched)</StyledTd>
                    <StyledTd>
                      Order has been placed and is currently in the process of
                      being dispatched to a driver.
                    </StyledTd>
                  </tr>
                  <tr>
                    <StyledTd>PENDING PICK UP</StyledTd>
                    <StyledTd>
                      Order has been dispatched to a driver and is scheduled for
                      pick up.
                    </StyledTd>
                  </tr>
                  <tr>
                    <StyledTd>IN ROUTE</StyledTd>
                    <StyledTd>
                      Order has been picked up from the origin and is on the way
                      to its destination.
                    </StyledTd>
                  </tr>
                  <tr>
                    <StyledTd>ON HOLD</StyledTd>
                    <StyledTd>
                      Indicates the order has been placed on hold.
                    </StyledTd>
                  </tr>
                  <tr>
                    <StyledTd>DELIVERED</StyledTd>
                    <StyledTd>The order has reached its destination.</StyledTd>
                  </tr>
                  <tr>
                    <StyledTd>INACTIVE</StyledTd>
                    <StyledTd>
                      The order has not yet been activated for driver
                      assignment.
                    </StyledTd>
                  </tr>
                  <tr>
                    <StyledTd>CANCELLED</StyledTd>
                    <StyledTd>Indicates the order has been cancelled.</StyledTd>
                  </tr>
                </StyledTable>
              </>
            }
          />
          <BasicQuestionDropdown
            question="What does Estimated Time mean?"
            answer="An estimated delivery date/time is an educated guess on when your unit(s) will be delivered, based on the driver’s current manifest, delivery schedule, and other factors such as drive time, distance, and location."
          />
          <BasicQuestionDropdown
            question="How will I be notified if there is a delay in my shipment?"
            answer={
              <>
                If your Scheduled Pick Up or Estimated Delivery dates have
                passed, please call the Customer Care team at{" "}
                <a href="tel:800.432.5183">(800) 432-5183</a>, Monday-Friday 8
                am - 5 pm ET. for more information.
              </>
            }
          />
          <BasicQuestionDropdown
            question="How do I place a new order?"
            answer={
              <>
                Call our Sales team at{" "}
                <a href="tel:800.432.5184">(800) 432-5184</a> and you will be
                connected with your dedicated Sales Consultant. Please have the
                pick up and delivery zip codes available, along with the
                year(s), make(s), and model(s).
              </>
            }
          />
          <BasicQuestionDropdown
            question="My order was delivered but is still showing IN ROUTE. Why is that?"
            answer="Occasionally an order will be delivered but may not be marked as delivered in your portal view.  Don’t worry, your order status will be updated once all delivery paperwork has been completed and uploaded into our tracking software."
          />
          <BasicQuestionDropdown
            question="How do I set up PICK UP and DELIVERY notification emails?"
            answer="If you want to set up pick up and delivery notification emails, click on the Notifications bell located in the upper right of the page."
          />
        </Box>
        <Box mt="lg" mh="sm">
          <Box mv="sm">
            <Text fontSize="xxl" fontWeight="med">
              Contact Us:
            </Text>
            <Box mv="lg">
              <Text fontSize="xl">
                Monday - Friday, 8 am - 5 pm ET. Closed Holidays
              </Text>
            </Box>
            <Box mv="lg">
              <Text fontSize="xl">Sales</Text>
              <Box mt="xs">
                <a href="tel:800.432.5184">(800) 432-5184</a> or submit a
                Request for Quote on our website at{" "}
                <a href="https://www.unitedroad.com/contact">
                  https://www.unitedroad.com/contact
                </a>
              </Box>
            </Box>
            <Box mv="lg">
              <Text fontSize="xl">Customer Care Support</Text>
              <Box mt="xs">
                <a href="tel:800.432.5183">(800) 432-5183</a> /{" "}
                <a href="mailto:Info@UnitedRoad.com">Info@UnitedRoad.com</a>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

const SectionTitle = styled.div((props) => ({
  fontSize: props.theme.text.sizes.xxl,
  color: props.theme.color.black,
  marginBottom: props.theme.space.xs,
}))

const SectionDescription = styled.div((props) => ({
  fontSize: props.theme.text.sizes.sm,
  color: props.theme.color.black,
}))

const QuestionText = styled.div((props) => ({
  textAlign: "left",
  fontSize: props.theme.text.sizes.lg,
  color: props.theme.color.black,
  fontFamily: "'Barlow', sans-serif",
  maxWidth: "55%",
}))

const AnswerText = styled.div((props) => ({
  textAlign: "left",
  fontSize: props.theme.text.sizes.sm,
  color: props.theme.color.black,
  fontFamily: "'Barlow', sans-serif",
}))

type QuestionDropdownProps = {
  question: React.ReactNode
  answer: React.ReactNode
}

function BasicQuestionDropdown({ question, answer }: QuestionDropdownProps) {
  return (
    <QuestionDropdown
      question={<QuestionText>{question}</QuestionText>}
      answer={<AnswerText>{answer}</AnswerText>}
    />
  )
}

function QuestionDropdown({ question, answer }: QuestionDropdownProps) {
  return (
    <>
      <Box mv="sm">
        <Disclosure>
          <ShowAnswerButton>
            {question}
            <DisplayArrow />
          </ShowAnswerButton>
          <DisclosurePanel>
            <Box mv="sm">{answer}</Box>
          </DisclosurePanel>
        </Disclosure>
      </Box>
      <Divider />
    </>
  )
}

const ShowAnswerButton = styled(DisclosureButton)({
  margin: 0,
  padding: 0,
  border: "none",
  background: "none",
  cursor: "pointer",
  width: "100%",

  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
})

function DisplayArrow() {
  const { open } = useDisclosureContext()
  return (
    <ArrowWrapper open={open}>
      <img src={faqArrow} alt={open ? "close" : "open"} />
    </ArrowWrapper>
  )
}

const ArrowWrapper = styled.div<{ open: boolean }>((props) => ({
  transform: props.open ? "rotate(90deg)" : undefined,
}))

const Divider = styled.div((props) => ({
  width: "100%",
  height: 1,
  background: props.theme.color.black,
}))

const StyledTable = styled.table((props) => ({
  marginTop: props.theme.space.lg,
  borderCollapse: "collapse",
}))

const StyledTd = styled.td((props) => ({
  border: "1px solid black",
  padding: props.theme.space.sm,
}))
