import styled from "@emotion/styled"
import { useMutation } from "react-query"
import { useCurrentUserQuery } from "../utils/hooks/useCurrentUserQuery"
import { downloadBlack, downloadWhite } from "../utils/icons"
import { useDownloadRequest } from "../utils/oviss"
import { Box } from "./Box"
import { SecondaryButton } from "./Buttons/SecondaryButton"
import oval_dark from "./LoadingIndicator/oval_dark.svg"
import oval_light from "./LoadingIndicator/oval_light.svg"

type DownloadOrderListButtonProps = {
  colorTheme?: "light" | "dark"
  listParams?: any
  children?: React.ReactNode
  iconSize?: number
}

export function DownloadOrderListButton({
  colorTheme = "light",
  listParams,
  children = undefined,
  iconSize = 24,
}: DownloadOrderListButtonProps) {
  const downloadRequest = useDownloadRequest()
  const { data: user } = useCurrentUserQuery()
  const { mutate: downloadOrderList, isLoading } = useMutation(() => {
    const clientId = user?.Client.ClientId
    if (!clientId) throw new Error("Missing ClientId")
    return downloadRequest(
      {
        path: "/api/CustomerPortalOrders/ListOrders",
        method: "POST",
        body: {
          ...listParams,
          IncludeVehicles: true,
          ResponseAsAttachment: true,
          PageSize: 10000,
          PageNumber: 1,
          OrderClients: [clientId],
        },
        query: {
          resultType: "CSV",
        },
      },
      "ListOrders.csv",
    )
  })

  if (isLoading)
    return (
      <img
        width="24"
        height="24"
        alt="loading"
        src={colorTheme === "light" ? oval_light : oval_dark}
      />
    )

  return (
    <StyledSecondaryButton
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        downloadOrderList()
      }}
      title="Download Orders"
    >
      <Box display="flex" alignItems="center">
        <img
          src={colorTheme === "light" ? downloadWhite : downloadBlack}
          width={iconSize}
          height={iconSize}
          alt="download"
        />
        {children && <Box ml="xs">{children}</Box>}
      </Box>
    </StyledSecondaryButton>
  )
}

const StyledSecondaryButton = styled(SecondaryButton)({
  padding: 0,
  textDecoration: "none",
})
