import { css } from "@emotion/react"
import styled from "@emotion/styled"
import { ComboboxInput } from "@reach/combobox"
import { Theme } from "../emotion/theme"

const inputStyles = (props: { theme: Theme; type?: string }) =>
  css({
    paddingLeft: props.theme.space.sm,
    paddingRight: props.theme.space.sm,
    paddingTop: props.theme.space.xs,
    // unfortunate hack to work-around input type date being 1px off
    paddingBottom: props.type === "date" ? `7px` : props.theme.space.xs,
    fontFamily: props.theme.text.fonts.sans,
    fontSize: "16px",
    lineHeight: "16px",
    width: "100%",
  })

export const StyledSelect = styled.select((props) => ({
  ...inputStyles(props),
}))

export const StyledInput = styled.input((props) => ({
  ...inputStyles(props),
}))

export const StyledComboboxInput = styled(ComboboxInput)((props) => ({
  ...inputStyles(props),
}))
