import styled from "@emotion/styled"
import * as CSS from "csstype"
import { addDays, subDays } from "date-fns"
import * as ApiV2 from "../generated/ApiV2"
import { selectTriangleBlack, selectTriangleWhite } from "../utils/icons"
import { ListOrdersRequestBody, OrderStatus, SavedView } from "../utils/types"
import { formatISODate, reformatCustomViewDate } from "../utils/utils"
import { Consignor } from "./hooks/useCurrentUserQuery"

export type ListParams = Omit<
  ListOrdersRequestBody,
  "DriverType" | "ResponseFormat" | "PageSize" | "PageNumber"
>

export type OrderListGroup =
  | "current"
  | "history"
  | "inactive"
  | "myOrders"
  | "inbound"
  | "outbound"

export type OrderStatusOption = {
  displayName: string
  value: OrderStatus
  defaultSelected?: boolean
}

export type SortByOption =
  | "deliveryDate"
  | "pickUpDate"
  | "largest"
  | "smallest"
  | "originCity"
  | "destinationCity"

export type OrderOption = "asc" | "desc"

type ListOrderRequest =
  ApiV2.paths["/api/v2/Orders/ListOrders"]["post"]["parameters"]["body"]["listOrdersRequest"]

export type SortField = ListOrderRequest["SortField"]

export type OrderListSortOptionSpec = {
  displayName: string
  value: string
  SortField: SortField
  SortAscending: boolean
}

// need feedback on this
const inboundOutboundStatuses = [
  { displayName: "Active (not dispatched)", value: "Active" },
  { displayName: "Pending Pick Up", value: "PendingPickup" },
  { displayName: "In Route", value: "InRoute" },
  {
    displayName: "Delivered",
    value: "Delivered",

    defaultSelected: false,
  },
  {
    displayName: "Cancelled",
    value: "Cancelled",
    defaultSelected: false,
  },
  {
    displayName: "On Hold",
    value: "OnHold",

    defaultSelected: false,
  },
  { displayName: "Inactive", value: "Inactive" },
]

const statusMap: Record<OrderListGroup, OrderStatusOption[]> = {
  current: [
    { displayName: "Active (not dispatched)", value: "Active" },
    { displayName: "Pending Pick Up", value: "PendingPickup" },
    { displayName: "In Route", value: "InRoute" },
  ],
  history: [
    { displayName: "Delivered", value: "Delivered" },
    {
      displayName: "Cancelled",
      value: "Cancelled",
      defaultSelected: false,
    },
  ],
  inactive: [
    { displayName: "On Hold", value: "OnHold" },
    { displayName: "Inactive", value: "Inactive" },
  ],
  myOrders: [
    { displayName: "Active (not dispatched)", value: "Active" },
    { displayName: "Pending Pick Up", value: "PendingPickup" },
    { displayName: "In Route", value: "InRoute" },
    {
      displayName: "Delivered",
      value: "Delivered",

      defaultSelected: false,
    },
    {
      displayName: "Cancelled",
      value: "Cancelled",
      defaultSelected: false,
    },
    {
      displayName: "On Hold",
      value: "OnHold",

      defaultSelected: false,
    },
    { displayName: "Inactive", value: "Inactive" },
  ],
  inbound: inboundOutboundStatuses,
  outbound: inboundOutboundStatuses,
}

export function getOrderStatusesForGroup(
  group: OrderListGroup,
): OrderStatusOption[] {
  return statusMap[group]
}

export function getOrderListGroupFromStatuses(orderStatuses: string[]) {
  if (
    ["Active", "PendingPickup", "InRoute"].some((s) =>
      orderStatuses.includes(s),
    )
  ) {
    return "current"
  } else if (
    ["Delivered", "Cancelled"].some((s) => orderStatuses.includes(s))
  ) {
    return "history"
  } else if (["OnHold", "Inactive"].some((s) => orderStatuses.includes(s))) {
    return "inactive"
  } else {
    return "Unknown"
  }
}

export function getReverseSortDetails(
  sortField: SortField,
  sortAscending: boolean,
): SortByOption {
  switch (sortField) {
    case "ActualPickupDate":
      return "pickUpDate"
    case "Quantity":
      return sortAscending ? "smallest" : "largest"
    case "OriginCity":
      return "originCity"
    case "DestinationCity":
      return "destinationCity"
    default:
      return "deliveryDate"
  }
}

export function getSortDetails(sortBy: SortByOption): OrderListSortOptionSpec {
  switch (sortBy) {
    case "deliveryDate":
      return {
        SortField: "DeliveryDateToDisplay",
        SortAscending: false,
        displayName: "Delivery Date",
        value: "deliveryDate",
      }
    case "pickUpDate":
      return {
        SortField: "PickupDateToDisplay",
        SortAscending: false,
        displayName: "Pick Up Date",
        value: "pickUpDate",
      }
    case "largest":
      return {
        SortField: "Quantity",
        SortAscending: false,
        displayName: "Largest",
        value: "largest",
      }
    case "smallest":
      return {
        SortField: "Quantity",
        SortAscending: true,
        displayName: "Smallest",
        value: "smallest",
      }
    case "originCity":
      return {
        SortField: "OriginCity",
        SortAscending: true,
        displayName: "Origin City",
        value: "originCity",
      }
    case "destinationCity":
      return {
        SortField: "DestinationCity",
        SortAscending: true,
        displayName: "Destination City",
        value: "destinationCity",
      }
  }
}

const sortOptions: SortByOption[] = [
  "deliveryDate",
  "pickUpDate",
  "originCity",
  "destinationCity",
  // "largest",
  // "smallest",
]
export const listSortingOptions = sortOptions.map((v) => getSortDetails(v))

export type FiltersSpec = {
  sortBy: SortByOption
  order: OrderOption
  orderStatuses: OrderStatus[]
  showPriorityOnly?: boolean
  vins?: string
  orderNumbers?: string
  pickUpDateRange?: string
  pickUpDateCustomRangeStart?: string
  pickUpDateCustomRangeEnd?: string
  deliveryDateRange?: string
  deliveryDateCustomRangeStart?: string
  deliveryDateCustomRangeEnd?: string
  originClientId?: string
  originClientLabel?: string
  destinationClientId?: string
  destinationClientLabel?: string
  onlyOrdersCreatedByCurrentUser?: boolean
  consignor?: string
}

export type ParsedFiltersSpec = {
  sortBy: SortByOption
  order: OrderOption
  orderStatuses: OrderStatus[]
  showPriorityOnly?: boolean
  vins?: string[]
  orderNumbers?: number[]
  pickUpDateRange?: string
  pickUpDateCustomRangeStart?: string
  pickUpDateCustomRangeEnd?: string
  deliveryDateRange?: string
  deliveryDateCustomRangeStart?: string
  deliveryDateCustomRangeEnd?: string
  originClientId?: string
  originClientLabel?: string
  destinationClientId?: string
  destinationClientLabel?: string
  onlyOrdersCreatedByCurrentUser?: boolean
  consignor?: string
}

export type QuickFiltersSpec = {
  sortBy?: SortByOption
  orderStatuses?: OrderStatus[]
  pickUpDateRange?: string
  pickUpDateCustomRangeStart?: string
  pickUpDateCustomRangeEnd?: string
  deliveryDateRange?: string
  deliveryDateCustomRangeStart?: string
  deliveryDateCustomRangeEnd?: string
}

export type DateRangeSelectorValue =
  | "unselected"
  | "null"
  | "yesterday"
  | "today"
  | "tomorrow"
  | "thisWeek"
  | "custom"

export type MaybeDateRangeSelectorValue =
  | "unselected"
  | "null"
  | "yesterday"
  | "today"
  | "tomorrow"
  | "thisWeek"
  | "custom"
  | undefined

type DateFilterOption = {
  displayName: string
  value: DateRangeSelectorValue
}

export const dateFilterOptions: DateFilterOption[] = [
  { displayName: "Not Scheduled", value: "null" },
  { displayName: "Yesterday", value: "yesterday" },
  { displayName: "Today", value: "today" },
  { displayName: "Tomorrow", value: "tomorrow" },
  { displayName: "Next 7 Days", value: "thisWeek" },
]

export const getVisibleDateFilterOptions = (
  orderGroup: OrderListGroup,
): DateFilterOption[] =>
  dateFilterOptions.filter((option) =>
    orderGroup === "history"
      ? !["tomorrow", "thisWeek"].includes(option.value)
      : option,
  )

export function getDefaultFiltersForOrderList({
  groupName,
}: {
  groupName: OrderListGroup
}): FiltersSpec {
  return {
    sortBy: "deliveryDate",
    order: "desc",
    orderStatuses: getOrderStatusesForGroup(groupName)
      .filter(({ defaultSelected }) =>
        defaultSelected !== undefined ? defaultSelected : true,
      )
      .map(({ value }) => value),
    ...(groupName === "myOrders" && {
      onlyOrdersCreatedByCurrentUser: true,
      sortBy: "pickUpDate",
    }),
  }
}

export function calculateDateRangeForSelectedInputValues(
  rangeType: MaybeDateRangeSelectorValue,
  rangeStart?: string,
  rangeEnd?: string,
) {
  const today = new Date()
  switch (rangeType) {
    case undefined:
    case "null":
    case "unselected":
      return null
    case "custom":
      return {
        from: rangeStart,
        to: rangeEnd,
      }
    case "yesterday":
      return {
        from: formatISODate(subDays(today, 1)),
        to: formatISODate(today),
      }
    case "today":
      return {
        from: formatISODate(today),
        to: formatISODate(today),
      }
    case "tomorrow":
      return {
        from: formatISODate(today),
        to: formatISODate(addDays(today, 1)),
      }
    case "thisWeek":
      return {
        from: formatISODate(today),
        to: formatISODate(addDays(today, 7)),
      }
  }
}

export function filterParamsToOrderListFilters({
  params,
  consignors,
  orderGroup,
}: {
  params: ParsedFiltersSpec
  consignors: Array<Consignor>
  orderGroup: OrderListGroup
}): ListParams {
  const sortParams = getSortDetails(params.sortBy)
  const orderParams = params.order
  const pickUpDateFilterParams = calculateDateRangeForSelectedInputValues(
    params.pickUpDateRange as MaybeDateRangeSelectorValue,
    params.pickUpDateCustomRangeStart,
    params.pickUpDateCustomRangeEnd,
  )
  const deliveryDateFilterParams = calculateDateRangeForSelectedInputValues(
    params.deliveryDateRange as MaybeDateRangeSelectorValue,
    params.deliveryDateCustomRangeStart,
    params.deliveryDateCustomRangeEnd,
  )

  let listParams: ListParams = {
    SortField: sortParams.SortField as any,
    SortAscending: orderParams === "asc",
    ShowPriorityOnly: params.showPriorityOnly,
    OnlyOrdersCreatedByCurrentUser: params.onlyOrdersCreatedByCurrentUser,
    OrderStatus: params.orderStatuses as any,
    Vins: params.vins,
    OrderIds: params.orderNumbers?.filter(Boolean),
    OnlyOrdersWithoutPickupDates: params.pickUpDateRange === "null",
    OnlyOrdersWithoutDeliveryDates: params.deliveryDateRange === "null",
    Consignors: params.consignor
      ? [parseInt(params.consignor, 10)]
      : consignors.map((c) => c.ClientId!),
  }

  if (pickUpDateFilterParams && pickUpDateFilterParams.to) {
    listParams.PickupDateFrom = pickUpDateFilterParams.from
    listParams.PickupDateThru = pickUpDateFilterParams.to
  }

  if (deliveryDateFilterParams && deliveryDateFilterParams.to) {
    listParams.DeliveryDateFrom = deliveryDateFilterParams.from
    listParams.DeliveryDateThru = deliveryDateFilterParams.to
  }

  if (!!params.originClientId) {
    listParams.OriginClients = [
      ...(listParams.OriginClients || []),
      Number(params.originClientId),
    ]
  }

  if (!!params.destinationClientId) {
    // keeping this here for backwards compat, safe to delete eventually
    listParams.DestinationClients = [
      ...(listParams.DestinationClients || []),
      Number(params.destinationClientId),
    ]
  }

  if (orderGroup === "outbound") {
    listParams.OriginClients = listParams.Consignors
  }

  if (orderGroup === "inbound") {
    listParams.DestinationClients = listParams.Consignors
  }

  return listParams
}

// Uses a regex to match all numbers in the provided string. Returns a list of numbers
function inputStringToNumbers(s: string) {
  const regex = /\d+/g
  return Array.from(s.matchAll(regex)).map(([match]) => parseInt(match, 10))
}

// Uses a regex to match all VINs in the provided string. Returns a list of strings.
// Unlike Order IDs, VINs are alphanumeric
function inputStringToVINs(s: string): Array<string> {
  const regex = /[a-zA-Z0-9]+/g
  return Array.from(s.matchAll(regex)).map(([match]) => match)
}

export function parseVinsFromFormValue(vins?: string) {
  return vins ? inputStringToVINs(vins) : undefined
}

export function parseOrderNumbersFromFormValue(numbers?: string) {
  return numbers ? inputStringToNumbers(numbers) : undefined
}

export function calculateSearchQueryForFilters({
  searchParams,
  values,
  update,
}: {
  searchParams?: URLSearchParams
  values: any
  update: boolean
}): any {
  const parsedVINs: string[] | undefined = parseVinsFromFormValue(values.vins)
  const parsedOrderIDs: number[] | undefined = parseOrderNumbersFromFormValue(
    values.orderNumbers,
  )

  // If we are updating a subset of our parameters, copy the current search
  // params
  let originalParams = {}
  if (update) {
    searchParams = searchParams as URLSearchParams
    originalParams = {
      sortBy: searchParams.getAll("sortBy"),
      orderStatuses: searchParams.getAll("orderStatuses"),
      vins: searchParams.getAll("vins"),
      orderNumbers: searchParams.getAll("orderNumbers"),
      showPriorityOnly: searchParams.get("showPriorityOnly") || false,
      onlyOrdersCreatedByCurrentUser:
        searchParams.has("onlyOrdersCreatedByCurrentUser") || undefined,
      pickUpDateRange: searchParams.get("pickUpDateRange") || "",
      pickUpDateCustomRangeStart:
        searchParams.get("pickUpDateCustomRangeStart") || "",
      pickUpDateCustomRangeEnd:
        searchParams.get("pickUpDateCustomRangeEnd") || "",
      deliveryDateRange: searchParams.get("deliveryDateRange") || "",
      deliveryDateCustomRangeStart:
        searchParams.get("deliveryDateCustomRangeStart") || "",
      deliveryDateCustomRangeEnd:
        searchParams.get("deliveryDateCustomRangeEnd") || "",
      consignor: searchParams.get("consignor") || "",
    }
  }

  let newSearchParams = {
    ...originalParams,
    ...values,
  }

  // We need a way to differentiate an empty order status list from
  // an unprovided list (which takes a default value for the currently
  // selectd group). We denote this empty case with a single empty string
  // element.
  if (!values.orderStatuses || values.orderStatuses.length === 0) {
    newSearchParams.orderStatuses = [""]
  }

  // Do not update parsedOrderIDs or vins if update is true.
  // This prevents the quick-filters view from overwriting this filter with an empty
  // array
  // This solution will break if order number filters are added to the quick view
  if (!update) {
    newSearchParams.vins = parsedVINs || ""
    newSearchParams.orderNumbers = parsedOrderIDs || ""
  } else {
    searchParams = searchParams as URLSearchParams
    newSearchParams.vins = searchParams.getAll("vins")
    newSearchParams.orderNumbers = searchParams.getAll("orderNumbers")
  }

  // remove undefined values since these end up in the url
  Object.keys(newSearchParams).forEach((key) => {
    if (newSearchParams[key] === undefined) {
      delete newSearchParams[key]
    }
  })

  return newSearchParams
}

// Basically the opposite of filterParamsToOrderListFilters
export function orderListFiltersToFilterParams(v: SavedView) {
  let filters: Record<string, any> = {}

  const params = v.CustomerPortalSavedSearchDto

  if (!params) {
    return {}
  }

  // Sort parameters are always included in saved search blobs
  filters.sortBy = getReverseSortDetails(
    params.SortField as SortField,
    params.SortAscending as boolean,
  )

  filters.showPriorityOnly = params.ShowPriorityOnly
  filters.onlyOrdersCreatedByCurrentUser =
    params.OnlyOrdersCreatedByCurrentUser || undefined
  filters.orderStatuses = params.OrderStatus

  // These will always be set together, but checking both makes TS happy
  if (params.PickupDateFrom && params.PickupDateThru) {
    // Interpret everything as a custom date range, since the saved views
    // have no idea if we selected one of the canned ranges (ie `last week`).
    // It just gives us the date range start/end
    filters.pickUpDateRange = "custom"
    filters.pickUpDateCustomRangeStart = reformatCustomViewDate(
      params.PickupDateFrom,
    )
    filters.pickUpDateCustomRangeEnd = reformatCustomViewDate(
      params.PickupDateThru,
    )
  } else if (params.OnlyOrdersWithoutPickupDates) {
    filters.pickUpDateRange = "null"
  } else {
    filters.pickUpDateRange = "unselected"
  }

  // These will always be set together, but checking both makes TS happy
  if (params.DeliveryDateFrom && params.DeliveryDateThru) {
    // Interpret everything as a custom date range, since the saved views
    // have no idea if we selected one of the canned ranges (ie `last week`).
    // It just gives us the date range start/end
    filters.deliveryDateRange = "custom"
    filters.deliveryDateCustomRangeStart = reformatCustomViewDate(
      params.DeliveryDateFrom,
    )
    filters.deliveryDateCustomRangeEnd = reformatCustomViewDate(
      params.DeliveryDateThru,
    )
  } else if (params.OnlyOrdersWithoutDeliveryDates) {
    filters.deliveryDateRange = "null"
  } else {
    filters.deliveryDateRange = "unselected"
  }

  if (params.Vin8 && params.Vin8.length > 0) {
    filters.vins = params.Vin8
  }

  if (params.OrderIds && params.OrderIds.length > 0) {
    filters.orderNumbers = params.OrderIds.map((n) => n.toString())
  }

  return filters
}

export const SortBySelect = styled.select<{
  color?: CSS.Properties["color"]
  background?: CSS.Properties["background"]
  width?: CSS.Properties["width"]
}>((props) => ({
  appearance: "none",
  borderRadius: 100,
  width: props.width ? props.width : "100%",
  background: props.background ? props.background : props.theme.color.blue,
  color: props.color ? props.color : props.theme.color.white,
  padding: props.theme.space.xxs,
  paddingLeft: props.theme.space.sm,
  fontSize: props.theme.text.sizes.lg,
  backgroundImage: props.color
    ? `url(${selectTriangleBlack})`
    : `url(${selectTriangleWhite})`,
  backgroundPosition: "98% center",
  backgroundRepeat: "no-repeat",
}))
