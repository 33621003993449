import { ApiFlatOrder, ApiOrder } from "../utils/types"

/**
 * get all vehicles associated with an order
 *
 * @see https://www.pivotaltracker.com/story/show/178966759
 */
export function orderVehicles(order: ApiOrder) {
  return [
    ...order.Vehicles,
    ...order.ChildOrders.flatMap((childOrder) => childOrder.Vehicles),
  ]
}

export function orderVins(order: ApiOrder) {
  return orderVehicles(order).flatMap((vehicle) => vehicle.VIN)
}

export function orderFromFlat(
  order: ApiFlatOrder,
): Pick<
  ApiOrder,
  | "Origin"
  | "Destination"
  | "ActualPickupDate"
  | "ScheduledPickupDate"
  | "EstimatedDeliveryDate"
  | "ActualDeliveryDate"
  | "OrderId"
  | "OrderStatus"
  | "OrderMiles"
  | "IsUserPriority"
> {
  return {
    // @ts-expect-error TODO: IsCarrierSister and ClientKeys are required even though they are optional in ApiOrder.Origin
    Origin: {
      City: order.OriginCity,
      State: order.OriginState,
      ClientId: 0,
      Name: "",
      DeliveryComment: "",
      Address1: "",
      Address2: "",
      Zip: "",
      Country: "",
      GeoCodeLatitude: 0,
      GeoCodeLongitude: 0,
      TimeZoneId: "",
      Phone: "",
      ContactId: 0,
      Title: "",
      Email: "",
      Website: "",
      ContactName: "",
      ContactTitle: "",
      ContactEmail: "",
      ContactPhone: "",
    },
    // @ts-expect-error TODO: IsCarrierSister and ClientKeys are required even though they are optional in ApiOrder.Origin
    Destination: {
      City: order.DestinationCity,
      State: order.DestinationState,
      ClientId: 0,
      Name: "",
      DeliveryComment: "",
      Address1: "",
      Address2: "",
      Zip: "",
      Country: "",
      GeoCodeLatitude: 0,
      GeoCodeLongitude: 0,
      TimeZoneId: "",
      Phone: "",
      ContactId: 0,
      Title: "",
      Email: "",
      Website: "",
      ContactName: "",
      ContactTitle: "",
      ContactEmail: "",
      ContactPhone: "",
    },
    ScheduledPickupDate: order.ScheduledPickupDatetime,
    ActualPickupDate: order.ActualPickupDatetime,
    EstimatedDeliveryDate: order.EstimatedDelivery,
    ActualDeliveryDate: order.DeliveredDatetime,
    OrderId: order.OrderId,
    OrderStatus: order.OrderStatus as any,
    OrderMiles: order.Miles,
    IsUserPriority: order.IsUserPriority,
  }
}
