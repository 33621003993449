import styled from "@emotion/styled"
import { useField } from "formik"

export const FormikError = (props: { name: string }) => {
  const [_, meta] = useField(props)

  if (meta.error == null) return null
  if (!meta.touched) return null

  return <LabelErrorIndicator>{meta.error}</LabelErrorIndicator>
}

const LabelErrorIndicator = styled.div((props) => ({
  paddingTop: props.theme.space.xs,
  paddingBottom: props.theme.space.xs,
  color: props.theme.color.warning,
  whiteSpace: "pre-wrap",
  fontSize: props.theme.text.sizes.sm,
}))
