import styled from "@emotion/styled"

type InitialsAvatarProps = {
  firstName?: string
  lastName?: string
  backgroundColor?: "gray" | "black"
}

export function InitialsAvatar({
  firstName = "",
  lastName = "",
  backgroundColor = "gray",
}: InitialsAvatarProps) {
  return (
    <InitialsAvatarWrapper backgroundColor={backgroundColor}>
      {firstName.charAt(0)}
      {lastName.charAt(0)}
    </InitialsAvatarWrapper>
  )
}

const InitialsAvatarWrapper = styled.div<{ backgroundColor: "gray" | "black" }>(
  (props) => ({
    borderRadius: "100%",
    fontWeight: props.theme.text.weights.bold,
    backgroundColor:
      props.backgroundColor === "black"
        ? props.theme.color.black
        : props.theme.color.darkGray,
    color: "white",
    fontSize: props.theme.text.sizes.lg,
    padding: props.theme.space.xs,
    display: "inline-block",
    width: 35,
    height: 35,
  }),
)
