import styled from "@emotion/styled"
import { useQuery } from "react-query"
import { useParams } from "react-router"
import { useRequest } from "../../utils/oviss"
import { Box } from "../../components/Box"
import { Text } from "../../components/Text"
import { GridArea } from "../../components/GridArea"
import { BackArrow } from "../../components/BackArrow"
import {
  OrderCardId,
  CarIcon,
  UnitsDisplay,
  HorizontalDivider,
} from "../../components/OrderViewComponents"
import { uniqBy, groupBy } from "lodash"
import { useMemo } from "react"
import { ApiOrder } from "../../utils/types"
import { formatDatetimeString } from "../../utils/utils"
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  useDisclosureContext,
} from "@reach/disclosure"
import { SecondaryButton } from "../../components/Buttons/SecondaryButton"

export function OrderImagesScreen() {
  const { id } = useParams()
  const request = useRequest()

  const { data: orderPictures } = useQuery<Array<Record<string, any>>>(
    [
      ["order", { id }, "images"],
      {
        path: `/api/Orders/${id}/Pictures`,
        method: "GET",
      },
    ],
    request,
  )

  const vehicleOrderIDs = useMemo(
    () =>
      uniqBy(orderPictures || [], "orderid").map(
        ({ orderid }: Record<string, number>) => orderid,
      ),
    [orderPictures],
  )

  const imagesByVehicleOrderId = useMemo(
    () => groupBy(orderPictures || [], "orderid"),
    [orderPictures],
  )

  return (
    <Box pv="sm" mh="sm">
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box mr="sm">
          <BackArrow theme="dark" />
        </Box>
        <OrderCardId>{id}</OrderCardId>
      </Box>

      <Box display="flex" mt="md">
        <Box mr="sm">
          <UnitsDisplay>{vehicleOrderIDs.length}</UnitsDisplay>
        </Box>
        <CarIcon colorTheme="dark" />
      </Box>

      <HorizontalDivider colorTheme="dark" />

      {vehicleOrderIDs.map((vid, idx) => (
        <Box>
          <VehicleImages
            renderIdx={idx + 1}
            key={vid}
            vehicleOrderID={vid}
            images={imagesByVehicleOrderId[vid]}
          />
          <HorizontalDivider colorTheme="dark" />
        </Box>
      ))}
    </Box>
  )
}

type VehicleImagesProps = {
  renderIdx: number
  vehicleOrderID: number
  images: Array<Record<string, any>>
}

function VehicleImages({
  renderIdx,
  vehicleOrderID,
  images,
}: VehicleImagesProps) {
  const request = useRequest()

  const { data: order } = useQuery<ApiOrder>(
    [
      ["order", { vehicleOrderID }],
      {
        path: `/api/v2/Orders/Detail`,
        query: { orderId: vehicleOrderID },
        method: "GET",
      },
    ],
    request,
  )

  const vehicle = order?.Vehicles[0]

  return (
    <Box>
      <Box
        display="grid"
        alignItems="center"
        gridTemplateColumns="fit-content(100%) auto"
        gridTemplateRows="fit-content(100%) auto"
        columnGap="sm"
        justifyItems="left"
        gridTemplateAreas={'"icon vin" ". details"'}
        mv="md"
      >
        <GridArea gridArea="icon">
          <NumberIcon>
            <Box
              display="grid"
              bg="blue"
              borderRadius="100%"
              justifyItems="center"
              alignItems="center"
            >
              <Text color="white" fontSize="lg" fontWeight="bold">
                {renderIdx}
              </Text>
            </Box>
          </NumberIcon>
        </GridArea>

        <GridArea gridArea="vin">
          <Text fontSize="med" fontWeight="heavy">
            {vehicle?.VIN}
          </Text>
        </GridArea>

        <GridArea gridArea="details">
          <Text fontSize="xl">
            {vehicle?.Make} {vehicle?.Model}
          </Text>
          <Text fontSize="xl">{vehicle?.Year}</Text>
        </GridArea>
      </Box>

      <HorizontalDivider colorTheme="dark" />

      <Box>
        <Disclosure>
          <VehicleImagesToggleListButton />
          <DisclosurePanel>
            <Box mv="lg">
              {images.map((i, idx) => (
                <>
                  <Box
                    key={i.pictureid}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    ml="lg"
                  >
                    <Box>
                      <Text fontSize="xl" fontWeight="bold">
                        {i.pictureid}
                      </Text>
                      <Text color="medGray">
                        <Text>
                          {i.PicLocation}, {i.perspective}
                        </Text>
                        <Text>{formatDatetimeString(i.datetaken)}</Text>
                      </Text>
                    </Box>

                    <Box>
                      <StyledLink
                        href={i.fullURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        View
                      </StyledLink>
                    </Box>
                  </Box>

                  {/* Replace the post-image divider with some extra spacing if this is the last image in the list */}
                  {idx !== images.length - 1 ? (
                    <HorizontalDivider colorTheme="dark" />
                  ) : (
                    <Box mv="xxl" />
                  )}
                </>
              ))}
            </Box>
          </DisclosurePanel>
        </Disclosure>
      </Box>
    </Box>
  )
}

const NumberIcon = styled.div({
  width: 36,
  height: 36,
  display: "grid",
})

const StyledSecondaryButton = styled(SecondaryButton)({
  textDecoration: "none",
  margin: 0,
  padding: 0,
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
})

const StyledLink = styled.a((props) => ({
  width: "auto",
  paddingTop: props.theme.space.xs,
  paddingBottom: props.theme.space.xs,
  paddingLeft: props.theme.space.xl,
  paddingRight: props.theme.space.xl,
  backgroundColor: props.theme.color.green,
  color: props.theme.color.white,
  textDecoration: "none",
  fontWeight: props.theme.text.weights.bold,
  fontSize: props.theme.text.sizes.med,
}))

const DisplayArrow = styled.div<{ direction: "up" | "down" }>((props) => ({
  content: '""',
  clear: "both",
  // Hand-picked values to make the arrows look approximately correct. No math, just eyeballed values
  right: 20,
  top: 18,
  bottom: 18,
  borderLeft: "5px solid transparent",
  borderRight: "5px solid transparent",
  borderTop: props.direction === "down" ? "8px solid black" : undefined,
  borderBottom: props.direction === "up" ? "8px solid black" : undefined,
}))

function VehicleImagesToggleListButton() {
  const { open } = useDisclosureContext()

  return (
    <DisclosureButton as={StyledSecondaryButton}>
      <Text fontSize="xs" fontWeight="bold">
        VEHICLE IMAGES
      </Text>
      <DisplayArrow direction={open ? "up" : "down"} />
    </DisclosureButton>
  )
}
