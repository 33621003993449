import styled from "@emotion/styled"
import { useEffect, useState } from "react"
import { useFlashMessageContext } from "../utils/contexts/FlashMessageContext"
import { closeX } from "../utils/icons"
import { Box } from "./Box"
import { SecondaryButtonInline } from "./Buttons/SecondaryButtonInline"

export function FormSuccessIndicator({
  message,
  localMessage,
  timeout,
}: {
  message: string
  localMessage?: boolean
  timeout?: number
}) {
  const [open, setOpen] = useState(true)
  const { clearMessage } = useFlashMessageContext()

  useEffect(() => {
    if (localMessage && timeout && timeout > 0) {
      setTimeout(() => setOpen(false), timeout)
    }
  }, [localMessage, timeout])

  return !localMessage || open ? (
    <FormSuccessIndicatorText>
      <Box mr="lg">
        <StyledSecondaryButton
          onClick={localMessage ? () => setOpen(false) : clearMessage}
        >
          <img src={closeX} alt="close" width="12" height="12" />
        </StyledSecondaryButton>
      </Box>
      {message}
    </FormSuccessIndicatorText>
  ) : null
}

const FormSuccessIndicatorText = styled.div((props) => ({
  display: "flex",
  alignItems: "center",
  padding: props.theme.space.md,
  color: props.theme.color.green,
  fontWeight: props.theme.text.weights.heavy,
  border: "1px solid black",
  borderColor: props.theme.color.green,
  backgroundColor: props.theme.color.lightGreen,
}))

const StyledSecondaryButton = styled(SecondaryButtonInline)({
  padding: 0,
})
