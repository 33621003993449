import { supportBlue } from "../utils/icons"
import { supportPath } from "../paths"
import { NavLink } from "react-router-dom"

export function SupportPageLink() {
  return (
    <NavLink to={supportPath()}>
      <img src={supportBlue} alt="support" />
    </NavLink>
  )
}
