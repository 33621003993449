import styled from "@emotion/styled"
import { Dialog as ReachDialog } from "@reach/dialog"
import * as CSS from "csstype"
import React from "react"
import { useIsMobile } from "../utils/hooks/commonMediaQueryHooks"
import { closeX } from "../utils/icons"
import { Box } from "./Box"
import { SecondaryButtonInline } from "./Buttons/SecondaryButtonInline"

type DialogProps = {
  show: boolean
  onClose?: () => void
  cancelButton?: React.ReactNode
  confirmButton?: React.ReactNode
  body?: React.ReactNode
  ariaLabel: string
  minWidth?: CSS.Properties["minWidth"]
}

export function Dialog({
  show,
  onClose,
  cancelButton,
  confirmButton,
  body,
  ariaLabel,
}: DialogProps) {
  return show ? (
    <StyledDialog aria-label={ariaLabel}>
      <Box display="flex" justifyContent="flex-end" mb="lg">
        <CloseButton onClick={onClose}>
          <img src={closeX} alt="close" />
        </CloseButton>
      </Box>
      <Box m="lg">
        <Box textAlign="center" mb="xl">
          {body}
        </Box>

        <Box mt="md" display="flex" justifyContent="space-around">
          {cancelButton}
          {confirmButton}
        </Box>
      </Box>
    </StyledDialog>
  ) : null
}

const StyledDialog = styled(ReachDialog)((props) => {
  const isMobile = useIsMobile()
  return {
    width: isMobile ? "50%" : "fit-content",
    minWidth: isMobile ? "320px" : "600px",
    padding: props.theme.space.xs,
  }
})

const CloseButton = styled(SecondaryButtonInline)({
  margin: 0,
})
