import styled from "@emotion/styled"
import { useFormik } from "formik"
import { useMutation } from "react-query"
import { NavLink, useSearchParams } from "react-router-dom"
import * as Yup from "yup"
import { Box } from "../../components/Box"
import { PrimaryButton } from "../../components/Buttons/PrimaryButton"
import { ErrorIndicator } from "../../components/ErrorIndicator"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { Text } from "../../components/Text"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import { landingPath } from "../../paths"
import textureBGGray from "../../utils/backgrounds/ur_gray.jpg"
import { useFlashMessageContext } from "../../utils/contexts/FlashMessageContext"
import { useMutationRequest } from "../../utils/oviss"
import {
  passwordValidation,
  PASSWORD_RULES,
} from "../AccountScreen/AccountScreen"

export function ResetPasswordScreen() {
  const flashContext = useFlashMessageContext()
  const [searchParams] = useSearchParams()
  const request = useMutationRequest()
  useDocumentTitle("Reset Password")
  const { mutateAsync, isSuccess, isLoading } = useMutation(
    ({
      userId,
      password,
      confirmationPassword,
      resetCode,
    }: {
      userId: number
      password: string
      confirmationPassword: string
      resetCode: string
    }) => {
      return request({
        path: "/api/Users/ResetForgotPassword",
        method: "PUT",
        body: {
          UserId: userId,
          Password: password,
          ConfirmationPassword: confirmationPassword,
          ResetCode: resetCode,
        },
      })
    },
    {
      onError: (e) => flashContext.setMessage(<ErrorIndicator error={e} />),
    },
  )
  const formik = useFormik({
    initialValues: {
      // Editable form elements
      password: "",
      confirmationPassword: "",
      // Static params from the email URL
      userId: parseInt(searchParams.get("UserId") || "0", 10),
      resetCode: searchParams.get("ResetCode") || "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("Required")
        .test("password-format", PASSWORD_RULES, passwordValidation),
      confirmationPassword: Yup.string().oneOf(
        [Yup.ref("password"), null],
        "Passwords must match",
      ),
    }),
    onSubmit: async (values) => {
      return await mutateAsync(values)
    },
  })

  return (
    <Box
      background={`url(${textureBGGray}) black`}
      display="grid"
      justifyItems="center"
      flex={1}
    >
      <Box
        bg="white"
        m="xl"
        p="xl"
        minHeight="200px"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          mb="lg"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Text fontSize="xxl" fontWeight="bold">
            Change Your Password
          </Text>
        </Box>

        {isLoading ? (
          <LoadingIndicator colorScheme="black" />
        ) : isSuccess ? (
          <Box
            textAlign="center"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Box mb="sm">
              <Text fontSize="lg" fontWeight="bold">
                Password reset complete.
              </Text>
            </Box>

            <Text fontSize="lg" fontWeight="bold">
              <NavLink to={landingPath()}>Return to login page</NavLink>
            </Text>
          </Box>
        ) : (
          <>
            <Box mb="md" textAlign="center">
              <Text fontSize="xl" fontWeight="bold">
                Enter your new password and confirm it below.
              </Text>
            </Box>
            <Text fontSize="sm">{PASSWORD_RULES}</Text>
            <br />
            {flashContext.message && <Box mb="sm">{flashContext.message}</Box>}
            <form onSubmit={formik.handleSubmit}>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <StyledLabel>
                  New Password
                  <LabelErrorIndicator>
                    {formik.touched.password && formik.errors.password}
                  </LabelErrorIndicator>
                </StyledLabel>
                <StyledInput
                  type="password"
                  id="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Box>

              <StyledHr />

              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <StyledLabel>
                  Confirm New Password
                  <LabelErrorIndicator>
                    {formik.touched.confirmationPassword &&
                      formik.errors.confirmationPassword}
                  </LabelErrorIndicator>
                </StyledLabel>
                <StyledInput
                  type="password"
                  id="confirmationPassword"
                  value={formik.values.confirmationPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Box>

              <StyledHr />

              <Box mt="lg" display="flex" alignItems="center">
                <PrimaryButton type="submit" width="100%">
                  Change password
                </PrimaryButton>
              </Box>
            </form>
          </>
        )}
      </Box>
    </Box>
  )
}

const StyledLabel = styled.label((props) => ({
  color: props.theme.color.medGray,
  fontSize: props.theme.text.sizes.sm,
  marginBottom: props.theme.space.xxs,
}))

const StyledInput = styled.input((props) => ({
  border: "none",
  margin: 0,
  padding: 0,
  color: props.theme.color.darkGray,
  fontSize: props.theme.text.sizes.xl,
  fontFamily: "'Barlow', sans-serif",
}))

const LabelErrorIndicator = styled.span((props) => ({
  marginLeft: props.theme.space.xs,
  color: props.theme.color.warning,
  whiteSpace: "pre-wrap",
}))

const StyledHr = styled.hr((props) => ({
  backgroundColor: props.theme.color.darkGray,
}))
