import { saveAs } from "file-saver"
import { stringify } from "qs"
import { useMemo } from "react"
import { MutationFunction } from "react-query"
import { useLoginContext } from "../utils/contexts/LoginContext"

const API_ROOT_LOCALSTORAGE_KEY = "ur-customer-portal-api-root"

export function getApiRootFromLocalstorage() {
  return localStorage.getItem(API_ROOT_LOCALSTORAGE_KEY)
}

export function setApiRootInLocalstorage(v: string) {
  if (v === "") {
    clearApiRootInLocalstorage()
  } else {
    localStorage.setItem(API_ROOT_LOCALSTORAGE_KEY, v)
    clearTokenInLocalstorage()
  }
}

export function clearApiRootInLocalstorage() {
  localStorage.removeItem(API_ROOT_LOCALSTORAGE_KEY)
  clearTokenInLocalstorage()
}

if (
  process.env.REACT_APP_API_ROOT == null ||
  process.env.REACT_APP_API_ROOT.trim().length === 0
) {
  throw new Error(`REACT_APP_API_ROOT environment variable is missing`)
}

export const API_ROOT: string =
  getApiRootFromLocalstorage() || process.env.REACT_APP_API_ROOT

export const USING_DEFAULT_API_ROOT =
  (process.env.REACT_APP_API_ROOT as string) === API_ROOT

const CUSTOMER_PORTAL_TOKEN_KEY = "ur-customer-portal-token"

export function setTokenInLocalstorage(token: string) {
  localStorage.setItem(CUSTOMER_PORTAL_TOKEN_KEY, token)
}

export function getTokenInLocalstorage() {
  return localStorage.getItem(CUSTOMER_PORTAL_TOKEN_KEY)
}

export function clearTokenInLocalstorage() {
  localStorage.removeItem(CUSTOMER_PORTAL_TOKEN_KEY)
}

export async function createSessionRequest({
  username,
  password,
}: {
  username: string
  password: string
}) {
  const params = stringify({
    grant_type: "password",
    username,
    password,
  })

  try {
    const response = await fetch(`${API_ROOT}/token`, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      method: "POST",
      body: params,
    })

    const serverStatusMessage = `${response.statusText} (${response.status})`

    const data = await (async () => {
      try {
        return await response.json()
      } catch (e) {
        throw new Error(serverStatusMessage)
      }
    })()

    if (response.ok) {
      return {
        data,
        validationErrors: null,
        ok: response.ok,
        status: response.status,
      }
    } else {
      const errorMessage = data.error_description
      if (!errorMessage) {
        throw new Error(serverStatusMessage)
      }

      return {
        data,
        validationErrors: [
          {
            field: "password",
            message: errorMessage,
          },
        ],
        ok: response.ok,
        status: response.status,
      }
    }
  } catch (err) {
    return {
      data: null,
      ok: false,
      validationErrors: [
        {
          field: "password",
          message: err instanceof Error ? err.message : `unkown error`,
        },
      ],
    }
  }
}

type RequestParams = {
  token?: string | null | undefined
  path: string
  method?: "GET" | "POST" | "PATCH" | "PUT" | "DELETE"
  query?: object
  body?: object
}

export async function request({
  token,
  path,
  method = "GET",
  query,
  body,
}: RequestParams) {
  let url = `${API_ROOT}${path}`
  if (query) {
    url += `?${stringify(query)}`
  }

  let requestParams: {
    method: string
    body?: string
    headers: any
  } = {
    method,
    body: body ? JSON.stringify(body) : undefined,
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      ...(token != null && {
        Authorization: `bearer ${token}`,
      }),
    },
  }

  return fetch(url, requestParams)
}

/** request, but token is prefilled */
type UseRequestParams = Omit<RequestParams, "token">

export function useRequest() {
  const { token, logout } = useLoginContext()

  return useMemo(() => {
    async function useQueryRequestFn<T = any>({
      queryKey,
    }: {
      queryKey:
        | Readonly<[string, UseRequestParams]>
        | Readonly<[string]>
        | Readonly<unknown[]>
    }): Promise<T> {
      if (queryKey.length !== 2) throw new Error(`bad query key`)

      const requestArgs = queryKey[1] as UseRequestParams // don't know a better way to type this

      const result = await request({ token, ...requestArgs })
      // Read the body as text instead of JSON, in case we got an HTML response
      const responseText = await result.text()
      let responseJSON

      // Check for a non-OK status. Assume JSON for these error responses
      if (!result.ok) {
        try {
          responseJSON = JSON.parse(responseText)
        } catch (er) {
          if (result.status === 404) {
            throw new Error(`Not found`)
          } else {
            throw new Error(`Server status ${result.status}`)
          }
        }

        if (
          responseJSON.Message ===
          "Authorization has been denied for this request."
        ) {
          logout()
          // Explicit return following an auth failure
          throw new Error("authorization expired")
        }

        throw new Error(responseJSON.Message)
      } else if (result.status === 200 && responseText === "") {
        // The update password response is 200 with an empty body. Later checks
        // in this function will erroneously mark this as an error case so we check
        // for it here
        return {} as T
      }

      try {
        responseJSON = JSON.parse(responseText)
        return responseJSON as T
      } catch (error) {
        // If we've failed to parse the response as JSON, search through the response for
        // the error indicator
        throw new Error(`Server failure: ${responseText}`)
      }
    }
    return useQueryRequestFn
  }, [logout, token])
}

export function useMutationRequest<TData = any>() {
  const { token, logout } = useLoginContext()

  return useMemo(() => {
    const useMutationRequestFn: MutationFunction<TData, UseRequestParams> =
      async (variables): Promise<TData> => {
        const result = await request({ token, ...variables })
        // Read the body as text instead of JSON, in case we got an HTML response
        const responseText = await result.text()
        let responseJSON

        // Check for a non-OK status. Assume JSON for these error responses
        if (!result.ok) {
          responseJSON = JSON.parse(responseText)

          // Incorrect/invalid token message. Clear our token and redirect to the login page
          if (
            responseJSON.Message ===
            "Authorization has been denied for this request."
          ) {
            logout()
            // Explicit return following an auth failure
            throw new Error("authorization expired")
          }

          // Server error responses are either an object with a `Message` attribute
          // or a string. Try `Message` first.
          throw new Error(responseJSON.Message || responseJSON)
        } else if (result.status === 200 && responseText === "") {
          // The update password response is 200 with an empty body. Later checks
          // in this function will erroneously mark this as an error case so we check
          // for it here
          return {} as TData
        }

        try {
          responseJSON = JSON.parse(responseText)
          return responseJSON as TData
        } catch (error) {
          // If we've failed to parse the response as JSON, search through the response for
          // the error indicator
          throw new Error(`Server failure: ${responseText}`)
        }
      }
    return useMutationRequestFn
  }, [logout, token])
}

export function useFileViewRequest() {
  const { token, logout } = useLoginContext()

  return useMemo(() => {
    async function useRequestFn(
      requestArgs: UseRequestParams,
    ): Promise<string> {
      const result = await request({ token, ...requestArgs })

      if (!result.ok) {
        const responseJSON = await result.json()

        // Incorrect/invalid token message. Clear our token and redirect to the login page
        if (
          responseJSON.Message ===
          "Authorization has been denied for this request."
        ) {
          logout()
          // Explicit return following an auth failure
          throw new Error("authorization expired")
        }

        // Server error responses are either an object with a `Message` attribute
        // or a string. Try `Message` first.
        throw new Error(responseJSON.Message || responseJSON)
      }

      const responseBlob = await result.blob()

      return URL.createObjectURL(responseBlob)
    }
    return useRequestFn
  }, [token, logout])
}

export function useDownloadRequest() {
  const { token, logout } = useLoginContext()

  return useMemo(() => {
    async function useRequestFn(
      requestArgs: UseRequestParams,
      saveToFilename: string,
    ): Promise<void> {
      const result = await request({ token, ...requestArgs })

      if (!result.ok) {
        const responseJSON = await result.json()

        // Incorrect/invalid token message. Clear our token and redirect to the login page
        if (
          responseJSON.Message ===
          "Authorization has been denied for this request."
        ) {
          logout()
          // Explicit return following an auth failure
          throw new Error("authorization expired")
        }

        throw new Error(responseJSON.Message)
      }

      const responseBlob = await result.blob()

      return saveAs(responseBlob, saveToFilename)
    }
    return useRequestFn
  }, [token, logout])
}
