import styled from "@emotion/styled"

export const DialogLabel = styled.label((props) => ({
  color: props.theme.color.medGray,
  fontSize: props.theme.text.sizes.sm,
  marginBottom: props.theme.space.xxs,
  textAlign: "left",
}))

export const DialogInput = styled.input((props) => ({
  border: "none",
  margin: 0,
  padding: 0,
  color: props.theme.color.darkGray,
  fontSize: props.theme.text.sizes.xl,
  fontFamily: "'Barlow', sans-serif",
}))

export const DialogLabelErrorIndicator = styled.span((props) => ({
  marginLeft: props.theme.space.xs,
  color: props.theme.color.warning,
  whiteSpace: "pre-wrap",
}))

export const DialogTextarea = styled.textarea((props) => ({
  border: "none",
  margin: 0,
  padding: 0,
  color: props.theme.color.darkGray,
  fontSize: props.theme.text.sizes.xl,
  fontFamily: "'Barlow', sans-serif",
}))
