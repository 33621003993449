import styled from "@emotion/styled"

export const PrimaryButton = styled.button<{ width?: string | number }>(
  (props) => ({
    backgroundColor: !props.disabled
      ? props.theme.color.green
      : props.theme.color.lightGray,
    color: props.theme.color.white,
    border: "none",
    height: 40,
    textAlign: "center",
    width: props.width || 340,
    margin: "0 auto",
    cursor: !props.disabled ? "pointer" : "not-allowed",
    fontFamily: "'Barlow', sans-serif",
    fontSize: props.theme.text.sizes.med,
    fontWeight: props.theme.text.weights.bold,
    paddingLeft: props.theme.space.lg,
    paddingRight: props.theme.space.lg,
  }),
)
// Give this button a default type of "button"
PrimaryButton.defaultProps = {
  type: "button",
}

export const CancelButton = styled(PrimaryButton)<{ colorTheme?: "white" }>(
  (props) => ({
    backgroundColor:
      props.colorTheme === "white"
        ? props.theme.color.white
        : props.theme.color.warning,
    border:
      props.colorTheme === "white"
        ? `1px solid ${props.theme.color.black}`
        : "none",
    color:
      props.colorTheme === "white"
        ? props.theme.color.black
        : props.theme.color.white,
  }),
)
