import { useCallback } from "react"
import { useMutation, useQueryClient } from "react-query"
import { orderDetailsQueryKey } from "../queryKeys"
import {
  priority_icon_black,
  priority_icon_filled,
  priority_icon_white,
} from "../utils/icons"
import { useMutationRequest } from "../utils/oviss"
import { ApiOrder, InfiniteListOrdersResponse } from "../utils/types"
import { Box } from "./Box"
import { MinimalSecondaryButton } from "./Buttons/SecondaryButtonInline"

type TogglePriorityButtonProps = {
  priority: "high" | "normal"
  colorScheme?: "light" | "dark"
  orderID: number
  children?: React.ReactNode
}

export function TogglePriorityButton({
  priority,
  orderID,
  colorScheme = "dark",
  children,
}: TogglePriorityButtonProps) {
  const queryClient = useQueryClient()
  const mutationRequest = useMutationRequest()

  const updatePriority = useCallback(() => {
    return mutationRequest({
      path: `/api/CustomerPortalOrders/PriorityOrder/${orderID}`,
      // Clear or set the priority flag with DELETE/POST methods
      // to this same path
      method: priority === "high" ? "DELETE" : "POST",
    })
  }, [priority, orderID, mutationRequest])

  const updatePriorityMutation = useMutation(updatePriority, {
    onSuccess: () => {
      const cache = queryClient.getQueryCache()
      const listOrderQueries = cache.findAll("orders")

      // Check *ALL* list order responses for the updated order
      listOrderQueries.forEach((key: any) => {
        queryClient.setQueryData<InfiniteListOrdersResponse | undefined>(
          key,
          (cachedData) => {
            if (!cachedData) {
              console.error(`Couldn't find query key ${key}`)
              return cachedData
            }

            return {
              ...cachedData,
              pages: cachedData.pages.map((page) => {
                return {
                  ...page,
                  Records: page.Records
                    ? page.Records.map((order) =>
                        order.OrderId !== orderID
                          ? order
                          : {
                              ...order,
                              IsUserPriority:
                                priority === "high" ? false : true,
                            },
                      )
                    : [],
                }
              }),
            }
          },
        )
      })

      // Update the details query for the updated order, if we have fetched it.
      queryClient.setQueryData<ApiOrder | undefined>(
        orderDetailsQueryKey(orderID),
        (cachedOrder) => {
          if (!cachedOrder) {
            // Don't log an error for this case -- it's very possible that we haven't fetched this order yet.
            return cachedOrder
          }

          return {
            ...cachedOrder,
            IsUserPriority: priority === "high" ? false : true,
          }
        },
      )
    },
  })

  return (
    <Box display="flex" alignItems="center">
      <MinimalSecondaryButton
        type="button"
        onClick={async (e) => {
          e.stopPropagation()
          e.preventDefault()
          await updatePriorityMutation.mutateAsync()
        }}
      >
        <img
          width={24}
          height={24}
          src={
            priority === "high"
              ? priority_icon_filled
              : colorScheme === "dark"
              ? priority_icon_black
              : priority_icon_white
          }
          alt={priority === "high" ? "high priority" : "normal priority"}
        />
        {children}
      </MinimalSecondaryButton>
    </Box>
  )
}
