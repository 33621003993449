import styled from "@emotion/styled"
import { useFormik } from "formik"
import { useState } from "react"
import { useMutation } from "react-query"
import * as Yup from "yup"
import { useFlashMessageContext } from "../utils/contexts/FlashMessageContext"
import { useCurrentUserQuery } from "../utils/hooks/useCurrentUserQuery"
import { emailBlack, emailWhite } from "../utils/icons"
import { useMutationRequest } from "../utils/oviss"
import { Box } from "./Box"
import { CancelButton, PrimaryButton } from "./Buttons/PrimaryButton"
import { SecondaryButton } from "./Buttons/SecondaryButton"
import { Dialog } from "./Dialog"
import { FormSuccessIndicator } from "./FormSuccessIndicator"
import { LoadingIndicator } from "./LoadingIndicator/LoadingIndicator"
import { Text } from "./Text"
import * as ApiV1 from "../generated/ApiV1"
import {
  DialogLabel,
  DialogLabelErrorIndicator,
  DialogInput,
  DialogTextarea,
} from "./DialogUI"

type EmailOrderListButtonProps = {
  colorTheme?: "light" | "dark"
  listParams?: Partial<
    ApiV1.paths["/api/CustomerPortalOrders/ListOrders"]["post"]["parameters"]["body"]["request"]
  >
  children?: React.ReactNode
  iconSize?: number
}

export function EmailOrderListButton({
  colorTheme = "light",
  listParams,
  children = undefined,
  iconSize = 24,
}: EmailOrderListButtonProps) {
  const flashContext = useFlashMessageContext()
  const { data: user } = useCurrentUserQuery()
  const request = useMutationRequest()
  const formik = useFormik({
    initialValues: {
      email: "",
      subject: "",
      body: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Invalid email").required("Required"),
      subject: Yup.string().required("Required"),
      body: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { setStatus }) => {
      try {
        await mutateAsync(values)
        flashContext.setMessage(
          <FormSuccessIndicator message="Email sent successfully!" />,
        )
        close()
      } catch (e) {
        setStatus({ type: "error", message: e.message })
      }
    },
  })
  const { mutateAsync } = useMutation(
    ({
      email,
      subject,
      body,
    }: {
      email: string
      subject: string
      body: string
    }) => {
      const clientId = user?.Client.ClientId
      if (!clientId) throw new Error("Missing ClientId")
      return request({
        path: "/api/CustomerPortalOrders/ListOrders",
        method: "POST",
        body: {
          ...listParams,
          IncludeVehicles: true,
          ResponseFormat: "Email",
          ResponseAsAttachment: true,
          EmailResponseToAddress: email,
          EmailResponseSubject: subject,
          EmailResponseBody: body,
          OrderClients: [clientId],
        },
        query: {
          resultType: "Email",
        },
      })
    },
  )
  const [showDialog, setShowDialog] = useState(false)
  const open = () => setShowDialog(true)
  const close = () => setShowDialog(false)

  return (
    <>
      <StyledSecondaryButton
        onClick={(e: any) => {
          e.preventDefault()
          e.stopPropagation()
          open()
        }}
        title="Email Orders"
      >
        <Box display="flex" alignItems="center">
          <img
            src={colorTheme === "light" ? emailWhite : emailBlack}
            width={iconSize}
            height={iconSize}
            alt="email"
          />
          {children && <Box ml="xs">{children}</Box>}
        </Box>
      </StyledSecondaryButton>

      <Dialog
        ariaLabel="send-email"
        onClose={close}
        show={showDialog}
        body={
          <>
            <Text color="darkGray" fontSize="xxl">
              Email Order List
            </Text>
            <Box mt="xxl">
              <form onSubmit={formik.handleSubmit}>
                <Box
                  mt="lg"
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <DialogLabel>
                    Email
                    <DialogLabelErrorIndicator>
                      {formik.touched.email && formik.errors.email}
                    </DialogLabelErrorIndicator>
                  </DialogLabel>
                  <DialogInput
                    id="email"
                    name="email"
                    type="text"
                    placeholder="Email address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                </Box>

                <StyledHr />

                <Box
                  mt="lg"
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <DialogLabel>
                    Subject
                    <DialogLabelErrorIndicator>
                      {formik.touched.subject && formik.errors.subject}
                    </DialogLabelErrorIndicator>
                  </DialogLabel>
                  <DialogInput
                    id="subject"
                    name="subject"
                    type="text"
                    placeholder="Email subject"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.subject}
                  />
                </Box>

                <StyledHr />

                <Box
                  mt="lg"
                  display="flex"
                  justifyContent="center"
                  flexDirection="column"
                >
                  <DialogLabel>
                    Your message
                    <DialogLabelErrorIndicator>
                      {formik.touched.body && formik.errors.body}
                    </DialogLabelErrorIndicator>
                  </DialogLabel>
                  <DialogTextarea
                    id="body"
                    name="body"
                    placeholder="Write your message here..."
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.body}
                  />
                </Box>

                <Box display="flex" justifyContent="center" mt="sm">
                  {formik.status?.type === "error" && (
                    <ErrorIndicatorWithoutLabel>
                      Error sending email: {formik.status.message}
                    </ErrorIndicatorWithoutLabel>
                  )}
                </Box>
              </form>
            </Box>
          </>
        }
        cancelButton={
          formik.isSubmitting ? (
            <LoadingIndicator colorScheme="black" />
          ) : (
            <CancelButton colorTheme="white" width="auto" onClick={close}>
              Cancel
            </CancelButton>
          )
        }
        confirmButton={
          formik.isSubmitting ? null : (
            <PrimaryButton
              type="button"
              width="auto"
              disabled={!formik.dirty || !formik.isValid}
              onClick={formik.submitForm}
            >
              Send
            </PrimaryButton>
          )
        }
      />
    </>
  )
}

const StyledSecondaryButton = styled(SecondaryButton)({
  padding: 0,
  textDecoration: "none",
})

const StyledHr = styled.hr((props) => ({
  backgroundColor: props.theme.color.darkGray,
}))

const ErrorIndicatorWithoutLabel = styled(DialogLabelErrorIndicator)({
  marginLeft: 0,
})
