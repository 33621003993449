import styled from "@emotion/styled"
import currency from "currency.js"
import { Field, Form, FormikProvider, useFormik } from "formik"
import { useMutation } from "react-query"
import invariant from "tiny-invariant"
import { Box } from "../../components/Box"
import { Button, ButtonAnchor } from "../../components/Buttons/Button"
import { CheckboxLine } from "../../components/CheckboxLine"
import { ResultName } from "../../components/ClientAutocompleteInput"
import { CustomerCareLink } from "../../components/CustomerCareLink"
import { ErrorIndicator } from "../../components/ErrorIndicator"
import { Divider } from "../../components/HorizontalDivider"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { Text } from "../../components/Text"
import * as ApiV1 from "../../generated/ApiV1"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import { newOrderPath } from "../../paths"
import { getRequiredEnvVar } from "../../util/environment"
import { formatUsd } from "../../utils/currency"
import { useMutationRequest } from "../../utils/oviss"
import { formatDatetimeString } from "../../utils/utils"
import {
  Container,
  Label,
  SectionTitle,
  Title,
  TwoColumn,
  VehicleBasics,
  VehicleContainer,
  VehicleControlContainer,
} from "./NewOrderScreenComponents"
import { Fields, OrderResponse, PricingResponses } from "./types"
import { useOvissClient } from "./useOvissClient"

export const OrderSummary = ({
  prices,
  fields,
  onOrderCreated,
}: {
  prices: PricingResponses
  fields: Fields
  onOrderCreated: (orderResponse: OrderResponse, fields: Fields) => void
}) => {
  useDocumentTitle(`Quote Summary`)
  const formik = useFormik({
    initialValues: {
      pickupComments: "",
      deliveryComments: "",
      isRushSold: fields.isRushSold,
    },
    onSubmit: async (values) => {
      await mutateAsync({
        pickupComments: values.pickupComments,
        deliveryComments: values.deliveryComments,
      })
    },
  })

  const selectedPricing = formik.values.isRushSold
    ? prices.rushSold
    : prices.noRushSold

  const selectedDueDate = formik.values.isRushSold
    ? fields.dueLatestRushSold
    : fields.dueLatestNoRushSold

  const price = parseInt(selectedPricing.optimal_price, 10)

  const originResult = useOvissClient(`originResult`, fields.originId)

  const destinationResult = useOvissClient(
    `destinationResult`,
    fields.destinationId,
  )

  const mutationRequest = useMutationRequest()

  const optimalPriceByVehicle = currency(
    selectedPricing.optimal_price,
  ).distribute(fields.vehicles.length)
  const carrierPayByVehicle = currency(
    selectedPricing.carrier_pay_oviss,
  ).distribute(fields.vehicles.length)

  invariant(optimalPriceByVehicle.length === fields.vehicles.length)
  invariant(carrierPayByVehicle.length === fields.vehicles.length)

  const { mutateAsync, status, error, reset } = useMutation(
    ({
      pickupComments,
      deliveryComments,
    }: {
      pickupComments: string
      deliveryComments: string
    }) => {
      const payload: ApiV1.paths["/api/OrderEntry"]["post"]["parameters"]["body"]["orderDto"] =
        {
          BillOfLading: {
            RushSold: false,
            TwicRequired: false,
            CallInAdvance: false,

            BolComments: [
              {
                CommentType: "PickUpComment",
                Collection: "Order",
                ItemCollected: pickupComments,
                AddToLoadParent: false,
              },
              {
                CommentType: "DeliveryComment",
                Collection: "Order",
                ItemCollected: deliveryComments,
                AddToLoadParent: false,
              },
            ].filter((comment) => comment.ItemCollected.trim().length > 0),
          },

          Carrier: {
            ShipperId: parseInt(
              getRequiredEnvVar(
                process.env.REACT_APP_OVISS_CREATE_ORDER_SHIPPER_ID,
                "94316",
              ),
              10,
            ),
            IsBrokered: false,
            NoChargeQuickPay: false,
            PayType: "Credit",
            DoNotInvoice: false,
          },

          Miles: fields.distance?.Miles,

          AdditionalCharges: [],

          // empty
          RateCharges: [],

          Vehicles: fields.vehicles.map((vehicle, index) => {
            return {
              Vin: vehicle.vehicle.Vin,

              Year: vehicle.vehicle.Year,
              Make: vehicle.vehicle.Make,
              Model: vehicle.vehicle.Model,
              SubModel: vehicle.vehicle.SubModel,

              Price: optimalPriceByVehicle[index].value,
              CarrierPay: carrierPayByVehicle[index].value,

              PriceGenerated: false,

              LockInCarrierPayable: false,
              LockInPrice: false,

              // TODO follow up with Greg
              // Size: "string",

              // from vindecode
              Height: vehicle.vehicle.Height,
              Length: vehicle.vehicle.Length,
              Width: vehicle.vehicle.Width,
              Weight: vehicle.vehicle.Weight,
              Wheelbase: vehicle.vehicle.Wheelbase,

              IsShuttle: false,

              Keys: vehicle.fields.hasKeys,
              Inop: vehicle.fields.inop,

              ShowInCI: true,
              Enclosed: fields.isEnclosed,

              // TODO omit for now, follow up with Kevin
              // RefNo1: "string",
              // RefNo2: "string",

              SizeCode:
                vehicle.vehicle.SizeCode != null
                  ? vehicle.vehicle.SizeCode.toString()
                  : undefined,
              StyleId: vehicle.vehicle.StyleId?.toString(),
              Decoded: true,

              RateCharges: [],
            }
          }),
          // consigner
          BillTo: {
            ClientId: parseInt(fields.consignorId, 10),
            // TODO? omit - TBD Greg
            ContactId: 0,
          },
          Consignor: {
            ClientId: parseInt(fields.consignorId, 10),
            // TODO? omit - TBD Greg
            ContactId: 0,
          },
          Destination: {
            ClientId: parseInt(fields.destinationId, 10),
          },
          Origin: {
            ClientId: parseInt(fields.originId, 10),
          },
          OrderDates: {
            EarliestPickupDate: fields.requestedEarliest,
            LatestPickupDate: fields.requestedEarliest,
            LatestDueDate: selectedDueDate,
          },
          // 1 VIN => Individual, multiple => FPLoad (note – Greg indicated to use Load here)
          CreateOrderOption:
            fields.vehicles.length === 1 ? "Individual" : "Load",
          OrderStatus: "Inactive",
          // base 64 random guid - and ALSO include this with pricing call
          QuoteId: fields.randomQuoteUuid,
        }

      return mutationRequest({
        path: "/api/OrderEntry",
        method: "POST",
        body: payload,
      })
    },
    {
      onSuccess: async (data) => {
        const updatedFields: Fields = {
          ...fields,
          isRushSold: formik.values.isRushSold,
        }
        onOrderCreated(data, updatedFields)
      },
    },
  )

  if (
    originResult.status === "loading" ||
    destinationResult.status === "loading"
  ) {
    return <LoadingIndicator />
  }

  if (originResult.status === "error") {
    return <ErrorIndicator error={originResult.error} />
  }
  if (destinationResult.status === "error") {
    return <ErrorIndicator error={destinationResult.error} />
  }

  if (Number.isNaN(price)) {
    return (
      <ErrorIndicator
        error={new Error("Unexpected error with pricing response")}
      />
    )
  }

  invariant(originResult.data)
  invariant(destinationResult.data)
  invariant(fields.originName)
  invariant(fields.destinationName)

  if (error) {
    return (
      <Container>
        <Box p="lg" bg="white" maxWidth="1024px" width="100%">
          <Title>Issue Creating Your Order</Title>
          <SectionTitle>
            Please reach out to customer support at <CustomerCareLink /> for
            assistance
          </SectionTitle>
          <ErrorIndicator error={error} />

          <Box flex={1} display="grid" pt="lg" gridColumns={2} gap="10px">
            <ButtonAnchor href={newOrderPath()} bg="green">
              Create New Order
            </ButtonAnchor>
            <Button
              onClick={() => reset()}
              type="button"
              bg="transparent"
              color="darkGray"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Container>
    )
  }

  const rushCostDifference =
    parseInt(prices.rushSold.optimal_price, 10) -
    parseInt(prices.noRushSold.optimal_price, 10)

  return (
    <FormikProvider value={formik}>
      <Form>
        <Container>
          <Box p="lg" bg="white" maxWidth="1024px" width="100%">
            <Title>Quote Summary</Title>
            <PickupDeliverRow
              ovissClient={originResult.data}
              name={fields.originName!}
              label="PICK-UP"
              dateLabel="READY DATE"
              date={fields.requestedEarliest}
            />
            <Divider color="lightGray" />
            <PickupDeliverRow
              ovissClient={destinationResult.data}
              name={fields.destinationName!}
              label="DELIVERY"
              dateLabel="DUE DATE"
              date={selectedDueDate}
            />
            <Divider color="lightGray" />
            <Box mb="md">
              {fields.vehicles.map(({ vehicle, fields: vFields }, index) => (
                <VehicleContainer key={vehicle.Vin}>
                  <VehicleBasics vehicle={vehicle} />
                  <VehicleControlContainer>
                    <CheckboxLine
                      name={`vehicles.${index}.fields.inop`}
                      displayName="Inoperable"
                      checked={vFields.inop}
                      disabled={true}
                    />
                  </VehicleControlContainer>
                  <VehicleControlContainer>
                    <CheckboxLine
                      name={`vehicles.${index}.fields.hasKeys`}
                      displayName="Has Keys"
                      checked={vFields.hasKeys}
                      disabled={true}
                    />
                  </VehicleControlContainer>
                </VehicleContainer>
              ))}
            </Box>
            <TwoColumn>
              <Box display="flex" flexDirection="column">
                <Box mb="xxs">
                  <Label htmlFor="pickupComments">Pickup comments</Label>
                </Box>
                <CommentField name="pickupComments" id="pickupComments" />
              </Box>
              <Box display="flex" flexDirection="column">
                <Box mb="xxs">
                  <Label htmlFor="deliveryComments">Delivery comments</Label>
                </Box>
                <CommentField name="deliveryComments" id="deliveryComments" />
              </Box>
            </TwoColumn>

            {/* <Box mt="xl">
            <Divider color="lightGray" />
            <pre>Debug: {JSON.stringify({ pricing, fields }, null, 2)}</pre>
          </Box> */}
            <TwoColumn>
              <Box flex={1} />

              <Box flex={1}>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{ borderBottom: "1px solid #D8D8D8" }}
                >
                  <Box ml="md" mr="md">
                    <Field
                      as={BlackInput}
                      type="checkbox"
                      name="isRushSold"
                      id="isRushSold"
                    />
                  </Box>
                  <Box pb="lg" pt="lg" mr="auto" pr="lg">
                    <label htmlFor="isRushSold">
                      <PriceLabel>
                        {formik.values.isRushSold
                          ? `Rush Shipping Cost:`
                          : `Need Your Order Faster?`}
                      </PriceLabel>
                      <Box>
                        <Text>
                          {formik.values.isRushSold
                            ? `*Removing “Rush Shipping” will change your delivery date.`
                            : `*Select checkbox to calculate rush shipping cost and see updated delivery date.`}
                        </Text>
                      </Box>
                    </label>
                  </Box>

                  {formik.values.isRushSold ? (
                    <PriceValue>
                      {rushCostDifference > 0 ? `+` : ``}
                      {formatUsd(rushCostDifference)}
                    </PriceValue>
                  ) : null}
                </Box>
                <SectionTitle></SectionTitle>
                <Box display="flex" alignItems="center">
                  {/* spacer */}
                  <Box ml="md" mr="md">
                    <BlackInput
                      type="checkbox"
                      disabled={true}
                      style={{ visibility: "hidden" }}
                    />
                  </Box>
                  <Box pb="lg" pt="lg" mr="auto" pr="lg">
                    <PriceLabel>Total Load Price:</PriceLabel>
                  </Box>
                  <PriceValue>{formatUsd(price)}</PriceValue>
                </Box>
                <Divider color="lightGray" />
                <Box flex={1}>
                  <Button
                    type="submit"
                    bg="green"
                    disabled={status === "loading"}
                    block={true}
                  >
                    Create Quote
                  </Button>
                </Box>
                <Box flex={1}>
                  <Button
                    type="button"
                    bg="transparent"
                    color="darkGray"
                    onClick={() => {
                      window.location.href = newOrderPath()
                    }}
                    block={true}
                  >
                    Discard order
                  </Button>
                </Box>
              </Box>
            </TwoColumn>
          </Box>
        </Container>
      </Form>
    </FormikProvider>
  )
}

const PickupDeliverRow = (props: {
  ovissClient: ApiV1.definitions["VTVSolutions.Vlms.Dtos.Clients.ClientDto"]
  label: string
  dateLabel: string
  date: string
  name: string
}) => {
  const dateFormatted = formatDatetimeString(props.date)
  return (
    <Box pv="lg">
      <TwoColumn centerVertical={true}>
        <Box>
          <Text fontWeight="med" fontSize="xxs">
            {props.label /* e.g. PICK-UP */}
          </Text>

          <ResultName name={props.name} />
          {/* <Text fontSize="lg" fontWeight="bold">
            {props.ovissClient.Name}
          </Text>
          <Text fontSize="lg">
            {props.ovissClient.State} {props.ovissClient.Zip}
          </Text> */}
        </Box>
        <Box>
          <Box mb="xxs">
            <Text fontWeight="med" fontSize="xxs">
              {props.dateLabel /* e.g. REQUESTED EARLIEST */}
            </Text>
          </Box>
          <Text fontSize="lg">{dateFormatted}</Text>
        </Box>
      </TwoColumn>
    </Box>
  )
}

const CommentField = styled(Field)({
  flex: 1,
  padding: `10px`,
})

CommentField.defaultProps = {
  rows: 4,
  as: "textarea",
  maxLength: 255,
}

const BlackInput = styled.input({
  accentColor: "black",
  width: 24,
  height: 24,
})

const PriceLabel = styled.div({
  fontSize: "21px",
  lineHeight: "30px",
})

const PriceValue = styled.div((props) => ({
  fontWeight: props.theme.text.weights.heavy,
  fontSize: "21px",
  lineHeight: "30px",
}))
