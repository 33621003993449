import styled from "@emotion/styled"
import { useFormik } from "formik"
import { useMutation } from "react-query"
import { Link } from "react-router-dom"
import { BackArrow } from "../../components/BackArrow"
import { Box } from "../../components/Box"
import {
  CancelButton,
  PrimaryButton,
} from "../../components/Buttons/PrimaryButton"
import { Container } from "../../components/Container"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { Text } from "../../components/Text"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import { accountPath } from "../../paths"
import { useGoBack } from "../../utils/hooks/useGoBack"
import { useMutationRequest } from "../../utils/oviss"

export function FeedbackScreen() {
  const goBack = useGoBack()
  const request = useMutationRequest()
  useDocumentTitle("Feedback")
  const formik = useFormik({
    initialValues: {
      message: "",
    },
    onSubmit: async (values, { setStatus }) => {
      try {
        await mutateAsync(values)
        setStatus({
          type: "success",
          message: "Feedback submitted successfully!",
        })
      } catch (e) {
        setStatus({ type: "error", message: e.message })
      }
    },
  })

  const { isLoading, mutateAsync } = useMutation(
    ({ message }: { message: string }) => {
      return request({
        path: "/api/CustomerPortalFeedback",
        method: "POST",
        query: {
          message,
        },
      })
    },
  )

  return (
    <Box m="sm">
      <Text color="darkGray">
        <Box display="flex">
          <div style={{ flex: 1 }}>
            <BackArrow theme="dark" />
          </div>

          <Text fontSize="xxl">Feedback</Text>

          <div style={{ flex: 1 }} />
        </Box>
        <Container>
          {formik.status?.type === "success" ? (
            <Box mt="xl" textAlign="center">
              <br />
              <Text fontSize="xl">{formik.status.message}</Text>
              <br />
              <Link to={accountPath()}>View Profile</Link>
            </Box>
          ) : (
            <form onSubmit={formik.handleSubmit}>
              <Box mt="xxl">
                <Box mb="sm">
                  <Text fontSize="xxl">We want to hear from you!</Text>
                </Box>
                <StyledTextarea
                  id="message"
                  name="message"
                  placeholder="Tell us about issues you are experiencing or what feature you'd like to see next."
                  value={formik.values.message}
                  onChange={formik.handleChange}
                />
              </Box>

              {isLoading ? (
                <LoadingIndicator colorScheme="black" />
              ) : (
                <>
                  <Box display="flex" justifyContent="center" mt="sm">
                    {formik.status?.type === "error" && (
                      <ErrorIndicatorWithoutLabel>
                        Error submitting feedback: ${formik.status.message}
                      </ErrorIndicatorWithoutLabel>
                    )}
                  </Box>
                  <Box mt="lg" display="flex" justifyContent="space-between">
                    <CancelButton onClick={goBack}>Cancel</CancelButton>
                    <ButtonSpacer />
                    <PrimaryButton type="submit" disabled={!formik.dirty}>
                      Submit
                    </PrimaryButton>
                  </Box>
                </>
              )}
            </form>
          )}
        </Container>
      </Text>
    </Box>
  )
}

const StyledTextarea = styled.textarea((props) => ({
  width: "100%",
  height: 200,
  padding: props.theme.space.sm,
  fontSize: props.theme.text.sizes.lg,
  fontFamily: "'Barlow', sans-serif",
}))

const ButtonSpacer = styled.div((props) => ({
  marginLeft: props.theme.space.xxs,
  marginRight: props.theme.space.xxs,
}))

const ErrorIndicator = styled.span((props) => ({
  marginLeft: props.theme.space.xs,
  color: props.theme.color.warning,
  whiteSpace: "pre-wrap",
}))

const ErrorIndicatorWithoutLabel = styled(ErrorIndicator)({
  marginLeft: 0,
})
