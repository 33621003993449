import styled from "@emotion/styled"
import * as CSS from "csstype"

export const GridArea = styled.div<{
  gridArea: CSS.Properties["gridArea"]
  width?: number | string
  height?: number | string
  alignSelf?: CSS.Properties["alignSelf"]
  justifySelf?: CSS.Properties["justifySelf"]
  hidden?: boolean
}>((props) => ({
  gridArea: props.gridArea,
  width: props.width,
  height: props.height,
  alignSelf: props.alignSelf,
  justifySelf: props.justifySelf,
  display: props.hidden ? "none" : undefined,
}))
