import { useEffect, useState } from "react"
import invariant from "tiny-invariant"
import { NewOrderForm } from "./NewOrderForm"
import { OrderCreated } from "./OrderCreated"
import { OrderSummary } from "./OrderSummary"
import { Fields, OrderResponse, PricingResponses } from "./types"

export const NewOrderScreen = () => {
  const [state, setState] = useState<
    | { status: "form" }
    | { status: "order-summary"; prices: PricingResponses; fields: Fields }
    | {
        status: "order-created"
        prices: PricingResponses
        fields: Fields
        orderResponse: OrderResponse
      }
  >({ status: "form" })

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [state.status])

  switch (state.status) {
    case "form":
      return (
        <NewOrderForm
          onPricingCreated={(prices, fields) =>
            setState({
              status: "order-summary",
              prices: prices,
              fields: fields,
            })
          }
        />
      )
    case "order-summary":
      return (
        <OrderSummary
          prices={state.prices}
          fields={state.fields}
          onOrderCreated={(orderResponse, fields) => {
            setState((prev) => {
              invariant(prev.status === "order-summary")
              return {
                status: "order-created",
                prices: prev.prices,
                fields,
                orderResponse: orderResponse,
              }
            })
          }}
        />
      )
    case "order-created":
      return (
        <OrderCreated
          pricing={
            state.fields.isRushSold
              ? state.prices.rushSold
              : state.prices.noRushSold
          }
          fields={state.fields}
          orderResponse={state.orderResponse}
        />
      )
  }
}
