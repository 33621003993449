import L, { LatLng, Map } from "leaflet"
import "leaflet-routing-machine"
import iconShadow from "leaflet/dist/images/marker-shadow.png"
import "leaflet/dist/leaflet.css"
import { FC, useEffect, useMemo, useState } from "react"
import { MapContainer, Marker, Popup, TileLayer, Tooltip } from "react-leaflet"
import { mapMarker } from "../utils/icons"
import { ApiOrder } from "../utils/types"
import { fullStreetAddress } from "../utils/utils"
import "./RoutesMap.css"

let DefaultIcon = L.icon({
  iconUrl: mapMarker,
  shadowUrl: iconShadow,
  iconSize: [35, 49],
  iconAnchor: [17.5, 49],
  popupAnchor: [1, -34],
})

L.Marker.prototype.options.icon = DefaultIcon

export const CENTER = L.latLng(39.5, 98.35)
export const ZOOM = 5
export const ATTRIBUTION =
  '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
const FIT_PADDING = 100

// Map mode descriptions:
// "route" shows the route from `order.Origin` to `order.Destination` and centers around the route
// "origin" puts a pin at `order.Origin` and centers around it
// "destination" puts a pin at `order.Destination` and centers around it
// "empty" centers around `order.Origin` and doesn't add any markers to the map
export type MapMode = "route" | "origin" | "destination" | "empty"

export const OsmRouteMap: FC<{ order: ApiOrder; mode: MapMode }> = ({
  order,
  mode,
}) => {
  const [map, setMap] = useState<Map | undefined>(undefined)
  const displayMap = useMemo(() => {
    // Defaults are the values used for `route` mode
    let center = CENTER
    let markerAddress: string[] = []
    let tooltip: string = ""
    if (mode.toLowerCase() === "origin" || mode.toLowerCase() === "empty") {
      center = L.latLng(
        order.Origin.GeoCodeLatitude,
        order.Origin.GeoCodeLongitude,
      )
      markerAddress = [
        order.Origin.Name,
        fullStreetAddress(order.Origin.Address1, order.Origin.Address2),
        `${order.Origin.City}, ${order.Origin.State} ${order.Origin.Zip}`,
      ]
      tooltip = `Origin: ${[order.Origin.City, order.Origin.State]
        .filter(Boolean)
        .join(", ")}`
    } else if (mode === "destination") {
      center = L.latLng(
        order.Destination.GeoCodeLatitude,
        order.Destination.GeoCodeLongitude,
      )
      markerAddress = [
        order.Destination.Name,
        fullStreetAddress(
          order.Destination.Address1,
          order.Destination.Address2,
        ),
        `${order.Destination.City}, ${order.Destination.State} ${order.Destination.Zip}`,
      ]
      tooltip = [order.Destination.City, order.Destination.State]
        .filter(Boolean)
        .join(", ")
    }

    return (
      <MapContainer
        center={center}
        zoom={ZOOM}
        scrollWheelZoom={false}
        whenCreated={(m) => setMap(m)}
        style={{ width: "100%", height: 600 }}
      >
        <TileLayer
          attribution={ATTRIBUTION}
          url={process.env.REACT_APP_MAP_TILE_API || ""}
        />
        {["origin", "destination"].includes(mode) ||
          (true && (
            <Marker
              position={center}
              icon={L.divIcon({
                className: "CustomLeafletIconContainer",
                popupAnchor: [1, -34],
              })}
            >
              <Tooltip permanent={true} interactive={true}>
                <span style={{ textTransform: "capitalize" }}>{tooltip}</span>
              </Tooltip>
              <Popup>
                {markerAddress.map((line, idx) => (
                  <div key={idx}>
                    {line}
                    <br />
                  </div>
                ))}
              </Popup>
            </Marker>
          ))}
      </MapContainer>
    )
  }, [order, mode])

  // https://github.com/nextzen/lrm-mapzen/blob/gh-pages/js/fitRoutesWithPadding.js
  const onRoutesFound = (route: any, m: Map) => {
    /* We turned off the default behaviour of routing machine, which fits start and destination point to the map without buffer */
    /* We are setting our desired behaviour here (adding topLeft[100,100] buffer, with bottomRight [100, 100] buffer) */
    const allRoutesCoords: LatLng[] = []
    /* Collecting all the result route coordinates */
    for (let i = 0, j = route.routes.length; i < j; i++) {
      for (let k = 0, l = route.routes[i].coordinates.length; k < l; k++) {
        allRoutesCoords.push(route.routes[i].coordinates[k])
      }
    }

    const bounds = L.latLngBounds(allRoutesCoords)

    m.fitBounds(bounds, {
      paddingTopLeft: [FIT_PADDING, FIT_PADDING],
      paddingBottomRight: [FIT_PADDING, FIT_PADDING],
    })
  }

  useEffect(() => {
    const getOriginAddress = () => [
      fullStreetAddress(order.Origin.Address1, order.Origin.Address2),
      order.Origin.City,
      order.Origin.State,
      order.Origin.Zip,
    ]

    const getDestinationAddress = () => [
      fullStreetAddress(order.Destination.Address1, order.Destination.Address2),
      order.Destination.City,
      order.Destination.State,
      order.Destination.Zip,
    ]

    if (map && mode === "route") {
      const control = L.Routing.control({
        plan: L.Routing.plan(
          [
            L.latLng(
              order.Origin.GeoCodeLatitude,
              order.Origin.GeoCodeLongitude,
            ),
            L.latLng(
              order.Destination.GeoCodeLatitude,
              order.Destination.GeoCodeLongitude,
            ),
          ],
          {
            createMarker: (i: number, wp: L.Routing.Waypoint) => {
              const icon = L.divIcon({
                className: "CustomLeafletIconContainer",
                popupAnchor: [1, -34],
              })
              const marker = L.marker(wp.latLng, { icon })
              const address =
                i === 0 ? getOriginAddress() : getDestinationAddress()
              const type = i === 0 ? "Origin" : "Destination"
              const shortAddress = [address[1], address[2]]
                .filter(Boolean)
                .join(", ")

              marker.bindPopup(address.filter((a) => a).join(", "))

              const tooltip = marker.bindTooltip(`${type}: ${shortAddress}`, {
                permanent: true,
                interactive: true,
              })
              tooltip.on("click", () => marker.openPopup())
              return marker
            },
          },
        ),
        router: new L.Routing.OSRMv1({
          serviceUrl: process.env.REACT_APP_OSRM_API,
        }),
        lineOptions: {
          styles: [
            {
              color: "#5a98dd",
              opacity: 0.7,
              weight: 5,
            },
          ],
          extendToWaypoints: false,
          missingRouteTolerance: 10,
        },
        routeWhileDragging: false,
        fitSelectedRoutes: false,
      })
      control.on("routesfound", (route) => onRoutesFound(route, map))
      map.attributionControl.setPrefix("")
      if (typeof control.onAdd !== "undefined") control.onAdd(map)
    }
  }, [order, mode, map])

  return <>{displayMap}</>
}
