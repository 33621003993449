import styled from "@emotion/styled"
import * as css from "csstype"
import { carBlack, carWhite } from "../utils/icons"
import { OrderStatus } from "../utils/types"
import { OrderStatusProgressBar } from "./OrderStatusProgressBar"

export { OrderStatusProgressBar }

export const OrderCardId = styled.div<{
  fontSize?: "xxs" | "xs" | "sm" | "med" | "lg" | "xl" | "xxl" | "xxxl"
}>((props) => ({
  fontSize: props.fontSize
    ? props.theme.text.sizes[props.fontSize]
    : props.theme.text.sizes.xxs,
}))

const BaseOrderStatusDisplay = styled.div((props) => ({
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: props.theme.text.weights.heavy,
  fontSize: props.theme.text.sizes.xxs,
  lineHeight: props.theme.text.lineHeights.xs,
  minWidth: 70,
  padding: `${props.theme.space.xxs}px 7px`,
  whiteSpace: "nowrap",
  margin: 0,
}))

export const OrderStatusDisplay = styled(BaseOrderStatusDisplay)<{
  status: OrderStatus
}>((props) => {
  const colorMap: Partial<
    Record<OrderStatus, css.Properties["backgroundColor"]>
  > = {
    InRoute: props.theme.color.green,
    Active: props.theme.color.blue,
    PendingPickup: props.theme.color.blue,
    Delivered: props.theme.color.blue,
    OnHold: props.theme.color.blue,
  }

  return {
    backgroundColor: colorMap[props.status] ?? props.theme.color.darkGray,
  }
})

export function CarIcon({
  alt = "cars",
  colorTheme = "light",
}: {
  alt?: string
  colorTheme?: "light" | "dark"
}) {
  return <img src={colorTheme === "light" ? carWhite : carBlack} alt={alt} />
}

export const UnitsDisplay = styled.div((props) => ({
  fontSize: props.theme.text.sizes.xl,
  fontWeight: props.theme.text.weights.reg,
  marginRight: props.theme.space.xs,
  paddingBottom: props.theme.space.xxs,
}))

export const HorizontalDivider = styled.div<{ colorTheme?: "light" | "dark" }>(
  (props) => ({
    margin: 0,
    marginTop: props.theme.space.sm,
    marginBottom: props.theme.space.sm,
    borderTop: `1px solid ${
      props.colorTheme === "light"
        ? props.theme.color.white
        : props.theme.color.lightGray
    }`,
  }),
)

export const VerticalDivider = styled.div<{ colorTheme?: "light" | "dark" }>(
  (props) => ({
    width: "100%",
    height: "100%",
    margin: 0,
    backgroundColor:
      props.colorTheme === "light"
        ? props.theme.color.white
        : props.theme.color.black,
  }),
)

export const MileageDisplay = styled.div((props) => ({
  fontSize: props.theme.text.sizes.lg,
  fontWeight: props.theme.text.weights.bold,
  color: props.theme.color.black,
  lineHeight: props.theme.text.lineHeights.xs,
  whiteSpace: "nowrap",
}))
