import { ThemeProvider } from "@emotion/react"
import createActivityDetector from "activity-detector"
import "focus-visible/dist/focus-visible.js"
import { useEffect, useState } from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom"
import { Footer } from "./components/Footer"
import { NavBar } from "./components/NavBar/NavBar"
import { ScreenWrapper } from "./components/ScreenWrapper"
import { theme } from "./emotion/theme"
import {
  accountPath,
  configPath,
  contactPath,
  currentOrdersListPath,
  faqPath,
  feedbackPath,
  forgotPasswordPath,
  historyOrdersListPath,
  inactiveOrdersListPath,
  inboundOrdersListPath,
  landingPath,
  listSavedViewsPath,
  myOrdersListPath,
  newOrderPath,
  notificationsPath,
  outboundOrdersListPath,
  resetExpiredPasswordPath,
  resetPasswordPath,
  searchOrdersPath,
  setPasswordPath,
  supportPath,
  viewOrderImagesPath,
  viewOrderPath,
} from "./paths"
import { AccountScreen } from "./screens/AccountScreen/AccountScreen"
import { ConfigScreen } from "./screens/ConfigScreen/ConfigScreen"
import { ContactScreen } from "./screens/ContactScreen/ContactScreen"
import { FAQScreen } from "./screens/FAQScreen/FAQScreen"
import { FeedbackScreen } from "./screens/FeedbackScreen/FeedbackScreen"
import { ForgotPasswordScreen } from "./screens/ForgotPasswordScreen/ForgotPasswordScreen"
import { LandingScreen } from "./screens/LandingScreen/LandingScreen"
import { NewOrderScreen } from "./screens/NewOrderScreen/NewOrderScreen"
import { NotificationsScreen } from "./screens/NotificationsScreen/NotificationsScreen"
import { OrderImagesScreen } from "./screens/OrderImagesScreen/OrderImagesScreen"
import { OrderScreen } from "./screens/OrderScreen/OrderScreen"
import { OrdersListScreen } from "./screens/OrdersListScreen/OrdersListScreen"
import { ResetExpiredPasswordScreen } from "./screens/ResetExpiredPasswordScreen/ResetExpiredPasswordScreen"
import { ResetPasswordScreen } from "./screens/ResetPasswordScreen/ResetPasswordScreen"
import { SavedViewsScreen } from "./screens/SavedViewsScreen/SavedViewsScreen"
import { SearchOrdersScreen } from "./screens/SearchOrdersScreen/SearchOrdersScreen"
import { SetPasswordScreen } from "./screens/SetPasswordScreen/SetPasswordScreen"
import { SupportScreen } from "./screens/SupportScreen/SupportScreen"
import { FlashMessageContextProvider } from "./utils/contexts/FlashMessageContext"
import {
  LoginContextProvider,
  useLoginContext,
} from "./utils/contexts/LoginContext"

// change some of react-query's unusual defaults:
// https://react-query.tanstack.com/guides/important-defaults
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
})

const Content = () => {
  const { logout } = useLoginContext()

  useEffect(() => {
    const activityDetector = createActivityDetector({
      timeToIdle: 120 * 60000, // 2 hours
      inactivityEvents: [],
    })
    activityDetector.on("idle", () => logout())
  }, [logout])

  return (
    <>
      <NavBar />
      <Routes>
        <Route path={landingPath()}>
          <LandingScreen />
        </Route>
        <Route path={configPath()}>
          <ConfigScreen />
        </Route>
        <Route path={forgotPasswordPath()}>
          <ForgotPasswordScreen />
        </Route>
        <Route path={resetPasswordPath()}>
          <ResetPasswordScreen />
        </Route>
        <Route path={setPasswordPath()}>
          <SetPasswordScreen />
        </Route>
        <Route path={resetExpiredPasswordPath()}>
          <ResetExpiredPasswordScreen />
        </Route>
        <PrivateRoute>
          <Route path={contactPath()}>
            <ContactScreen />
          </Route>
          <Route path={accountPath()}>
            <AccountScreen />
          </Route>
          <Route path={feedbackPath()}>
            <FeedbackScreen />
          </Route>
          <Route path={faqPath()}>
            <FAQScreen />
          </Route>
          <Route path={notificationsPath()}>
            <NotificationsScreen />
          </Route>
          <Route path={supportPath()}>
            <SupportScreen />
          </Route>

          <Route path={viewOrderImagesPath(":id")}>
            <OrderImagesScreen />
          </Route>
          <Route path={viewOrderPath(":id")}>
            <OrderScreen />
          </Route>
          <Route path={feedbackPath()}>
            <FeedbackScreen />
          </Route>
          <Route path={searchOrdersPath()}>
            <SearchOrdersScreen />
          </Route>

          <Routes>
            <Route path={currentOrdersListPath()}>
              <OrdersListScreen orderGroup={"current"} />
            </Route>
            <Route path={historyOrdersListPath()}>
              <OrdersListScreen orderGroup={"history"} />
            </Route>
            <Route path={inactiveOrdersListPath()}>
              <OrdersListScreen orderGroup={"inactive"} />
            </Route>
            <Route path={myOrdersListPath()}>
              <OrdersListScreen orderGroup={"myOrders"} />
            </Route>
            <Route path={inboundOrdersListPath()}>
              <OrdersListScreen orderGroup={"inbound"} />
            </Route>
            <Route path={outboundOrdersListPath()}>
              <OrdersListScreen orderGroup={"outbound"} />
            </Route>
            <Route path={viewOrderPath(":id")}>
              <OrderScreen />
            </Route>
            <Route path={listSavedViewsPath()}>
              <SavedViewsScreen />
            </Route>
            <Route path={newOrderPath()}>
              <NewOrderScreen />
            </Route>
          </Routes>
        </PrivateRoute>
      </Routes>
      <Footer />
    </>
  )
}

export function App() {
  const [currentTheme] = useState(theme)

  return (
    <ThemeProvider theme={currentTheme}>
      <QueryClientProvider client={queryClient}>
        <ScreenWrapper>
          <LoginContextProvider>
            <Router>
              <FlashMessageContextProvider>
                <Content />
              </FlashMessageContextProvider>
            </Router>
          </LoginContextProvider>
        </ScreenWrapper>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export function PrivateRoute({
  children,
  ...rest
}: {
  children: React.ReactNode
}) {
  const { isLoggedIn } = useLoginContext()
  const location = useLocation()

  return !isLoggedIn ? (
    <Navigate to={{ pathname: landingPath() }} state={{ from: location }} />
  ) : (
    <Route {...rest}>{children}</Route>
  )
}
