import styled from "@emotion/styled"
import "@reach/tooltip/styles.css"
import { forwardRef } from "react"
import { Tooltip as RTooltip } from "@reach/tooltip"

export const Tooltip = styled(RTooltip)((props) => ({
  background: "hsla(0, 0%, 0%, 0.85)",
  color: props.theme.color.white,
  border: "none",
  borderRadius: "4px",
}))

export const TooltipPrompt = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <TooltipPromptContainer ref={ref} {...props}>
      ?
    </TooltipPromptContainer>
  )
})

const TooltipPromptContainer = styled.div((props) => ({
  display: "inline-flex",
  borderRadius: 8,
  color: props.theme.color.darkGray,
  backgroundColor: "hsla(0, 0%, 0%, 0.1)",
  width: 16,
  height: 16,
  alignContent: "center",
  justifyContent: "center",
  fontSize: props.theme.text.sizes.xs,
}))
