import styled from "@emotion/styled"
import { OrderStatus } from "../utils/types"

const OrderStatusPill = styled.div({
  width: 49,
  height: 6,
  borderRadius: 20,
  display: "inline-block",
})

const EmptyOrderStatusPill = styled(OrderStatusPill)((props) => ({
  backgroundColor: props.theme.color.lightGray,
}))

const GreenOrderStatusPill = styled(OrderStatusPill)((props) => ({
  backgroundColor: props.theme.color.green,
}))

const GrayOrderStatusPill = styled(OrderStatusPill)((props) => ({
  backgroundColor: props.theme.color.darkGray,
}))

function StatusComponents({ status }: { status: OrderStatus }) {
  switch (status) {
    case "Active":
      return (
        <>
          <GreenOrderStatusPill />
          <EmptyOrderStatusPill />
          <EmptyOrderStatusPill />
          <EmptyOrderStatusPill />
        </>
      )
    case "PendingPickup":
      return (
        <>
          <GreenOrderStatusPill />
          <GreenOrderStatusPill />
          <EmptyOrderStatusPill />
          <EmptyOrderStatusPill />
        </>
      )
    case "InRoute":
      return (
        <>
          <GreenOrderStatusPill />
          <GreenOrderStatusPill />
          <GreenOrderStatusPill />
          <EmptyOrderStatusPill />
        </>
      )
    case "Delivered":
      return (
        <>
          <GreenOrderStatusPill />
          <GreenOrderStatusPill />
          <GreenOrderStatusPill />
          <GreenOrderStatusPill />
        </>
      )
    case "Inactive":
      return <div />
    case "OnHold":
      return (
        <>
          <EmptyOrderStatusPill />
          <EmptyOrderStatusPill />
          <EmptyOrderStatusPill />
          <EmptyOrderStatusPill />
        </>
      )
    default:
      return (
        <>
          <GrayOrderStatusPill />
          <EmptyOrderStatusPill />
          <EmptyOrderStatusPill />
          <EmptyOrderStatusPill />
        </>
      )
  }
}

export function OrderStatusProgressBar({ status }: { status: OrderStatus }) {
  return (
    <OrderStatusPillsWrapper>
      <StatusComponents status={status} />
    </OrderStatusPillsWrapper>
  )
}

const OrderStatusPillsWrapper = styled.div((props) => ({
  display: "grid",
  gap: props.theme.space.xxs,
  gridAutoFlow: "column",
}))
