import styled from "@emotion/styled"
import { useFormik } from "formik"
import { useState } from "react"
import { useMutation } from "react-query"
import { NavLink } from "react-router-dom"
import * as Yup from "yup"
import { BackArrow } from "../../components/BackArrow"
import { Box } from "../../components/Box"
import {
  CancelButton,
  PrimaryButton,
} from "../../components/Buttons/PrimaryButton"
import { Container } from "../../components/Container"
import { ErrorIndicator } from "../../components/ErrorIndicator"
import { FormSuccessIndicator } from "../../components/FormSuccessIndicator"
import { InitialsAvatar } from "../../components/InitialsAvatar"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { Text } from "../../components/Text"
import * as ApiV1 from "../../generated/ApiV1"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import { feedbackPath } from "../../paths"
import {
  useCurrentUserConsignors,
  useCurrentUserQuery,
} from "../../utils/hooks/useCurrentUserQuery"
import { useGoBack } from "../../utils/hooks/useGoBack"
import { useMutationRequest } from "../../utils/oviss"

export const PASSWORD_RULES =
  "Password must be at least 8 characters long and contain at least one upper case letter, one lower case letter, one number and one symbol (such as $ @ $ ! % * ? &)"

export const passwordValidation = (value?: string) => {
  if (!value) return true
  return (
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(
      value,
    ) &&
    /[a-z]/.test(value) && // has a lowercase letter
    /[A-Z]/.test(value) && // has an uppercase letter
    /\d/.test(value) // has a digit
  )
}

// From https://stackoverflow.com/questions/52483260/validate-phone-number-with-yup
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

// Parses a full name into first and last name. Assumes that the first name ends
// at the first space in the string, and that the last name is all text following
// the first space
function parseName(name: string): [string, string] {
  const firstSpaceIdx = name.indexOf(" ")
  let firstName = name.slice(0, firstSpaceIdx)
  let lastName = name.slice(firstSpaceIdx + 1)

  // If there are no spaces, then everything belongs to firstName
  if (firstSpaceIdx === -1) {
    firstName = name
    lastName = ""
  }

  return [firstName, lastName]
}

export function AccountScreen() {
  const goBack = useGoBack()
  const { data: user } = useCurrentUserQuery()
  const request = useMutationRequest()

  const consignorResult = useCurrentUserConsignors()

  const [formSuccessMessage, setFormSuccessMessage] =
    useState<string | null>(null)

  useDocumentTitle("My Account")

  const { isLoading: userDataIsLoading, mutateAsync: mutateUserData } =
    useMutation(
      ({
        name,
        email,
        phone,
      }: {
        name: string
        email: string
        phone: string
      }) => {
        const [firstName, lastName] = parseName(name)

        const params: ApiV1.definitions["VTVSolutions.Vlms.Dtos.Clients.ClientUserApiDto"] =
          {
            Id: user?.UserId,
            FirstName: firstName,
            LastName: lastName,
            Email: email,
            PrimaryPhone: phone,
          }

        return request({
          path: "/api/Users/User",
          method: "PUT",
          body: params,
        })
      },
    )
  const { isLoading: userPasswordIsLoading, mutateAsync: mutateUserPassword } =
    useMutation(
      ({
        currentPassword,
        newPassword,
        newPasswordConfirm,
      }: {
        currentPassword: string
        newPassword: string
        newPasswordConfirm: string
      }) => {
        return request({
          path: "/api/CurrentUser/ChangePassword",
          method: "POST",
          body: {
            CurrentPassword: currentPassword,
            NewPassword: newPassword,
            newPasswordConfirmation: newPasswordConfirm,
          },
        })
      },
    )

  const userDataFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user ? `${user.FirstName} ${user.LastName}` : "",
      email: user ? user.Email : "",
      phone: user
        ? user.PrimaryPhone
          ? user.PrimaryPhone.PhoneNumber
          : ""
        : "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Required"),
      email: Yup.string().email("Invalid email").required("Required"),
      phone: Yup.string()
        .matches(phoneRegExp, "Phone number is invalid")
        .required("Required"),
      newPassword: Yup.string().test(
        "password-format",
        PASSWORD_RULES,
        passwordValidation,
      ),
    }),
    onSubmit: async (values, { setStatus }) => {
      try {
        await mutateUserData(values)
        setStatus(null)
        return true
      } catch (e: any) {
        setStatus(e.message)
        return false
      }
    },
  })

  const userPasswordFormik = useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      newPasswordConfirm: "",
    },
    validationSchema: Yup.object().shape({
      currentPassword: Yup.string().required("Required"),
      newPassword: Yup.string()
        .required("Required")
        .test("password-format", PASSWORD_RULES, passwordValidation),
      newPasswordConfirm: Yup.string()
        .oneOf([Yup.ref("newPassword")], "New password fields do not match.")
        .required("Required"),
    }),
    onSubmit: async (values, { setStatus }) => {
      try {
        await mutateUserPassword(values)
        setStatus(null)
        return true
      } catch (e: any) {
        setStatus(e.message)
        return false
      }
    },
  })

  return (
    <Box m="sm">
      <Text color="darkGray">
        <Box display="flex" justifyContent="space-between" mb="lg">
          <div style={{ flex: 1 }}>
            <BackArrow theme="dark" />
          </div>
          <Text fontSize="xxl">My Account</Text>
          <div style={{ flex: 1 }} />
        </Box>
        <Container>
          <Box display="flex" justifyContent="center" mb="lg">
            <div style={{ textAlign: "center" }}>
              <InitialsAvatar
                firstName={user && user.FirstName}
                lastName={user && user.LastName}
              />

              <Text fontSize="xxl">
                {user && user.FirstName} {user && user.LastName}
              </Text>

              <Box mt="xs">
                <Text fontSize="lg">
                  {consignorResult.allConsignors ? (
                    consignorResult.allConsignors.map((client) => (
                      <div key={client.ClientId}>
                        {client.Name} | {client.ClientId}
                      </div>
                    ))
                  ) : consignorResult.loading ? (
                    "…"
                  ) : consignorResult.error ? (
                    <ErrorIndicator error={consignorResult.error} />
                  ) : null}
                </Text>
              </Box>

              <Box></Box>
            </div>
          </Box>

          {formSuccessMessage && (
            <FormSuccessIndicator message={formSuccessMessage} />
          )}

          <form onSubmit={userDataFormik.handleSubmit}>
            <Box
              mh="xxs"
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              {userDataFormik.status && (
                <Box mv="sm" display="flex" justifyContent="flex-start">
                  <ErrorIndicatorWithoutLabel>
                    {userDataFormik.status}
                  </ErrorIndicatorWithoutLabel>
                </Box>
              )}

              <StyledLabel>
                Name
                <StyledErrorIndicator>
                  {userDataFormik.touched.name && userDataFormik.errors.name}
                </StyledErrorIndicator>
              </StyledLabel>
              <StyledInput
                id="name"
                name="name"
                type="text"
                onChange={userDataFormik.handleChange}
                onBlur={userDataFormik.handleBlur}
                value={userDataFormik.values.name}
              />
            </Box>
            <StyledHr />
            <Box
              mh="xxs"
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              <StyledLabel>
                Email
                <StyledErrorIndicator>
                  {userDataFormik.touched.email && userDataFormik.errors.email}
                </StyledErrorIndicator>
              </StyledLabel>
              <StyledInput
                id="email"
                name="email"
                type="text"
                onChange={userDataFormik.handleChange}
                onBlur={userDataFormik.handleBlur}
                value={userDataFormik.values.email}
              />
            </Box>
            <StyledHr />
            <Box
              mh="xxs"
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              <StyledLabel>
                Phone
                <StyledErrorIndicator>
                  {userDataFormik.touched.phone && userDataFormik.errors.phone}
                </StyledErrorIndicator>
              </StyledLabel>
              <StyledInput
                id="phone"
                name="phone"
                type="text"
                onChange={userDataFormik.handleChange}
                onBlur={userDataFormik.handleBlur}
                value={userDataFormik.values.phone}
              />
            </Box>
            <StyledHr />
            <Box mt="lg" display="flex" justifyContent="flex-start">
              <Text fontWeight="bold">SECURITY</Text>
            </Box>
            <Box mt="xs" mb="xs" display="flex" justifyContent="flex-start">
              <Text fontSize="xxl">Change Password</Text>
            </Box>
            <Text fontSize="sm">{PASSWORD_RULES}</Text>
            {userPasswordFormik.status && (
              <Box mt="sm" display="flex" justifyContent="flex-start">
                <ErrorIndicatorWithoutLabel>
                  {userPasswordFormik.status}
                </ErrorIndicatorWithoutLabel>
              </Box>
            )}
            <Box
              mt="lg"
              mh="xxs"
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              <ErrorIndicatorWithoutLabel>
                {userPasswordFormik.dirty &&
                  userPasswordFormik.errors.currentPassword}
              </ErrorIndicatorWithoutLabel>
              <StyledInput
                id="currentPassword"
                name="currentPassword"
                type="password"
                placeholder="Current password"
                onChange={userPasswordFormik.handleChange}
                onBlur={userPasswordFormik.handleBlur}
                value={userPasswordFormik.values.currentPassword}
              />
            </Box>
            <StyledHr />
            <Box
              mt="lg"
              mh="xxs"
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              <ErrorIndicatorWithoutLabel>
                {userPasswordFormik.dirty &&
                  userPasswordFormik.errors.newPassword}
              </ErrorIndicatorWithoutLabel>
              <StyledInput
                id="newPassword"
                name="newPassword"
                type="password"
                placeholder="New password"
                onChange={userPasswordFormik.handleChange}
                onBlur={userPasswordFormik.handleBlur}
                value={userPasswordFormik.values.newPassword}
              />
            </Box>
            <StyledHr />
            <Box
              mt="lg"
              mh="xxs"
              display="flex"
              justifyContent="center"
              flexDirection="column"
            >
              <ErrorIndicatorWithoutLabel>
                {userPasswordFormik.dirty &&
                  userPasswordFormik.errors.newPasswordConfirm}
              </ErrorIndicatorWithoutLabel>
              <StyledInput
                id="newPasswordConfirm"
                name="newPasswordConfirm"
                type="password"
                placeholder="Confirm new password"
                onChange={userPasswordFormik.handleChange}
                onBlur={userPasswordFormik.handleBlur}
                value={userPasswordFormik.values.newPasswordConfirm}
              />
            </Box>
            <StyledHr />
          </form>

          {userPasswordIsLoading || userDataIsLoading ? (
            <LoadingIndicator colorScheme="black" />
          ) : (
            <Box mt="lg" display="flex" justifyContent="space-between">
              <CancelButton onClick={goBack}>Cancel</CancelButton>
              <ButtonSpacer />
              <PrimaryButton
                disabled={!(userDataFormik.dirty || userPasswordFormik.dirty)}
                onClick={async () => {
                  let successMessages: [boolean, boolean] = [false, false]
                  // Only submit forms if they have changes
                  if (userDataFormik.dirty) {
                    try {
                      successMessages[0] = await userDataFormik.submitForm()
                    } catch (e) {
                      userDataFormik.setStatus(e.message)
                      return
                    }
                  }

                  if (userPasswordFormik.dirty) {
                    try {
                      successMessages[1] = await userPasswordFormik.submitForm()
                    } catch (e) {
                      userPasswordFormik.setStatus(e.message)
                      return
                    }
                  }

                  setFormSuccessMessage(
                    calculateFormSuccessMessage(successMessages),
                  )
                }}
              >
                Save
              </PrimaryButton>
            </Box>
          )}

          <Box
            mt="xxl"
            display="flex"
            alignItems="center"
            flexDirection="column"
          >
            Let us know how we can improve our tracker.
            <div>
              <NavLink to={feedbackPath()}>Send us feedback.</NavLink>
            </div>
          </Box>
        </Container>
      </Text>
    </Box>
  )
}

const StyledHr = styled.hr((props) => ({
  backgroundColor: props.theme.color.darkGray,
}))

const StyledLabel = styled.label((props) => ({
  color: props.theme.color.medGray,
  fontSize: props.theme.text.sizes.sm,
  marginBottom: props.theme.space.xxs,
}))

const StyledInput = styled.input((props) => ({
  border: "none",
  margin: 0,
  padding: 0,
  color: props.theme.color.darkGray,
  fontSize: props.theme.text.sizes.xl,
  fontFamily: "'Barlow', sans-serif",
}))

const StyledErrorIndicator = styled.span((props) => ({
  marginLeft: props.theme.space.xs,
  color: props.theme.color.warning,
  whiteSpace: "pre-wrap",
}))

const ErrorIndicatorWithoutLabel = styled(StyledErrorIndicator)({
  marginLeft: 0,
})

const ButtonSpacer = styled.div((props) => ({
  marginLeft: props.theme.space.xxs,
  marginRight: props.theme.space.xxs,
}))

function calculateFormSuccessMessage([details, password]: [boolean, boolean]) {
  if (details && password) {
    return "User details and password updated successfully."
  } else if (details) {
    return "User details updated successfully."
  } else if (password) {
    return "User password updated successfully."
  } else {
    return null
  }
}
