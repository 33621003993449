import styled from "@emotion/styled"

export const PaddedContentsWrapper: React.FC = (props) => (
  <Container {...props} />
)

const Container = styled.div((props) => ({
  background: props.theme.color.background,
  paddingLeft: props.theme.space.sm,
  paddingRight: props.theme.space.sm,
}))
