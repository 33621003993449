import styled from "@emotion/styled"

export const SecondaryButton = styled.button(
  (props) => ({
    color: props.theme.color.black,
    border: "none",
    background: "none",
    textAlign: "center",
    margin: "0 auto",
    fontWeight: 500,
    fontSize: 12,
    lineHeight: "16px",
    textDecoration: "underline",
    cursor: "pointer",
    fontFamily: "'Barlow', sans-serif",
  }),
  { type: "button" },
)

// Give this button a default type of "button"
SecondaryButton.defaultProps = {
  type: "button",
}
