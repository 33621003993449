import styled from "@emotion/styled"
import { Color, Theme } from "../emotion/theme"

export const Text = styled.div<{
  color?: Color
  fontSize?: keyof Theme["text"]["sizes"]
  lineHeight?: keyof Theme["text"]["lineHeights"]
  fontWeight?: keyof Theme["text"]["weights"]
  textDecoration?: string
}>((props) => ({
  color: props.color ? props.theme.color[props.color] : undefined,
  fontSize: props.fontSize ? props.theme.text.sizes[props.fontSize] : undefined,
  lineHeight: props.lineHeight
    ? props.theme.text.lineHeights[props.lineHeight]
    : undefined,
  fontWeight: props.fontWeight
    ? props.theme.text.weights[props.fontWeight]
    : undefined,
  textDecoration: props.textDecoration,
}))
