import styled from "@emotion/styled"
import { useQuery } from "react-query"
import { BackArrow } from "../../components/BackArrow"
import { Box } from "../../components/Box"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { Text } from "../../components/Text"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import textureBGGray from "../../utils/backgrounds/ur_gray.jpg"
import { emailBlue, phoneBlue } from "../../utils/icons"
import { useRequest } from "../../utils/oviss"

export const SupportScreen = () => {
  const request = useRequest()
  useDocumentTitle("Connect With Us")

  const { data, isLoading } = useQuery<{
    CustomerCarePhoneNumber: string
    FeedbackEmail: string
  }>(
    [
      ["supportContactInfo"],
      {
        path: `/api/Lookups/CustomerPortalSupportContactInfo`,
        method: "GET",
      },
    ],
    request,
  )

  if (isLoading) {
    return <LoadingIndicator colorScheme="black" />
  }

  const FeedbackEmail = data?.FeedbackEmail || "info@unitedroad.com"
  const CustomerCarePhoneNumber =
    data?.CustomerCarePhoneNumber || "800.432.5183"

  return (
    <Box p="sm" background={`url(${textureBGGray}) black`} flex={1}>
      <Text color="white">
        <BackArrow theme="light" />
        <Box ph="xxl">
          <Box
            textAlign="center"
            display="grid"
            alignItems="center"
            justifyItems="center"
            flexDirection="column"
            rowGap="xl"
          >
            <Text fontSize="xxxl">Connect With Us</Text>

            <Text fontSize="xxl">
              Our team is here for all your transporation needs.
            </Text>

            <Box
              rowGap="xs"
              display="grid"
              justifyItems="center"
              alignItems="center"
            >
              <ContactLinkBlock href={`mailto:${FeedbackEmail}`}>
                <img width="40" height="40" src={emailBlue} alt="email" />
                <Text fontSize="xl">EMAIL</Text>
              </ContactLinkBlock>
              <Text fontSize="xl">
                Send us an email at{" "}
                <ContactLink href={`mailto:${FeedbackEmail}`}>
                  {FeedbackEmail}
                </ContactLink>{" "}
                and we'll get back to you within 24 hours.
              </Text>
            </Box>

            {CustomerCarePhoneNumber && (
              <Box
                rowGap="xs"
                display="grid"
                justifyItems="center"
                alignItems="center"
              >
                <ContactLinkBlock href={`tel:${CustomerCarePhoneNumber}`}>
                  <img width="40" height="40" src={phoneBlue} alt="call" />
                  <Text fontSize="xl">CALL</Text>
                </ContactLinkBlock>
                <Text fontSize="xl">
                  Call us at{" "}
                  <ContactLink href={`tel:${CustomerCarePhoneNumber}`}>
                    {CustomerCarePhoneNumber}
                  </ContactLink>{" "}
                  to speak with your dedicated representative and get all the
                  details you need on your orders.
                </Text>
              </Box>
            )}
            <Box></Box>
          </Box>
        </Box>
      </Text>
    </Box>
  )
}

const ContactLink = styled.a((props) => ({
  "color": props.theme.color.white,
  "&:visited": {
    color: props.theme.color.white,
  },
}))

const ContactLinkBlock = styled.a((props) => ({
  "color": props.theme.color.white,
  "&:visited": {
    color: props.theme.color.white,
  },
  "text-decoration": "none",
}))
