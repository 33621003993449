import styled from "@emotion/styled"
import { ErrorMessage, Field, Form, Formik } from "formik"
import { Link } from "react-router-dom"
import { Box } from "../../components/Box"
import { PaddedContentsWrapper } from "../../components/PaddedContentsWrapper"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import { landingPath } from "../../paths"

export const ContactScreen = () => {
  useDocumentTitle("Contact Us")

  return (
    <PaddedContentsWrapper>
      <h1>Contact us!</h1>
      <Formik
        initialValues={{ email: "", body: "" }}
        validate={(values) => {
          const errors: Record<string, string> = {}
          if (!values.email) {
            errors.email = "Required"
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = "Invalid email address"
          }
          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2))
            setSubmitting(false)
          }, 400)
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <Box mb="sm">
              <Label htmlFor="email">Email</Label>
              <Field type="email" name="email" id="email" />
              <ErrorMessage name="email" component={DangerText} />
            </Box>
            <Box mb="sm">
              <Label htmlFor="body">Message</Label>
              <Field as="textarea" name="body" id="body" />
              <ErrorMessage name="body" component={DangerText} />
            </Box>
            <button type="submit" disabled={isSubmitting}>
              Submit
            </button>
          </Form>
        )}
      </Formik>

      <Box mt="lg">
        <Link to={landingPath()}>Home</Link>
      </Box>
    </PaddedContentsWrapper>
  )
}

const Label = styled.label({ display: "block" })
const DangerText = styled.div((props) => ({
  color: props.theme.color.warning,
}))
