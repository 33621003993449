export function orderDetailsQueryKey(id: number | string) {
  return [
    ["order", { id: id.toString() }],
    {
      path: `/api/v2/Orders/Detail`,
      query: { orderId: id.toString() },
      method: "GET",
    },
  ]
}
