import styled from "@emotion/styled"
import { Box } from "./Box"
import { SecondaryButtonInline } from "./Buttons/SecondaryButtonInline"
import { closeX } from "../utils/icons"
import { useFlashMessageContext } from "../utils/contexts/FlashMessageContext"
import { useState } from "react"

type JustifyContent = "flex-start" | "center" | "flex-end"

export function ErrorIndicator({
  error,
  justifyContent = "center",
  localMessage = false,
}: {
  error: unknown
  justifyContent?: JustifyContent
  localMessage?: boolean
}) {
  const [open, setOpen] = useState(true)
  const { clearMessage } = useFlashMessageContext()
  // The message is formatted depending on the type of `error`
  // 1. error - error.message
  // 2. string - error
  // 3. anything else - json stringified error
  const message =
    error instanceof Error
      ? error.message
      : typeof error === "string"
      ? error
      : `${JSON.stringify(error)}`
  return !localMessage || open ? (
    <ErrorIndicatorWrapper justifyContent={justifyContent}>
      <Box mr="lg">
        <StyledSecondaryButton
          onClick={localMessage ? () => setOpen(false) : clearMessage}
        >
          <img src={closeX} alt="close" width="12" height="12" />
        </StyledSecondaryButton>
      </Box>
      {message}
    </ErrorIndicatorWrapper>
  ) : null
}

const ErrorIndicatorWrapper = styled.div<{
  justifyContent: JustifyContent
}>((props) => ({
  display: "flex",
  color: props.theme.color.warning,
  fontWeight: "bold",
  justifyContent: props.justifyContent,
  padding: props.theme.space.md,
  border: `1px solid ${props.theme.color.warning}`,
  backgroundColor: props.theme.color.lightWarning,
}))

const StyledSecondaryButton = styled(SecondaryButtonInline)({
  padding: 0,
})
