import styled from "@emotion/styled"
import { Field } from "formik"
import { Box } from "./Box"
import { SecondaryButton } from "./Buttons/SecondaryButton"
import { Text } from "./Text"

export const RadioClearButton: React.FC<{
  type?: "button" | "reset" | "submit"
  displayName: string
  onClick: any
}> = ({ displayName, onClick, type = "button" }) => {
  return (
    <Box mt="sm" display="flex" alignItems="center" justifyContent="center">
      <SecondaryButton type={type} onClick={onClick}>
        {displayName}
      </SecondaryButton>
    </Box>
  )
}

export const RadioLine: React.FC<{
  name: string
  displayName: string
  value: string
  onClick?: any
  checked?: boolean
  disabled?: boolean
}> = ({ name, displayName, value, onClick, checked, disabled }) => {
  return (
    <label onClick={onClick ? onClick : null}>
      <Box display="flex" alignItems="center">
        <RadioStyledField
          type="radio"
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
        />
        <Box ml="xs">
          <Text fontSize="lg">{displayName}</Text>
        </Box>
      </Box>
    </label>
  )
}

const RadioStyledField = styled(Field)((props) => ({
  "width": 24,
  "height": 24,
  "marginLeft": 0,
  "appearance": "none",

  "borderRadius": "100%",
  "border": "1px solid #555",

  "&:checked": {
    background: `radial-gradient(circle, ${props.theme.color.blue} 8px, ${props.theme.color.blue} 8px, ${props.theme.color.white} 1%);`,
    borderColor: props.theme.color.blue,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },

  "&:checked &:hover": {
    backgroundColor: props.theme.color.blue,
    border: props.theme.color.blue,
  },

  "&[disabled]": {
    "opacity": 0.4,
    "& + div": {
      opacity: 0.4,
    },
  },
}))
