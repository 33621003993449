import styled from "@emotion/styled"
import { Field, Form, Formik, useFormikContext } from "formik"
import { useEffect } from "react"
import TextareaAutosize from "react-autosize-textarea"
import { useDebouncedCallback } from "use-debounce"
import { CheckboxLine } from "../../components/CheckboxLine"
import { DateRangeSelector } from "../../components/DateRangeSelector"
import { Theme } from "../../emotion/theme"
import { useIsMobile } from "../../utils/hooks/commonMediaQueryHooks"
import { useCurrentUserConsignors } from "../../utils/hooks/useCurrentUserQuery"
import { closeX } from "../../utils/icons"
import {
  FiltersSpec,
  getSortDetails,
  listSortingOptions,
  OrderListGroup,
  OrderStatusOption,
  parseOrderNumbersFromFormValue,
  parseVinsFromFormValue,
  SortByOption,
  SortBySelect,
} from "../../utils/orderListFilters"
import { Box } from "../Box"
import { PrimaryButton } from "../Buttons/PrimaryButton"
import { SecondaryButton } from "../Buttons/SecondaryButton"
import { SecondaryButtonInline } from "../Buttons/SecondaryButtonInline"
import { ClientAutocompleteInput } from "../ClientAutocompleteInput"
import { HideIfDesktop } from "../HideIfDesktop"
import { DESKTOP_NAV_HEIGHT } from "../NavBar/NavBar"
import { SaveViewButton } from "../SaveViewButton"
import { ShowIfDesktop } from "../ShowIfDesktop"
import { Text } from "../Text"

export const getDefaultPickupDateRange = (orderGroup: OrderListGroup) => {
  if (orderGroup === "inactive") return "thisWeek"
  return "unselected"
}

export const getDefaultDeliveryDateRange = (orderGroup: OrderListGroup) => {
  if (orderGroup === "history") return "today"
  return "unselected"
}

type OrdersListViewCreatorProps = {
  initialValues: FiltersSpec
  selectableOrderStatuses: OrderStatusOption[]
  onClose: () => void
  onSave: (values: FiltersSpec) => void
  onClear: () => void
  orderGroup: OrderListGroup
}

const StyledContainerBox = styled(Box)(() => {
  const isMobile = useIsMobile()
  return {
    position: "fixed",
    height: `calc(100vh - ${DESKTOP_NAV_HEIGHT}px)`,
    overflow: "auto",
    width: isMobile ? "100%" : 300,
  }
})

export function OrdersListViewCreator({
  initialValues,
  selectableOrderStatuses,
  onClose,
  onSave,
  onClear,
  orderGroup,
}: OrdersListViewCreatorProps) {
  const consignorResult = useCurrentUserConsignors()

  return (
    <StyledContainerBox bg="white" ph="sm" mt="sm" pb="sm">
      <HideIfDesktop>
        <div>
          <StyledSecondaryButton onClick={onClose}>
            <img src={closeX} alt="close" />
          </StyledSecondaryButton>
        </div>
      </HideIfDesktop>
      <Formik
        enableReinitialize={true}
        initialValues={{
          sortBy: initialValues.sortBy,
          order: initialValues.order,
          showPriorityOnly: initialValues.showPriorityOnly,
          onlyOrdersCreatedByCurrentUser:
            initialValues.onlyOrdersCreatedByCurrentUser,
          orderStatuses: initialValues.orderStatuses,
          vins: initialValues.vins || "",
          orderNumbers: initialValues.orderNumbers || "",
          pickUpDateRange: initialValues.pickUpDateRange,
          pickUpDateCustomRangeStart:
            initialValues.pickUpDateCustomRangeStart || "",
          pickUpDateCustomRangeEnd:
            initialValues.pickUpDateCustomRangeEnd || "",
          deliveryDateRange: initialValues.deliveryDateRange,
          deliveryDateCustomRangeStart:
            initialValues.deliveryDateCustomRangeStart || "",
          deliveryDateCustomRangeEnd:
            initialValues.deliveryDateCustomRangeEnd || "",
          originClientId: initialValues.originClientId || "",
          originClientLabel: initialValues.originClientLabel || "",
          destinationClientId: initialValues.destinationClientId || "",
          destinationClientLabel: initialValues.destinationClientLabel || "",
          consignor: initialValues.consignor || "",
        }}
        validate={(values) => {
          const pickupComplete: boolean =
            values.pickUpDateRange !== "custom" ||
            (values.pickUpDateCustomRangeStart !== "" &&
              values.pickUpDateCustomRangeEnd !== "")
          const deliveryComplete: boolean =
            values.deliveryDateRange !== "custom" ||
            (values.deliveryDateCustomRangeStart !== "" &&
              values.deliveryDateCustomRangeEnd !== "")

          if (pickupComplete && deliveryComplete) {
            return
          } else {
            return {
              pickUpDatRange: "incomplete date range",
              deliveryDateRange: "incomplete date range",
            }
          }
        }}
        onSubmit={(values) => {
          onSave(values)
        }}
      >
        {({
          values,
          resetForm,
          setValues,
          setFieldValue,
          handleChange,
          submitForm,
        }) => (
          <Form>
            <Text fontSize="xxs">
              <Box mv="sm">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <HideIfDesktop>
                    <Text fontWeight="heavy">SORT BY</Text>
                  </HideIfDesktop>
                  <ShowIfDesktop>
                    <Box mt="sm" mb="lg">
                      <Text fontSize="xl" fontWeight="med">
                        Filters
                      </Text>
                    </Box>
                  </ShowIfDesktop>
                  <div>
                    <SecondaryButtonInline
                      onClick={() => {
                        resetForm({
                          values: {
                            // Reset to the default values for this list view
                            sortBy: "deliveryDate",
                            order: "desc",
                            showPriorityOnly: values.showPriorityOnly,
                            orderStatuses: selectableOrderStatuses.map(
                              ({ value }) => value,
                            ),
                            vins: "",
                            orderNumbers: "",
                            pickUpDateRange:
                              getDefaultPickupDateRange(orderGroup),
                            deliveryDateRange:
                              getDefaultDeliveryDateRange(orderGroup),
                            pickUpDateCustomRangeStart: "",
                            pickUpDateCustomRangeEnd: "",
                            deliveryDateCustomRangeStart: "",
                            deliveryDateCustomRangeEnd: "",
                            originClientId: "",
                            originClientLabel: "",
                            destinationClientId: "",
                            destinationClientLabel: "",
                            onlyOrdersCreatedByCurrentUser: false,
                            consignor: "",
                          },
                        })
                        onClear()
                      }}
                    >
                      CLEAR ALL
                    </SecondaryButtonInline>
                  </div>
                </Box>
                <HideIfDesktop>
                  <Box mv="xs">
                    <Field
                      as={SortBySelect}
                      name="sortBy"
                      value={values.sortBy}
                      onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        const orderForSort = getSortDetails(
                          e.target.value as SortByOption,
                        ).SortAscending
                          ? "asc"
                          : "desc"
                        setFieldValue("order", orderForSort)
                        handleChange(e)
                      }}
                    >
                      {listSortingOptions.map(({ displayName, value }) => (
                        <StyledOption key={value} value={value}>
                          {displayName}
                        </StyledOption>
                      ))}
                    </Field>
                  </Box>
                  <Box mv="xs">
                    <Field as={SortBySelect} name="order" value={values.order}>
                      <StyledOption value="asc">Ascending</StyledOption>
                      <StyledOption value="desc">Descending</StyledOption>
                    </Field>
                  </Box>
                </HideIfDesktop>
                <HideIfDesktop>
                  <SectionDivider />
                </HideIfDesktop>
                <Box mb="lg">
                  <FilterTitle>Status of Order</FilterTitle>
                  {selectableOrderStatuses.map(({ displayName, value }) => (
                    <CheckboxLine
                      name="orderStatuses"
                      key={value}
                      displayName={displayName}
                      value={value}
                    />
                  ))}
                </Box>
                {consignorResult.additionalConsignors ? (
                  <Box mb="lg">
                    <FilterTitle>Consignor</FilterTitle>

                    <Field name="consignor" as={SelectInput}>
                      <option value="">All</option>
                      {consignorResult?.allConsignors?.map((client) => (
                        <option key={client.ClientId} value={client.ClientId}>
                          {client.Name}
                        </option>
                      ))}
                    </Field>
                  </Box>
                ) : null}
                {orderGroup !== "outbound" && (
                  <Box mb="lg">
                    <FilterTitle>Origin</FilterTitle>
                    <ClientAutocompleteInput
                      clientType="Origin"
                      orderStatuses={values.orderStatuses}
                      selected={{
                        label: values.originClientLabel,
                        id: values.originClientId,
                      }}
                      onSelect={(label, id) => {
                        setValues({
                          ...values,
                          originClientLabel: label,
                          originClientId: id,
                        })
                        submitForm()
                      }}
                    />
                  </Box>
                )}
                {orderGroup !== "inbound" && (
                  <Box mv="lg">
                    <FilterTitle>Destination</FilterTitle>
                    <ClientAutocompleteInput
                      clientType="Destination"
                      orderStatuses={values.orderStatuses}
                      selected={{
                        label: values.destinationClientLabel,
                        id: values.destinationClientId,
                      }}
                      onSelect={(label, id) => {
                        setValues({
                          ...values,
                          destinationClientLabel: label,
                          destinationClientId: id,
                        })
                        submitForm()
                      }}
                    />
                  </Box>
                )}
                <Box mv="lg">
                  <FilterTitle>VIN Number</FilterTitle>
                  <Field
                    as={AutosizeTextInput}
                    name="vins"
                    placeholder="Add VIN Numbers"
                  />
                  <Hint>
                    If you are searching for multiple VINs, separate them with a
                    comma
                  </Hint>
                </Box>
                <Box mv="lg">
                  <FilterTitle>Order Numbers</FilterTitle>
                  <Field
                    as={TextInput}
                    name="orderNumbers"
                    placeholder="Add Order Number(s)"
                  />
                </Box>
                <Box mv="lg">
                  <div style={{ display: "inline-block" }}>
                    <FilterTitle>Pick Up Date</FilterTitle>
                    <Field
                      as={DateRangeSelector}
                      name="pickUpDateRange"
                      from={values.pickUpDateCustomRangeStart}
                      to={values.pickUpDateCustomRangeEnd}
                      onClear={() =>
                        setValues({
                          ...values,
                          pickUpDateRange: "unselected",
                        })
                      }
                      values={values}
                      onCustomRangeSelected={({
                        from,
                        to,
                      }: {
                        from: string
                        to: string
                      }) => {
                        setValues({
                          ...values,
                          pickUpDateCustomRangeStart: from,
                          pickUpDateCustomRangeEnd: to,
                        })
                      }}
                      orderGroup={orderGroup}
                    />
                  </div>
                </Box>
                <Box mv="lg">
                  <div style={{ display: "inline-block" }}>
                    <FilterTitle>Delivery Date</FilterTitle>
                    <Field
                      as={DateRangeSelector}
                      name="deliveryDateRange"
                      from={values.deliveryDateCustomRangeStart}
                      to={values.deliveryDateCustomRangeEnd}
                      onClear={() =>
                        setValues({
                          ...values,
                          deliveryDateRange: "unselected",
                        })
                      }
                      values={values}
                      onCustomRangeSelected={({
                        from,
                        to,
                      }: {
                        from: string
                        to: string
                      }) => {
                        setValues({
                          ...values,
                          deliveryDateCustomRangeStart: from,
                          deliveryDateCustomRangeEnd: to,
                        })
                      }}
                      orderGroup={orderGroup}
                    />
                  </div>
                </Box>
              </Box>
            </Text>
            <Box display="flex" justifyContent="center">
              <HideIfDesktop>
                <PrimaryButton type="submit">Apply</PrimaryButton>
              </HideIfDesktop>
              <ShowIfDesktop>
                <DesktopFormAutoSubmitter />
              </ShowIfDesktop>
            </Box>
            <Box mv="md">
              <SectionDivider />
              <SaveViewButton
                consignors={consignorResult.allConsignors || []}
                onSave={submitForm}
                viewParams={{
                  ...values,
                  vins: parseVinsFromFormValue(values.vins),
                  orderNumbers: parseOrderNumbersFromFormValue(
                    values.orderNumbers,
                  ),
                }}
                orderGroup={orderGroup}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </StyledContainerBox>
  )
}

function DesktopFormAutoSubmitter() {
  const { dirty, values, submitForm } = useFormikContext()
  const isMobile = useIsMobile()
  // Need to debounce the auto-submit so that typing in a text input doesn't
  // freeze the page
  const doSubmit = useDebouncedCallback(() => {
    // Only do the submit if the form is dirty. This prevents us from
    // clobbering our history with a no-op submit as soon as the form mounts.
    if (!isMobile && dirty) {
      submitForm()
    }
  }, 500)

  // Submit the form any time the form's values change (or the doSubmit function changes)
  useEffect(() => {
    doSubmit()
  }, [values, doSubmit])

  return null
}

const StyledSecondaryButton = styled(SecondaryButton)({
  padding: 0,
})

const StyledOption = styled.option({})

const SectionDivider = styled.hr((props) => ({
  color: props.theme.color.black,
  marginTop: props.theme.space.xs,
  marginBottom: props.theme.space.xs,
}))

const FilterTitle = styled.div((props) => ({
  fontWeight: "bold",
  fontSize: props.theme.text.sizes.lg,
  marginBottom: props.theme.space.xs,
}))

const inputStyles = (theme: Theme) => {
  return {
    paddingLeft: theme.space.sm,
    paddingRight: theme.space.sm,
    paddingTop: theme.space.xs,
    paddingBottom: theme.space.xs,
    fontSize: theme.text.sizes.sm,
    width: "100%",
  }
}

const TextInput = styled.input((props) => ({
  ...inputStyles(props.theme),
}))

const SelectInput = styled.select((props) => ({
  ...inputStyles(props.theme),
}))

const AutosizeTextInput = styled(TextareaAutosize)((props) => ({
  paddingLeft: props.theme.space.sm,
  paddingRight: props.theme.space.sm,
  paddingTop: props.theme.space.xs,
  paddingBottom: props.theme.space.xs,
  fontSize: props.theme.text.sizes.sm,
  width: "100%",
  fontFamily: props.theme.text.fonts.sans,
}))

const Hint = styled.div((props) => ({
  fontSize: props.theme.text.sizes.xxs,
  color: props.theme.color.darkGray,
}))
