// https://emotion.sh/docs/theming
// loosely based on https://system-ui.com/theme/
// placeholders here inspired by inspired by https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss

const color = {
  black: "#000",
  white: "#fff",
  blue: "#0066ff",
  darkGray: "#535353",
  lightGray: "#d8d8d8",
  xLightGray: "#e6e6e6",
  borderLightGray: "#dedede",
  travelLineColor: "#979797",
  medGray: "#808080",
  green: "#2dc84d",
  lightGreen: "#DEFFDE",
  warning: "red",
  lightWarning: "#FFDEDE",
  transparent: "transparent",
} as const

export const theme = {
  color: {
    ...color,
    background: color.white,
    text: color.black,
  },
  /** media queries */
  mq: {
    min: {
      sm: `@media (min-width: 576px)`,
      md: `@media (min-width: 768px)`,
      lg: `@media (min-width: 1024px)`,
      xl: `@media (min-width: 1200px)`,
      xxl: `@media (min-width: 1400px)`,
    },
    max: {
      sm: `@media (max-width: 576px)`,
      lg: `@media (max-width: 1024px)`,
    },
  },
  /* react-responsive breakpoints */
  reactMq: {
    max: {
      desktop: 1024,
    },
  },
  space: {
    xxs: 4,
    xs: 8,
    sm: 12,
    md: 16,
    lg: 24,
    xl: 32,
    xxl: 48,
    auto: "auto",
  } as const,
  text: {
    fonts: {
      /** Barlow */
      sans: "'Barlow', sans-serif",
    },
    sizes: {
      xxxl: 28,
      xxl: 24,
      xl: 18,
      lg: 16,
      med: 15,
      sm: 14,
      xs: 13,
      xxs: 12,
    } as const,
    lineHeights: {
      xs: "16px",
      sm: "17px",
      med: "20px",
      lg: "22px",
    } as const,
    weights: {
      reg: 400,
      med: 500,
      heavy: 600,
      bold: 700,
    } as const,
  },
  maxWidth: {
    screenLg: 800,
  },
  designSizes: {
    sm: 375,
  },
  navbar: {
    heights: {
      sm: 63,
    },
  },
  shadows: {
    down: `0px 4px 4px rgba(0, 0, 0, 0.25)`,
  },
}

export type Theme = typeof theme
export type Space = keyof Theme["space"]
export type Color = keyof Theme["color"]
