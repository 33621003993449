import { format, formatISO, parseISO } from "date-fns"
import { OrderStatus } from "./types"

export function parseISODate(s: string) {
  return parseISO(s)
}

export function formatISODate(d: Date) {
  return formatISO(d, { representation: "date" })
}

// Takes a datetime from saved view responses) and returns it as
// YYYY-MM-DD (for the app query string)
export function reformatCustomViewDate(date: string) {
  const parsed = parseISO(date)
  return format(parsed, "yyyy-LL-dd")
}

// Returns the provided datetime string in MM/DD/YY format
// ie 05/21/19
export function formatDatetimeString(datetimeString: string): string {
  const date = parseISO(datetimeString)

  return format(date, "LL/dd/yy")
}

// Extracts and returns a formatted date from a UR datetime string
// UR datetime format looks like `2016-07-10T10:39:02`
export function formatDatetimeStringWithDOW(datetimeString: string): string {
  const date = parseISO(datetimeString)

  return format(date, "E LL/dd/yy")
}

// Converts OVISS API statuses to a human-readable form
// Example: `InRoute` -> `In Route`
export function formatStatusString(status: OrderStatus): string {
  switch (status) {
    case "Active":
      return "Active (not dispatched)"
    case "PendingPickup":
      return "Pending Pick Up"
    case "InRoute":
      return "In Route"
    case "OnHold":
      return "On Hold"
    // Return the status as-is for anything else
    default:
      return status
  }
}

export function formatVIN(vin: string): string {
  // Handle vehicles with a null VIN
  return vin ? vin.slice(-8) : "Unknown"
}

// Combines `Address1` and `Address2` into a single line
export function fullStreetAddress(a1: string, a2?: string) {
  return `${a1}${a2 ? `, ${a2}` : ""}`
}

// Prepends the order id for vin searches
export function formatSearchTerm(searchTerm: string, orderId?: string) {
  if (searchTerm?.length > 0 && orderId?.startsWith(searchTerm)) {
    return searchTerm
  }
  return [orderId, searchTerm].filter(Boolean).join(" - ")
}
