import styled from "@emotion/styled"
import { groupBy } from "lodash"
import React, { useMemo, useState } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { createSearchParams, NavLink } from "react-router-dom"
import { Box } from "../../components/Box"
import {
  CancelButton,
  PrimaryButton,
} from "../../components/Buttons/PrimaryButton"
import { SecondaryButton } from "../../components/Buttons/SecondaryButton"
import { Dialog } from "../../components/Dialog"
import { DownloadOrderListButton } from "../../components/DownloadOrderListButton"
import { EmailOrderListButton } from "../../components/EmailOrderListButton"
import { ErrorIndicator } from "../../components/ErrorIndicator"
import { Text } from "../../components/Text"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import {
  currentOrdersListPath,
  historyOrdersListPath,
  inactiveOrdersListPath,
} from "../../paths"
import { ur_gray } from "../../utils/backgrounds"
import { useFlashMessageContext } from "../../utils/contexts/FlashMessageContext"
import { useGoBack } from "../../utils/hooks/useGoBack"
import { closeXWhite, saveBlue, saveWhite } from "../../utils/icons"
import {
  getOrderListGroupFromStatuses,
  getReverseSortDetails,
  orderListFiltersToFilterParams,
  SortByOption,
  SortField,
} from "../../utils/orderListFilters"
import { useMutationRequest, useRequest } from "../../utils/oviss"
import { GetCustomViewsResponse, SavedView } from "../../utils/types"
import { formatDatetimeString } from "../../utils/utils"

const savedViewsKey = [
  ["savedView"],
  {
    path: `/api/CustomerPortalSearchHistory/GetSavedSearchHistory`,
    method: "GET",
  },
]

function groupSavedViews(savedViews: GetCustomViewsResponse) {
  return groupBy(savedViews, (savedView) =>
    getOrderListGroupFromStatuses(
      savedView?.CustomerPortalSavedSearchDto?.OrderStatus || [],
    ),
  )
}

function getSortDisplayName(s: SortByOption) {
  switch (s) {
    case "pickUpDate":
      return "Pick Up Date"
    case "largest":
      return "Largest"
    case "smallest":
      return "Smallest"
    default:
      return "Delivery Date"
  }
}

const ViewListWrapper = styled.div((props) => ({
  display: "grid",
  gap: props.theme.space.sm,
  gridAutoFlow: "row",
  padding: props.theme.space.sm,
  background: `url(${ur_gray}) black`,
  color: props.theme.color.white,
  flex: 1,
}))

export function SavedViewsScreen() {
  const goBack = useGoBack()
  const request = useRequest()
  const flashMessageContext = useFlashMessageContext()
  useDocumentTitle("Saved Custom Views")

  const { data: savedViews, error } = useQuery<GetCustomViewsResponse>(
    savedViewsKey,
    request,
  )

  const groupedViews = useMemo(
    () => (savedViews ? groupSavedViews(savedViews) : {}),
    [savedViews],
  )

  return (
    <>
      <SavedViewBanner>
        <Box
          ph="sm"
          pv="md"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <CloseButton onClick={goBack}>
            <img src={closeXWhite} width={16} height={16} alt="close" />
          </CloseButton>
          <Box display="flex" alignItems="center">
            <Box display="flex" mr="xxs">
              <img src={saveWhite} width={30} height={30} alt="" />
            </Box>
            <Text fontSize="xl" fontWeight="heavy">
              Saved Custom Views
            </Text>
          </Box>
          <div />
        </Box>
      </SavedViewBanner>

      {flashMessageContext.message && <Box>{flashMessageContext.message}</Box>}

      <ViewListWrapper>
        {error && (
          <Box mv="sm">
            <ErrorIndicator error={error} justifyContent="flex-start" />
          </Box>
        )}

        {savedViews && savedViews.length === 0 && <EmptyListIndicator />}

        {groupedViews && groupedViews.current && (
          <>
            <Text>Current Orders</Text>
            {groupedViews.current.map((v) => (
              <CardNavLink
                key={v.Id}
                to={{
                  pathname: currentOrdersListPath(),
                  search:
                    "?" +
                    createSearchParams(
                      orderListFiltersToFilterParams(v),
                    ).toString(),
                }}
              >
                <SavedViewCard view={v} />
              </CardNavLink>
            ))}
          </>
        )}

        {groupedViews && groupedViews.history && (
          <>
            <Text>Order History</Text>
            {groupedViews.history.map((v) => (
              <CardNavLink
                key={v.Id}
                to={{
                  pathname: historyOrdersListPath(),
                  search:
                    "?" +
                    createSearchParams(
                      orderListFiltersToFilterParams(v),
                    ).toString(),
                }}
              >
                <SavedViewCard view={v} />
              </CardNavLink>
            ))}
          </>
        )}

        {groupedViews && groupedViews.inactive && (
          <>
            <Text>Inactive Orders</Text>
            {groupedViews.inactive.map((v) => (
              <CardNavLink
                key={v.Id}
                to={{
                  pathname: inactiveOrdersListPath(),
                  search:
                    "?" +
                    createSearchParams(
                      orderListFiltersToFilterParams(v),
                    ).toString(),
                }}
              >
                <SavedViewCard view={v} />
              </CardNavLink>
            ))}
          </>
        )}
      </ViewListWrapper>
    </>
  )
}

type SavedViewCardProps = {
  view: SavedView
}

function SavedViewCard({ view }: SavedViewCardProps) {
  const [activeFilters, numActiveFilters] = useMemo(() => {
    const possibleFilters: [string, unknown][] = [
      [
        "vins",
        view.CustomerPortalSavedSearchDto &&
        view.CustomerPortalSavedSearchDto.Vin8 &&
        view.CustomerPortalSavedSearchDto.Vin8.length > 0
          ? view.CustomerPortalSavedSearchDto.Vin8
          : null,
      ],
      [
        "orderIds",
        view.CustomerPortalSavedSearchDto &&
        view.CustomerPortalSavedSearchDto.OrderIds &&
        view.CustomerPortalSavedSearchDto.OrderIds.length > 0
          ? view.CustomerPortalSavedSearchDto.OrderIds
          : null,
      ],
      [
        "pickupDates",
        view.CustomerPortalSavedSearchDto?.PickupDateFrom
          ? {
              from: view.CustomerPortalSavedSearchDto?.PickupDateFrom,
              to: view.CustomerPortalSavedSearchDto?.PickupDateThru,
            }
          : null,
      ],
      [
        "deliveryDates",
        view.CustomerPortalSavedSearchDto?.DeliveryDateFrom
          ? {
              from: view.CustomerPortalSavedSearchDto?.DeliveryDateFrom,
              to: view.CustomerPortalSavedSearchDto?.DeliveryDateThru,
            }
          : null,
      ],
    ]

    let filtersObject: Record<string, any> = {}
    possibleFilters
      .filter(([_, v]) => !!v)
      .forEach(([k, v]) => (filtersObject[k] = v))
    return [filtersObject, Object.keys(filtersObject).length]
  }, [view])

  return (
    <SavedViewCardContainer>
      <Box>
        <Box p="md">
          <Text fontSize="xl" fontWeight="bold">
            {view.Name || view.Id}
          </Text>
        </Box>
      </Box>
      <HorizontalDivider />
      <Box display="flex" justifyContent="space-between" p="md">
        <Box>
          <>
            <Box mb="xxs">
              <Text fontSize="xxs" fontWeight="heavy">
                FILTER BY
              </Text>
            </Box>
            {numActiveFilters > 0 ? (
              <Text fontSize="sm">
                {activeFilters.vins && (
                  <Box>VINs: {activeFilters.vins.join(",")}</Box>
                )}
                {activeFilters.orderIds && (
                  <Box>Order IDs: {activeFilters.orderIds.join(",")}</Box>
                )}
                {activeFilters.pickupDates && (
                  <Box>
                    Pick Up Dates:{" "}
                    {formatDatetimeString(activeFilters.pickupDates.from)}
                    {" - "}
                    {formatDatetimeString(activeFilters.pickupDates.to)}
                  </Box>
                )}
                {activeFilters.deliveryDates && (
                  <Box>
                    Delivery Dates:{" "}
                    {formatDatetimeString(activeFilters.deliveryDates.from)}
                    {" - "}
                    {formatDatetimeString(activeFilters.deliveryDates.to)}
                  </Box>
                )}
              </Text>
            ) : (
              <Text fontSize="sm">(None selected)</Text>
            )}
          </>
        </Box>
        <Box>
          <Box mb="xxs">
            <Text fontSize="xxs" fontWeight="heavy">
              SORT BY
            </Text>
          </Box>
          <Text fontSize="sm">
            {getSortDisplayName(
              getReverseSortDetails(
                view.CustomerPortalSavedSearchDto?.SortField as SortField,
                view.CustomerPortalSavedSearchDto?.SortAscending as boolean,
              ),
            )}
          </Text>
        </Box>
      </Box>
      <HorizontalDivider />
      <Box
        display="grid"
        gridTemplateColumns="1fr 1px 1.25fr 1px 1fr"
        justifyItems="center"
        alignItems="center"
      >
        <Box p="sm">
          <EmailOrderListButton
            iconSize={18}
            colorTheme="dark"
            // @ts-expect-error bad api types
            listParams={view.CustomerPortalSavedSearchDto}
          >
            <Text fontSize="lg">SEND</Text>
          </EmailOrderListButton>
        </Box>
        <VerticalDivider />
        <Box p="sm">
          <DownloadOrderListButton
            iconSize={18}
            colorTheme="dark"
            listParams={view.CustomerPortalSavedSearchDto}
          >
            <Text fontSize="lg">DOWNLOAD</Text>
          </DownloadOrderListButton>
        </Box>
        <VerticalDivider />
        <Box p="sm">
          <RemoveSavedView savedViewID={view.Id} />
        </Box>
      </Box>
    </SavedViewCardContainer>
  )
}

const SavedViewCardContainer = styled.div((props) => ({
  backgroundColor: props.theme.color.white,
  color: props.theme.color.black,
}))

const HorizontalDivider = styled.div((props) => ({
  backgroundColor: props.theme.color.black,
  width: "100%",
  height: 1,
  margin: 0,
  padding: 0,
}))

type RemoveSavedViewProps = {
  savedViewID?: number
}

function RemoveSavedView({ savedViewID }: RemoveSavedViewProps) {
  const [showDialog, setShowDialog] = useState(false)
  const open = () => setShowDialog(true)
  const close = () => setShowDialog(false)

  const queryClient = useQueryClient()
  const mutationRequest = useMutationRequest()
  const deleteSavedView = useMutation(
    (_e: React.SyntheticEvent) => {
      return mutationRequest({
        path: "/api/CustomerPortalSearchHistory/RemoveSearchHistory",
        method: "DELETE",
        query: {
          searchHistoryId: savedViewID,
        },
      })
    },
    {
      // On success, delete this saved view from the cached response. Match
      // based on the view ID
      onSuccess: () => {
        queryClient.setQueryData<Array<any> | undefined>(
          savedViewsKey,
          (cachedData) => {
            // Unusual scenario
            // Log an error to the console and just return
            if (!cachedData) {
              console.error(`Couldn't find query key ${savedViewsKey}`)
              return cachedData
            }

            return cachedData.filter(({ Id }) => Id !== savedViewID)
          },
        )

        close()
      },
    },
  )

  return (
    <StyledSecondaryButton
      onClick={(e) => {
        e.preventDefault()
        e.stopPropagation()
        open()
      }}
    >
      <Box display="flex" alignItems="center">
        <img src={saveBlue} width={18} height={18} alt="download" />
        <Box ml="xs">
          <Text fontSize="lg">REMOVE</Text>
        </Box>
      </Box>
      <Dialog
        onClose={close}
        show={showDialog}
        ariaLabel="confirm"
        body="Are you sure you want to delete this custom view?"
        cancelButton={
          <CancelButton colorTheme="white" width="auto" onClick={close}>
            Cancel
          </CancelButton>
        }
        confirmButton={
          <PrimaryButton width={"auto"} onClick={deleteSavedView.mutateAsync}>
            Delete
          </PrimaryButton>
        }
      />
    </StyledSecondaryButton>
  )
}

const StyledSecondaryButton = styled(SecondaryButton)({
  padding: 0,
  textDecoration: "none",
})

const CloseButton = styled(StyledSecondaryButton)({
  margin: 0,
  display: "inherit",
})

const CardNavLink = styled(NavLink)({
  "textDecoration": "none",
  "color": "inherit",
  "&:visited": {
    color: "inherit",
  },
  "&:active": {
    color: "inherit",
  },
})

const VerticalDivider = styled.div((props) => ({
  backgroundColor: props.theme.color.black,
  width: 1,
  height: "100%",
}))

const SavedViewBanner = styled.div((props) => ({
  backgroundColor: props.theme.color.blue,
  color: props.theme.color.white,
}))

function EmptyListIndicator() {
  return (
    <Text color="white">
      <Box
        mt="xxl"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Box mb="md">
          <Text fontSize="xxl" fontWeight="bold">
            No Custom Views
          </Text>
        </Box>
        <Text fontSize="xl">
          Sorry, it looks like you don't have any Custom Views saved.
        </Text>
        <Text fontSize="xl">
          Save a set of filters to create a new Custom View.
        </Text>
      </Box>
    </Text>
  )
}
