import styled from "@emotion/styled"
import { useLoginContext } from "../utils/contexts/LoginContext"
import { SupportPageLink } from "./SupportPageLink"

export function Footer() {
  const { isLoggedIn } = useLoginContext()
  return <FixedFooter>{isLoggedIn && <SupportPageLink />}</FixedFooter>
}

const FixedFooter = styled.div((props) => ({
  // should be on top of everything else
  zIndex: 1000,
  position: "fixed",
  bottom: props.theme.space.sm,
  right: props.theme.space.sm,
}))
