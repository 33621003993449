/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useRef } from "react"

/**
 * based on https://github.com/rehooks/document-title
 */
export function useDocumentTitle(title: string, suffix = ` · Customer Portal`) {
  const defaultTitle = useRef(document.title)

  const displayTitle = `${title}${suffix}`

  useEffect(() => {
    document.title = displayTitle
  }, [displayTitle])

  useEffect(() => {
    return () => {
      document.title = defaultTitle.current
    }
  }, [])
}
