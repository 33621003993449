import styled from "@emotion/styled"
import { useFormik } from "formik"
import React, { useEffect } from "react"
import { useQuery } from "react-query"
import { NavLink, useSearchParams } from "react-router-dom"
import { Box } from "../../components/Box"
import { SecondaryButtonInline } from "../../components/Buttons/SecondaryButtonInline"
import { HorizontalDivider } from "../../components/HorizontalDivider"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { Text } from "../../components/Text"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import { viewOrderPath } from "../../paths"
import {
  useCurrentUserConsignors,
  useCurrentUserQuery,
} from "../../utils/hooks/useCurrentUserQuery"
import { useGoBack } from "../../utils/hooks/useGoBack"
import { faqArrow, search } from "../../utils/icons"
import { OrderListGroup } from "../../utils/orderListFilters"
import { useRequest } from "../../utils/oviss"
import { useSearchHistory } from "../../utils/search"
import { AutocompleteResponse } from "../../utils/types"
import { formatSearchTerm } from "../../utils/utils"

export function SearchOrdersScreen() {
  const goBack = useGoBack()
  useDocumentTitle("Search Orders")
  const [searchParams, setSearchParams] = useSearchParams()
  const [searches, appendSearch] = useSearchHistory()

  const formik = useFormik({
    initialValues: {
      search: searchParams.get("search") || "",
      orderGroup: (searchParams.get("orderGroup") ||
        "current") as OrderListGroup,
    },
    onSubmit: () => {},
  })

  useEffect(() => {
    setSearchParams(formik.values, { replace: true })
  }, [formik.values, setSearchParams])

  const request = useRequest()
  const { data: user } = useCurrentUserQuery()
  const consignorResult = useCurrentUserConsignors()

  let consignorParams = new URLSearchParams()
  for (let consignor of consignorResult.allConsignors || []) {
    consignorParams.append("clientIds", consignor.ClientId!.toString())
  }
  const { data, isLoading } = useQuery<AutocompleteResponse>(
    [
      "orders",
      {
        path: `/api/CustomerPortalOrders/OrderAutoCompleteOrderIdVin/${encodeURIComponent(
          formik.values.search,
        )}?${searchParams}`,
        query: { clientId: user?.Client.ClientId },
        method: "GET",
      },
    ],
    request,
    { enabled: formik.values.search?.length >= 3 },
  )

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box
          display="flex"
          position="relative"
          alignItems="center"
          justifyItems="center"
        >
          <Box position="absolute" ml="sm">
            <img src={search} alt="" />
          </Box>
          <Box flex={1}>
            <StyledTextInput
              type="text"
              placeholder="Search by VIN or order number"
              name="search"
              id="search"
              value={formik.values.search}
              onChange={formik.handleChange}
            />
          </Box>
          <StyledSelectContainer>
            <StyledSelect
              name="orderGroup"
              value={formik.values.orderGroup}
              onChange={formik.handleChange}
            >
              <option value="current">Current</option>
              <option value="history">History</option>
              <option value="inactive">Inactive</option>
            </StyledSelect>
          </StyledSelectContainer>
          <Box>
            <SecondaryButtonInline onClick={goBack}>
              Cancel
            </SecondaryButtonInline>
          </Box>
        </Box>
      </Box>
      {formik.values.search === "" ? (
        <Box>
          <Box mv="sm" p="sm" bg="lightGray">
            <Text fontSize="sm" color="darkGray" fontWeight="bold">
              RECENT SEARCHES
            </Text>
          </Box>
          <Box mh="sm">
            {searches.length > 0 ? (
              searches.map(
                ({ search: searchQuery, orderGroup, orderId }, idx) => (
                  <React.Fragment key={idx}>
                    <Box>
                      <LoadSavedSearchButton
                        onClick={() =>
                          formik.setValues({ search: searchQuery, orderGroup })
                        }
                      >
                        <Box pv="md">
                          <Text color="darkGray">
                            {formatSearchTerm(searchQuery, orderId)}
                          </Text>
                        </Box>
                      </LoadSavedSearchButton>
                    </Box>
                    <HorizontalDivider color="lightGray" />
                  </React.Fragment>
                ),
              )
            ) : (
              <Box>No recent searches</Box>
            )}
          </Box>
        </Box>
      ) : isLoading ? (
        <LoadingIndicator colorScheme="black" />
      ) : (
        <Box mh="sm">
          {data && data?.Matches.length > 0 ? (
            data?.Matches.map(({ OrderId, Vin }) => (
              <React.Fragment key={OrderId}>
                <StyledNavLink to={viewOrderPath(OrderId?.toString())}>
                  <SaveSearchButton
                    onClick={() =>
                      appendSearch({ ...formik.values, orderId: OrderId })
                    }
                  >
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Box pv="md">
                        <Text color="darkGray">
                          {formatSearchTerm(Vin, OrderId)}
                        </Text>
                      </Box>
                      <Box>
                        <img src={faqArrow} alt="" />
                      </Box>
                    </Box>
                  </SaveSearchButton>
                </StyledNavLink>
                <HorizontalDivider color="lightGray" />
              </React.Fragment>
            ))
          ) : (
            <Box mt="md" textAlign="center">
              No matches found
            </Box>
          )}
        </Box>
      )}
    </Box>
  )
}

// Inputs are hard to get to be the same size, so we're setting an
// explicit height to make things easier
const inputMaxHeight = 35

const StyledTextInput = styled.input((props) => ({
  borderRadius: props.theme.space.lg,
  border: `1px solid ${props.theme.color.medGray}`,
  padding: props.theme.space.xs,
  paddingLeft: props.theme.space.xxl,
  fontSize: props.theme.text.sizes.lg,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  maxHeight: inputMaxHeight,
  color: props.theme.color.medGray,
  width: "100%",
  margin: 0,
}))

const StyledSelect = styled.select((props) => ({
  "borderRadius": props.theme.space.lg,
  "borderTopLeftRadius": 0,
  "borderBottomLeftRadius": 0,
  "backgroundColor": "white",
  "border": `1px solid ${props.theme.color.medGray}`,
  "padding": props.theme.space.xs,
  "fontSize": props.theme.text.sizes.lg,
  "maxHeight": inputMaxHeight,
  "color": props.theme.color.medGray,
  "-moz-appearance": "none",
  "-webkit-appearance": "none",
  "appearance": "none",
  "minWidth": 100,
  "position": "relative",
  "margin": 0,
  "marginLeft": -1,
}))

const StyledSelectContainer = styled.div((props) => ({
  "position": "relative",
  "&:after": {
    content: "''",
    width: 0,
    height: 0,
    marginLeft: props.theme.space.xxs,
    position: "absolute",
    right: 14,
    top: 16,
    borderLeft: "3px solid transparent",
    borderRight: "3px solid transparent",
    borderTop: "5px solid white",
    borderTopColor: props.theme.color.black,
    color: props.theme.color.black,
  },
}))

const StyledNavLink = styled(NavLink)({
  textDecoration: "none",
})

const LoadSavedSearchButton = styled(SecondaryButtonInline)({
  width: "100%",
  textAlign: "left",
  textDecoration: "none",
})

const SaveSearchButton = styled(SecondaryButtonInline)({
  width: "100%",
  textDecoration: "none",
})
