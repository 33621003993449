import styled from "@emotion/styled"
import { useFormik } from "formik"
import { useMutation } from "react-query"
import { NavLink } from "react-router-dom"
import * as Yup from "yup"
import { Box } from "../../components/Box"
import { PrimaryButton } from "../../components/Buttons/PrimaryButton"
import { SecondaryButtonInline } from "../../components/Buttons/SecondaryButtonInline"
import { ErrorIndicator } from "../../components/ErrorIndicator"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { Text } from "../../components/Text"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import { landingPath, resetPasswordPath } from "../../paths"
import textureBGGray from "../../utils/backgrounds/ur_gray.jpg"
import { useFlashMessageContext } from "../../utils/contexts/FlashMessageContext"
import { useGoBack } from "../../utils/hooks/useGoBack"
import { useMutationRequest } from "../../utils/oviss"

export function ForgotPasswordScreen() {
  const flashContext = useFlashMessageContext()
  const goBack = useGoBack()
  const request = useMutationRequest()

  useDocumentTitle(`Forgot Password`)
  const { mutateAsync, isSuccess, isLoading } = useMutation(
    ({ username }: { username: string }) => {
      return request({
        path: "/api/Users/ForgotPassword",
        method: "POST",
        query: {
          username,
          passwordResetUrl: `${window.location.origin}${resetPasswordPath()}`,
          templateOverride: "MovesResetPassword.cshtml",
          emailSubjectOverride: `United Road Customer Portal Password Reset`,
          fromAddress: "info@unitedroad.com",
        },
      })
    },
    {
      onError: (e) => flashContext.setMessage(<ErrorIndicator error={e} />),
    },
  )
  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: Yup.object().shape({
      username: Yup.string().required("Required"),
    }),
    onSubmit: async (values) => {
      return await mutateAsync(values)
    },
  })

  return (
    <Box
      background={`url(${textureBGGray}) black`}
      display="grid"
      justifyItems="center"
      flex={1}
    >
      <Box
        bg="white"
        m="xl"
        p="xl"
        minHeight="200px"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          mb="lg"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
        >
          <Text fontSize="xxl" fontWeight="bold">
            Reset Your Password
          </Text>
        </Box>

        {isLoading ? (
          <LoadingIndicator colorScheme="black" />
        ) : isSuccess ? (
          <Box
            textAlign="center"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            <Text fontSize="xxl" fontWeight="bold">
              An email is on its way to you. Follow the instructions to reset
              your password.
            </Text>

            <Box mt="xl">
              <NavLink to={landingPath()}>Back to login</NavLink>
            </Box>
          </Box>
        ) : (
          <>
            <Box mb="md" textAlign="center">
              <Text fontSize="xl" fontWeight="bold">
                Enter your username below and we'll send you a link to reset
                your password.
              </Text>
            </Box>
            {flashContext.message && <Box mb="sm">{flashContext.message}</Box>}
            <form onSubmit={formik.handleSubmit}>
              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
              >
                <StyledLabel>
                  Username
                  <LabelErrorIndicator>
                    {formik.touched.username && formik.errors.username}
                  </LabelErrorIndicator>
                </StyledLabel>
                <StyledInput
                  type="username"
                  id="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Box>

              <StyledHr />

              <Box mt="lg" display="flex" alignItems="center">
                <PrimaryButton width="100%" type="submit">
                  Send reset password email
                </PrimaryButton>
              </Box>

              <Box display="flex" mt="sm">
                <SecondaryButtonInline onClick={goBack}>
                  Cancel
                </SecondaryButtonInline>
              </Box>
            </form>
          </>
        )}
      </Box>
    </Box>
  )
}

const StyledLabel = styled.label((props) => ({
  color: props.theme.color.medGray,
  fontSize: props.theme.text.sizes.sm,
  marginBottom: props.theme.space.xxs,
}))

const StyledInput = styled.input((props) => ({
  border: "none",
  margin: 0,
  padding: 0,
  color: props.theme.color.darkGray,
  fontSize: props.theme.text.sizes.xl,
  fontFamily: "'Barlow', sans-serif",
}))

const LabelErrorIndicator = styled.span((props) => ({
  marginLeft: props.theme.space.xs,
  color: props.theme.color.warning,
  whiteSpace: "pre-wrap",
}))

const StyledHr = styled.hr((props) => ({
  backgroundColor: props.theme.color.darkGray,
}))
