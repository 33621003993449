import styled from "@emotion/styled"
import {
  Combobox,
  ComboboxInput,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox"
import "@reach/combobox/styles.css"
import { useState } from "react"
import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import { viewOrderPath } from "../paths"
import { useCurrentUserConsignors } from "../utils/hooks/useCurrentUserQuery"
import { search as searchIcon } from "../utils/icons"
import { useRequest } from "../utils/oviss"
import { useSearchHistory } from "../utils/search"
import { AutocompleteResponse } from "../utils/types"
import { formatSearchTerm } from "../utils/utils"
import { Box } from "./Box"

export function DesktopOrderSearchCombobox() {
  const navigate = useNavigate()
  const [searchQuery, setSearchQuery] = useState("")
  const [searches, appendSearch] = useSearchHistory()

  const request = useRequest()
  const consignorResult = useCurrentUserConsignors()

  let searchParams = new URLSearchParams()
  for (let consignor of consignorResult.allConsignors || []) {
    searchParams.append("clientIds", consignor.ClientId!.toString())
  }

  const { data, isLoading } = useQuery<AutocompleteResponse>(
    [
      "orders",
      {
        path: `/api/CustomerPortalOrders/OrderAutoCompleteOrderIdVin/${encodeURIComponent(
          searchQuery,
        )}?${searchParams}`,
        method: "GET",
      },
    ],
    request,
    {
      enabled:
        searchQuery?.length >= 3 && consignorResult.allConsignors != null,
    },
  )

  return (
    <Combobox
      openOnFocus={true}
      aria-label="search by VIN or order ID"
      onSelect={(value: string) => setSearchQuery(value)}
    >
      <Box
        display="flex"
        position="relative"
        alignItems="center"
        justifyItems="center"
      >
        <Box position="absolute" ml="sm">
          <img height="14" width="14" src={searchIcon} alt="" />
        </Box>
        <StyledComboboxInput
          value={searchQuery}
          placeholder="Search by VIN or order number"
          onChange={(e: any) => setSearchQuery(e.target.value)}
        />
      </Box>
      <ComboboxPopover>
        <ComboboxList>
          {searchQuery === "" ? (
            searches.map(({ search, orderId }, idx) => (
              <ComboboxOption
                key={idx}
                value={orderId || ""}
                onClick={() => {
                  navigate(viewOrderPath(orderId))
                }}
              >
                {formatSearchTerm(search, orderId)}
              </ComboboxOption>
            ))
          ) : data && data?.Matches.length > 0 ? (
            data?.Matches.map(({ OrderId, Vin }, idx) => {
              const value = OrderId.startsWith(searchQuery) ? OrderId : Vin
              return (
                <ComboboxOption
                  key={idx}
                  value={value}
                  onClick={() => {
                    appendSearch({
                      search: value,
                      orderGroup: "current",
                      orderId: OrderId,
                    })
                    navigate(viewOrderPath(OrderId))
                  }}
                >
                  {formatSearchTerm(value, OrderId)}
                </ComboboxOption>
              )
            })
          ) : isLoading ? (
            <Box m="xs">Loading...</Box>
          ) : (
            <Box m="xs">No matches found</Box>
          )}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  )
}

const StyledComboboxInput = styled(ComboboxInput)((props) => ({
  borderRadius: props.theme.space.lg,
  border: `1px solid ${props.theme.color.darkGray}`,
  padding: props.theme.space.xxs,
  paddingLeft: props.theme.space.xl,
  color: props.theme.color.darkGray,
  width: "100%",
  marginRight: props.theme.space.lg,
}))
