import styled from "@emotion/styled"

export const Container = styled.div((props) => ({
  margin: "0 auto",
  maxWidth: 1024,

  [props.theme.mq.max.lg]: {
    maxWidth: "100%",
  },
}))
