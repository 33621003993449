import styled from "@emotion/styled"
import { arrowWhite, arrowBlack } from "../utils/icons"
import { useGoBack } from "../utils/hooks/useGoBack"

export function BackArrow({ theme }: { theme: "light" | "dark" }) {
  const goBack = useGoBack()

  return (
    <StyledBackButton onClick={goBack}>
      <img alt="go back" src={theme === "light" ? arrowWhite : arrowBlack} />
    </StyledBackButton>
  )
}

const StyledBackButton = styled.button({
  "cursor": "pointer",
  "border": "none",
  "margin": 0,
  "padding": 0,
  "width": "auto",
  "overflow": "visible",
  "background": "transparent",
  "color": "inherit",
  "font": "inherit",
  "textAlign": "inherit",
  "lineHeight": "normal",
  "WebkitFontSmoothing": "inherit",
  "MozOsxFontSmoothing": "inherit",
  "WebkitAppearance": "none",
  "&::-moz-focus-inner": {
    border: 0,
    padding: 0,
  },
})
