import styled from "@emotion/styled"
import * as css from "csstype"
import { Box } from "../components/Box"
import { Color, Space } from "../emotion/theme"

type HorizontalDividerProps = {
  mv?: Space
  mt?: Space
  mb?: Space
  color?: Color
  height?: css.Properties["height"]
}

export function HorizontalDivider({
  mv,
  mt,
  mb,
  color,
  height,
}: HorizontalDividerProps) {
  return (
    <Box height={height} mt={mt} mb={mb} mv={mv}>
      <Divider color={color} height={height} />
    </Box>
  )
}

export const Divider = styled.div<{
  color?: Color
  height?: css.Properties["height"]
}>((props) => ({
  width: "100%",
  height: props.height ? props.height : 1,
  background: props.color
    ? props.theme.color[props.color]
    : props.theme.color.black,
}))
