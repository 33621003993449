import styled from "@emotion/styled"
import { Field, Form, Formik } from "formik"
import { useEffect, useState } from "react"
import { useMutation } from "react-query"
import { NavLink, useNavigate } from "react-router-dom"
import { Box } from "../../components/Box"
import { PrimaryButton } from "../../components/Buttons/PrimaryButton"
import { SecondaryButtonInline } from "../../components/Buttons/SecondaryButtonInline"
import { Dialog } from "../../components/Dialog"
import { FormSuccessIndicator } from "../../components/FormSuccessIndicator"
import { GridArea } from "../../components/GridArea"
import { HorizontalDivider } from "../../components/HorizontalDivider"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { PaddedContentsWrapper } from "../../components/PaddedContentsWrapper"
import { ShowIfDesktop } from "../../components/ShowIfDesktop"
import { Text } from "../../components/Text"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import {
  currentOrdersListPath,
  forgotPasswordPath,
  resetExpiredPasswordPath,
} from "../../paths"
import textureBGBlue from "../../utils/backgrounds/ur_blue.png"
import textureBGGray from "../../utils/backgrounds/ur_gray.jpg"
import { useFlashMessageContext } from "../../utils/contexts/FlashMessageContext"
import { useLoginContext } from "../../utils/contexts/LoginContext"
import { useIsMobile } from "../../utils/hooks/commonMediaQueryHooks"
import {
  API_ROOT,
  createSessionRequest,
  USING_DEFAULT_API_ROOT,
} from "../../utils/oviss"
import landingImage from "./landing_image.png"
import { loginFormSchema } from "./loginFormSchema"
import Recaptcha from "react-recaptcha"

export const LandingScreen = () => {
  const { isLoggedIn } = useLoginContext()
  const isMobile = useIsMobile()
  const navigate = useNavigate()
  const flashContext = useFlashMessageContext()

  useEffect(() => {
    if (isLoggedIn) {
      navigate(currentOrdersListPath(), { replace: true })
    }
  }, [navigate, isLoggedIn])

  useDocumentTitle(`Customer Portal`, ``)

  return (
    <>
      {flashContext.message && <Box mb="sm">{flashContext.message}</Box>}
      <LandingContentsWrapper isMobile={isMobile}>
        <GridArea gridArea="divider" hidden={isMobile}>
          <HorizontalDivider height="100%" />
        </GridArea>
        <GridArea
          gridArea="heroImage"
          style={{
            background: "#1b76fe",
          }}
        >
          <LandingLink
            href="https://bit.ly/URSportalvideo"
            target="_blank"
            rel="noreferrer"
          >
            <img src={landingImage} width="100%" alt="Customer Portal" />
          </LandingLink>
        </GridArea>
        <GridArea gridArea="login">
          <PaddedContentsWrapper>
            {!USING_DEFAULT_API_ROOT && (
              <Box mt="sm" mb="lg" display="flex" justifyContent="center">
                <Text color="green" fontWeight="bold">
                  Using API root: <br /> {API_ROOT}
                </Text>
              </Box>
            )}
            <Box
              width={isMobile ? "340px" : "60%"}
              pt="xxl"
              pb="xxl"
              minWidth="340px"
              margin={"0 auto"}
              mt={isMobile ? undefined : "xxl"}
              flexDirection="column"
              display="flex"
              justifyContent="center"
            >
              <Box mb={"lg"}>
                <LoginCTA>Keeping track of cars is easy.</LoginCTA>
              </Box>
              <LoginForm isMobile={isMobile} />
              <ShowIfDesktop>
                <Box mt="lg" display="flex" justifyContent="center">
                  <NavLink to={forgotPasswordPath()}>
                    <StyledSecondaryButtonInline>
                      <Text color="black" textDecoration="underline">
                        Forgot your password?
                      </Text>
                    </StyledSecondaryButtonInline>
                  </NavLink>
                </Box>
              </ShowIfDesktop>
              <RegisterAccount />
            </Box>
          </PaddedContentsWrapper>
        </GridArea>
      </LandingContentsWrapper>
      <Box hidden={!isMobile}>
        <TexturedBGGray>
          <NavLink to={forgotPasswordPath()}>
            <StyledSecondaryButtonInline>
              Forgot your password?
            </StyledSecondaryButtonInline>
          </NavLink>
        </TexturedBGGray>
      </Box>
    </>
  )
}

const LandingContentsWrapper = styled.div<{ isMobile: boolean }>((props) => ({
  minHeight: "716px",
  display: "grid",
  gridTemplateAreas: props.isMobile
    ? `"heroImage" "heroText" "login"`
    : `"divider divider" "heroImage login"`,
  gridTemplateColumns: props.isMobile ? "1fr" : "1fr 1fr",
  gridTemplateRows: props.isMobile
    ? "minmax(0, 1fr) min-content min-content"
    : "2px 7fr",
}))

const TexturedBGGray = styled.div((props) => ({
  background: `url(${textureBGGray}) black`,
  padding: "46px 0",
  color: props.theme.color.white,
  fontSize: props.theme.text.sizes.sm,
  fontWeight: props.theme.text.weights.med,
  lineHeight: props.theme.text.lineHeights.med,
  textAlign: "center",
}))

const LoginCTA = styled.div((props) => ({
  textAlign: "center",
  fontSize: props.theme.text.sizes.xl,
  fontWeight: props.theme.text.weights.bold,
  lineHeight: props.theme.text.lineHeights.lg,
}))

type LoginFormProps = {
  isMobile: boolean
}

const RegisterAccount = () => {
  const [showedSuccessFlash, setShowedSuccessFlash] = useState(false)
  const [showDialog, setShowDialog] = useState(false)
  const flashContext = useFlashMessageContext()
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)

  const open = () => setShowDialog(true)
  const close = () => setShowDialog(false)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    let flashMessageTimout: NodeJS.Timeout | undefined
    if (!showedSuccessFlash && params.has("registerSuccess")) {
      flashMessageTimout = setTimeout(() => {
        flashContext.setMessage(
          <FormSuccessIndicator message="Your registration request has been submitted" />,
        )
        setShowedSuccessFlash(true)
      }, 500)
    }

    return () => {
      if (typeof flashMessageTimout !== "undefined")
        clearTimeout(flashMessageTimout)
    }
  }, [flashContext, showedSuccessFlash])

  return (
    <Box>
      <Box
        height="100%"
        mt="xxl"
        display="flex"
        alignItems="center"
        justifyContent="center"
        background={`url(${textureBGBlue})`}
        p="sm"
      >
        <Text color="white" fontWeight="med">
          Don't have an account?
        </Text>
        <StyledRegisterButton onClick={open}>
          <Text color="white" textDecoration="underline" fontWeight="med">
            Register Now
          </Text>
        </StyledRegisterButton>
      </Box>
      <Dialog
        ariaLabel="register"
        onClose={close}
        show={showDialog}
        body={
          <Box mt="xxl">
            <h2>Register</h2>
            <form
              action="https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8"
              method="POST"
            >
              <input type="hidden" name="oid" value="00D60000000ITqi" />
              <input
                type="hidden"
                name="lead_source"
                value="Customer Portal User Registration"
              />
              <RegisterStyledField
                type="hidden"
                name="retURL"
                value={`https://moves.unitedroad.com/?registerSuccess=true`}
              />
              <Box mb="sm">
                <StyledRegisterLabel>First Name</StyledRegisterLabel>
                <RegisterStyledField
                  id="first_name"
                  maxLength={40}
                  name="first_name"
                  size={20}
                  required={true}
                />
              </Box>
              <Box mb="sm">
                <StyledRegisterLabel>Last Name</StyledRegisterLabel>
                <RegisterStyledField
                  id="last_name"
                  maxLength={80}
                  name="last_name"
                  size={20}
                  type="text"
                  required={true}
                />
              </Box>
              <Box mb="sm">
                <StyledRegisterLabel>Title</StyledRegisterLabel>
                <RegisterStyledField
                  id="title"
                  name="title"
                  maxLength={80}
                  size={20}
                  type="text"
                />
              </Box>
              <Box mb="sm">
                <StyledRegisterLabel>Company</StyledRegisterLabel>
                <RegisterStyledField
                  id="company"
                  maxLength={40}
                  name="company"
                  size={20}
                  type="text"
                  required={true}
                />
              </Box>
              <Box mb="sm">
                <StyledRegisterLabel htmlFor="email">Email</StyledRegisterLabel>
                <RegisterStyledField
                  id="email"
                  maxLength={80}
                  name="email"
                  size={20}
                  type="email"
                  required={true}
                />
              </Box>

              <Box mb="sm">
                <StyledRegisterLabel htmlFor="phone">Phone</StyledRegisterLabel>
                <RegisterStyledField
                  id="phone"
                  maxLength={40}
                  name="phone"
                  size={20}
                  type="text"
                  required={true}
                />
              </Box>

              <Box mb="sm">
                <StyledRegisterLabel htmlFor="description">
                  VIN to be Tracked
                </StyledRegisterLabel>
                <RegisterStyledField
                  id="description"
                  name="description"
                  maxLength={120}
                  size={20}
                  type="text"
                />
              </Box>

              {!isRecaptchaVerified && (
                <Box mt="md">
                  <Recaptcha
                    sitekey="6Le3wqEhAAAAAD9EOPvcTnWdlyVC8-D6pByNAxWj"
                    render="explicit"
                    verifyCallback={() => setIsRecaptchaVerified(true)}
                  />
                </Box>
              )}
              <Box mb="xxl"></Box>
              <StyledRegisterSubmitButton
                type="submit"
                disabled={!isRecaptchaVerified}
              >
                Register
              </StyledRegisterSubmitButton>

              <Box display="flex" mt="sm">
                <SecondaryButtonInline onClick={close}>
                  Cancel
                </SecondaryButtonInline>
              </Box>
            </form>
          </Box>
        }
      />
    </Box>
  )
}

const LoginForm = ({ isMobile }: LoginFormProps) => {
  const { setToken } = useLoginContext()
  const mutation = useMutation(createSessionRequest)
  const navigate = useNavigate()

  return (
    <Formik
      initialValues={{ username: "", password: "" }}
      validationSchema={loginFormSchema}
      onSubmit={(
        values: { username: string; password: string },
        { setErrors },
      ) => {
        // Need to wrap this `mutation.mutate` in a promise so that formik will
        // un-set `isSubmitting` if there is an error with login.
        return new Promise<void>((resolve) => {
          mutation.mutate(values, {
            onSuccess: (loginResponse) => {
              if (
                loginResponse.data &&
                loginResponse.data.error === "password_expired"
              ) {
                return navigate(
                  `${resetExpiredPasswordPath()}?username=${values.username}`,
                )
              }

              if (loginResponse.ok) {
                // Save the token for future API requests
                setToken(loginResponse.data.access_token)
              } else {
                let errors: Record<string, string> = {}
                loginResponse.validationErrors.forEach(
                  ({ field, message }: { field: string; message: string }) => {
                    errors[field] = message
                  },
                )
                setErrors(errors)
              }
              resolve()
            },
            onError: () => resolve(),
          })
        })
      }}
    >
      {({ errors, touched, isSubmitting }) => (
        <Form>
          <Box mb={isMobile ? "sm" : "lg"}>
            <Box mb={isMobile ? undefined : "lg"}>
              <StyledLabel htmlFor="username">
                Username
                <ErrorIndicator>
                  {" "}
                  {touched.username && errors.username}
                </ErrorIndicator>
              </StyledLabel>
              <StyledField name="username" id="username" />
              <HorizontalDivider mb="xs" />
            </Box>

            <Box mb={isMobile ? undefined : "lg"}>
              <StyledLabel htmlFor="password">
                Password
                <ErrorIndicator>
                  {" "}
                  {touched.password && errors.password}
                </ErrorIndicator>
              </StyledLabel>
              <StyledField name="password" type="password" id="password" />
              <HorizontalDivider mb="xs" />
            </Box>
          </Box>

          {mutation.error ? (
            <div>
              {mutation.error instanceof Error
                ? mutation.error.message
                : "unknown error"}
            </div>
          ) : null}

          <Box display="flex" justifyContent="center">
            {!isSubmitting ? (
              <StyledPrimaryButton type="submit">Sign In</StyledPrimaryButton>
            ) : (
              <LoadingIndicator colorScheme="black" />
            )}
          </Box>
        </Form>
      )}
    </Formik>
  )
}

const StyledLabel = styled.label((props) => ({
  display: "block",
  fontSize: props.theme.text.sizes.lg,
  lineHeight: props.theme.text.lineHeights.med,
  marginBottom: props.theme.space.sm,
}))

const StyledRegisterLabel = styled.label((props) => ({
  display: "block",
  fontSize: props.theme.text.sizes.lg,
  lineHeight: props.theme.text.lineHeights.med,
  marginBottom: props.theme.space.xxs,
  textAlign: "left",
}))

const StyledField = styled(Field)((props) => ({
  border: "none",
  margin: 0,
  color: props.theme.color.darkGray,
  fontSize: props.theme.text.sizes.xl,
  fontFamily: "'Barlow', sans-serif",
  width: "100%",
}))

const RegisterStyledField = styled.input((props) => ({
  paddingLeft: props.theme.space.sm,
  paddingRight: props.theme.space.sm,
  paddingTop: props.theme.space.xs,
  paddingBottom: props.theme.space.xs,
  fontSize: props.theme.text.sizes.sm,
  width: "100%",
}))

const ErrorIndicator = styled.span((props) => ({
  marginLeft: props.theme.space.xs,
  color: props.theme.color.warning,
}))

const StyledSecondaryButtonInline = styled(SecondaryButtonInline)((props) => ({
  color: props.theme.color.white,
}))

const StyledPrimaryButton = styled(PrimaryButton)((props) => ({
  [props.theme.mq.min.lg]: {
    marginTop: props.theme.space.xxl,
  },
}))

const StyledRegisterSubmitButton = styled(PrimaryButton)({
  width: "100%",
})

const StyledRegisterButton = styled(Box)({
  background: "none",
  cursor: "pointer",
  marginLeft: 5,
})

const LandingLink = styled.a({ display: "flex", flex: "1" })
