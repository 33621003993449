import styled from "@emotion/styled"
import { Disclosure } from "@reach/disclosure"
import { useMemo } from "react"
import { orderFromFlat } from "../../api/order"
import { useIsMobile } from "../../utils/hooks/commonMediaQueryHooks"
import { ApiFlatOrder } from "../../utils/types"
import {
  formatDatetimeString,
  formatStatusString,
  formatVIN,
} from "../../utils/utils"
import { Box } from "../Box"
import { GridArea } from "../GridArea"
import { Locations } from "../Locations"
import {
  HorizontalDivider,
  MileageDisplay,
  OrderCardId,
  OrderStatusDisplay,
  OrderStatusProgressBar,
  VerticalDivider,
} from "../OrderViewComponents"
import { Text } from "../Text"
import { TogglePriorityButton } from "../TogglePriorityButton"

type OrderCardProps = {
  flatOrder: ApiFlatOrder
}

export function OrderCard({ flatOrder }: OrderCardProps) {
  const { VehicleVin } = flatOrder
  const order = orderFromFlat(flatOrder)
  const {
    OrderId,
    OrderStatus,
    Origin,
    ScheduledPickupDate,
    ActualPickupDate,
    Destination,
    EstimatedDeliveryDate,
    ActualDeliveryDate,
    OrderMiles,
    IsUserPriority,
  } = orderFromFlat(flatOrder)
  const originCityState = [Origin?.City, Origin?.State]
    .filter(Boolean)
    .join(", ")
  const destinationCityState = [Destination?.City, Destination?.State]
    .filter(Boolean)
    .join(", ")

  const isMobile = useIsMobile()
  const vins = VehicleVin?.split(", ")
    .map((vin) => formatVIN(vin))
    .join(", ")

  const orderStatus = useMemo(
    () => formatStatusString(OrderStatus),
    [OrderStatus],
  )

  return (
    <OrderCardWrapper>
      <Disclosure>
        <GridArea gridArea="locations" hidden={!isMobile}>
          <Box ml="sm" mt="sm">
            <Locations order={order} colorTheme="dark" />
          </Box>
        </GridArea>
        <GridArea gridArea="id">
          <Box ml="sm" mt="sm">
            <OrderCardId>{OrderId}</OrderCardId>
          </Box>
        </GridArea>
        <GridArea gridArea="statusBar" justifySelf="start">
          <Box ml="sm">
            <OrderStatusProgressBar status={OrderStatus} />
          </Box>
        </GridArea>
        <GridArea gridArea="status">
          <Box mt="sm" mr="sm" textAlign="right">
            <OrderStatusDisplay status={OrderStatus}>
              {orderStatus}
            </OrderStatusDisplay>
          </Box>
        </GridArea>
        <GridArea
          gridArea="mileage"
          alignSelf={isMobile ? "end" : "start"}
          justifySelf={isMobile ? "end" : "start"}
        >
          <Box
            mt={!isMobile ? "sm" : undefined}
            ml={!isMobile ? "xs" : undefined}
            mr={isMobile ? "sm" : undefined}
          >
            <MileageDisplay>{OrderMiles.toFixed(0)} mi.</MileageDisplay>
          </Box>
        </GridArea>
        <GridArea gridArea="origin" hidden={isMobile}>
          <Box ml="sm">
            <CityTitle title={originCityState}>{originCityState}</CityTitle>
            <ConsignorName>{flatOrder.ConsignorName}</ConsignorName>
          </Box>
        </GridArea>
        <GridArea gridArea="dest" hidden={isMobile}>
          <Box ml="lg">
            <CityTitle title={destinationCityState}>
              {destinationCityState}
            </CityTitle>
          </Box>
        </GridArea>
        <GridArea gridArea="originDate" hidden={isMobile}>
          <Text fontSize="med" color="blue" fontWeight="heavy">
            {ActualPickupDate ? (
              <>Actual: {formatDatetimeString(ActualPickupDate)}</>
            ) : (
              <>
                Estimated:{" "}
                {ScheduledPickupDate
                  ? formatDatetimeString(ScheduledPickupDate)
                  : "–"}
              </>
            )}
          </Text>
        </GridArea>
        <GridArea gridArea="destDate" hidden={isMobile}>
          <Text fontSize="med" color="blue" fontWeight="heavy">
            {ActualDeliveryDate ? (
              <>Actual: {formatDatetimeString(ActualDeliveryDate)}</>
            ) : (
              <>
                Estimated:{" "}
                {EstimatedDeliveryDate
                  ? formatDatetimeString(EstimatedDeliveryDate)
                  : "–"}
              </>
            )}
          </Text>
        </GridArea>

        <GridArea gridArea="vins" justifySelf="end">
          <Box mh="sm">
            <VinsPanel>{vins}</VinsPanel>
          </Box>
        </GridArea>

        <GridArea gridArea="priority" justifySelf="end">
          <Box mr="sm" mb="sm">
            <TogglePriorityButton
              orderID={OrderId}
              priority={IsUserPriority ? "high" : "normal"}
            />
          </Box>
        </GridArea>

        <GridArea gridArea="divider" hidden={isMobile}>
          <VerticalDivider />
        </GridArea>

        <GridArea gridArea="hd" hidden={!isMobile}>
          <Box mv="sm">
            <HorizontalDivider />
          </Box>
        </GridArea>
      </Disclosure>
    </OrderCardWrapper>
  )
}

const VinsPanel = styled.div((props) => ({
  fontSize: props.theme.text.sizes.sm,
  lineHeight: props.theme.text.lineHeights.sm,
}))

const OrderCardWrapper = styled.div((props) => ({
  border: "1px solid black",
  backgroundColor: props.theme.color.background,
  display: "grid",
  gridTemplateRows: `auto auto auto auto auto`,
  gridTemplateColumns: "70px 85px 160px 160px 1px 295px 165px 150px",
  gridTemplateAreas: `
  "id mileage . . divider . . status"
  "statusBar statusBar statusBar . divider . . ."
  "origin origin origin originDate divider dest destDate vins"
  ". . . . divider .  vinsList vinsList"
  ". . . . divider .  . priority"
  `,
  rowGap: props.theme.space.xxs,

  [props.theme.mq.max.lg]: {
    width: "100%",
    gridTemplateRows: `auto auto auto auto auto auto`,
    gridTemplateColumns: "1fr 1fr 1fr 75px 75px",
    gridTemplateAreas: `
    "id . . status status"
    "statusBar statusBar . . ."
    "locations locations locations mileage mileage"
    "hd hd hd hd hd"
    ". . . vins priority"
    "vinsList vinsList vinsList vinsList vinsList"
    `,
  },
}))

const CityTitle = styled.div((props) => ({
  fontSize: props.theme.text.sizes.xl,
  fontWeight: props.theme.text.weights.med,
  whiteSpace: "nowrap",
  overflow: "hidden",
  marginRight: props.theme.space.sm,
  textOverflow: "ellipsis",
}))

const ConsignorName = styled.div((props) => ({
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "17px",
  color: "#020202",
  marginTop: props.theme.space.xxs,
}))
