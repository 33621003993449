import styled from "@emotion/styled"
import { Link, LinkProps } from "react-router-dom"
import { Color } from "../../emotion/theme"

type ButtonProps = {
  bg: Color
  color?: Color
  block?: boolean
}

export const Button = styled.button<ButtonProps>((props) => ({
  backgroundColor: !props.disabled
    ? props.theme.color[props.bg]
    : props.theme.color.lightGray,
  color: props.color ? props.theme.color[props.color] : props.theme.color.white,
  border: "none",
  textAlign: "center",
  cursor: !props.disabled ? "pointer" : "not-allowed",
  fontFamily: props.theme.text.fonts.sans,
  fontSize: props.theme.text.sizes.med,
  fontWeight: props.theme.text.weights.bold,
  paddingLeft: props.theme.space.lg,
  paddingRight: props.theme.space.lg,
  paddingTop: props.theme.space.sm,
  paddingBottom: props.theme.space.sm,
  textDecoration: "none",
  ...(props.block && { display: "block", width: "100%" }),
}))

export const ButtonLink = (props: LinkProps & ButtonProps) => (
  <Button as={Link} {...(props as unknown as any)} />
)

export const ButtonAnchor = (
  props: JSX.IntrinsicElements["a"] & ButtonProps,
) => <Button as={"a"} {...(props as unknown as any)} />
