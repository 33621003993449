export function getRequiredEnvVar(
  value: string | undefined,
  developmentValue?: string,
) {
  if (value != null) return value

  if (process.env.NODE_ENV !== "production" && developmentValue != null) {
    return developmentValue
  }

  throw new Error(`Missing a required environment variable`)
}
