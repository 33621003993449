import { useCallback, useState } from "react"
import DayPicker, { DateUtils, Modifier, RangeModifier } from "react-day-picker"
import "react-day-picker/lib/style.css"
import { formatISODate, parseISODate } from "../../utils/utils"
import { Box } from "../Box"
import { PrimaryButton } from "../Buttons/PrimaryButton"
import { SecondaryButton } from "../Buttons/SecondaryButton"
import "./DateRangeInput.css"

type DateRangeInputProps = {
  from?: string
  to?: string
  onSave: any
  disabledDays?: Modifier
}

export function DateRangeInput({
  from,
  to,
  onSave,
  disabledDays,
}: DateRangeInputProps) {
  const [range, setRange] = useState<RangeModifier>({
    from: from !== "" && from !== undefined ? parseISODate(from) : undefined,
    to: to !== "" && to !== undefined ? parseISODate(to) : undefined,
  })

  const resetFromAndTo = useCallback(() => {
    setRange({ from: undefined, to: undefined })
  }, [setRange])

  const handleDayClick = useCallback(
    (day, modifiers = {}) => {
      if (modifiers.disabled) {
        return
      }
      const rangeState: any = range.from && range.to ? {} : range
      return setRange(DateUtils.addDayToRange(day, rangeState))
    },
    [range, setRange],
  )

  const selectedDays = range.from || range.to ? range : undefined
  return (
    <>
      <DayPicker
        className="Selectable"
        modifiers={{ start: range.from as Modifier, end: range.to as Modifier }}
        selectedDays={selectedDays}
        numberOfMonths={1}
        month={range && range.from ? new Date(range.from) : undefined}
        onDayClick={handleDayClick}
        disabledDays={disabledDays}
      />
      <Box display="flex" alignItems="center" flexDirection="column">
        <PrimaryButton
          width={250}
          disabled={!range.to || !range.from}
          onClick={() =>
            onSave({
              from: formatISODate(range.from as Date),
              to: formatISODate(range.to as Date),
            })
          }
        >
          Select
        </PrimaryButton>

        <SecondaryButton onClick={resetFromAndTo}>Clear</SecondaryButton>
      </Box>
    </>
  )
}
