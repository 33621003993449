import styled from "@emotion/styled"
import { Popover } from "@headlessui/react"
import { Formik, useFormik } from "formik"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useInfiniteQuery } from "react-query"
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom"
import invariant from "tiny-invariant"
import { Box } from "../../components/Box"
import { PrimaryButton } from "../../components/Buttons/PrimaryButton"
import { SecondaryButton } from "../../components/Buttons/SecondaryButton"
import { CheckboxLine } from "../../components/CheckboxLine"
import { DesktopOrderSearchCombobox } from "../../components/DesktopOrderSearchCombobox"
import { DownloadOrderListButton } from "../../components/DownloadOrderListButton"
import { EmailOrderListButton } from "../../components/EmailOrderListButton"
import { ErrorIndicator } from "../../components/ErrorIndicator"
import { HideIfDesktop } from "../../components/HideIfDesktop"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { OrderCard } from "../../components/OrderCard/OrderCard"
import {
  getDefaultDeliveryDateRange,
  getDefaultPickupDateRange,
  OrdersListViewCreator,
} from "../../components/OrderListViewCreator/OrdersListViewCreator"
import { RadioClearButton, RadioLine } from "../../components/RadioLine"
import { ShowIfDesktop } from "../../components/ShowIfDesktop"
import { Text } from "../../components/Text"
import * as ApiV2 from "../../generated/ApiV2"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import {
  currentOrdersListPath,
  historyOrdersListPath,
  inactiveOrdersListPath,
  listSavedViewsPath,
  newOrderPath,
  viewOrderPath,
} from "../../paths"
import { ur_gray } from "../../utils/backgrounds"
import { useFlashMessageContext } from "../../utils/contexts/FlashMessageContext"
import { useIsMobile } from "../../utils/hooks/commonMediaQueryHooks"
import {
  Consignor,
  useCurrentUserConsignors,
  useCurrentUserQuery,
} from "../../utils/hooks/useCurrentUserQuery"
import {
  filterBlack,
  filterWhite,
  priority_icon_filled,
  priority_icon_white,
  saveWhite,
} from "../../utils/icons"
import {
  calculateSearchQueryForFilters,
  filterParamsToOrderListFilters,
  getDefaultFiltersForOrderList,
  getOrderStatusesForGroup,
  getSortDetails,
  getVisibleDateFilterOptions,
  ListParams,
  listSortingOptions,
  MaybeDateRangeSelectorValue,
  OrderListGroup,
  OrderOption,
  OrderStatusOption,
  QuickFiltersSpec,
  SortByOption,
  SortBySelect,
} from "../../utils/orderListFilters"
import { useRequest } from "../../utils/oviss"
import {
  ApiFlatOrder,
  ApiUser,
  OrdersListPage,
  OrderStatus,
} from "../../utils/types"

function getSelectedOrderStatuses(searchParams: URLSearchParams) {
  // Filters out the empty orderStatuses placeholder that signifies an
  // empty list
  return searchParams.getAll("orderStatuses").filter((x) => x !== "")
}

function getOrderCountFromPages(
  pages: Array<{ TotalRecordCount: number }>,
): number | null {
  return pages.length > 0 ? pages[0].TotalRecordCount : null
}

const titleMap: Record<OrderListGroup, string> = {
  current: "Current Orders",
  history: "Order History",
  inactive: "Inactive Orders",
  myOrders: "My Orders",
  inbound: "Inbound",
  outbound: "Outbound",
}

export const OrdersListScreen: React.FC<{
  orderGroup: OrderListGroup
}> = ({ orderGroup }) => {
  const userResult = useCurrentUserQuery()
  const consignorResult = useCurrentUserConsignors()

  if (
    userResult.status === "idle" ||
    userResult.status === "loading" ||
    consignorResult.loading
  ) {
    return <LoadingIndicator />
  }

  if (userResult.status === "error" || consignorResult.error) {
    return <ErrorIndicator error={userResult.error || consignorResult.error} />
  }

  invariant(consignorResult.allConsignors, `expected consignors`)

  return (
    <OrdersListScreenAuthenticated
      orderGroup={orderGroup}
      user={userResult.data}
      consignors={consignorResult.allConsignors}
    />
  )
}

export const OrdersListScreenAuthenticated: React.FC<{
  orderGroup: OrderListGroup
  user: ApiUser
  consignors: Array<Consignor>
}> = ({ orderGroup, consignors }) => {
  useDocumentTitle(titleMap[orderGroup])
  const isMobile = useIsMobile()
  const location = useLocation()
  const request = useRequest()
  const [searchParams, setSearchParams] = useSearchParams()
  const [filtersViewOpen, setFiltersViewOpen] = useState(false)

  const defaultOrderListFilters = getDefaultFiltersForOrderList({
    groupName: orderGroup,
  })

  const sortBy =
    (searchParams.get("sortBy") as SortByOption) ||
    defaultOrderListFilters.sortBy
  const order =
    (searchParams.get("order") as OrderOption) || defaultOrderListFilters.order

  const selectedOrderStatuses = searchParams.has("orderStatuses")
    ? getSelectedOrderStatuses(searchParams)
    : defaultOrderListFilters.orderStatuses

  const onlyOrdersCreatedByCurrentUser =
    searchParams.has("onlyOrdersCreatedByCurrentUser") ||
    defaultOrderListFilters.onlyOrdersCreatedByCurrentUser

  const showPriorityOnly = searchParams.get("showPriorityOnly") === "true"

  const pickUpDateRange = (searchParams.get("pickUpDateRange") ||
    getDefaultPickupDateRange(orderGroup)) as MaybeDateRangeSelectorValue

  const deliveryDateRange = (searchParams.get("deliveryDateRange") ||
    getDefaultDeliveryDateRange(orderGroup)) as MaybeDateRangeSelectorValue

  const consignor = searchParams.get("consignor") || undefined

  const listParams = filterParamsToOrderListFilters({
    params: {
      sortBy,
      order,
      showPriorityOnly,
      orderStatuses: selectedOrderStatuses,
      vins: searchParams.getAll("vins").filter((v) => v !== ""),
      orderNumbers: searchParams
        .getAll("orderNumbers")
        .filter((n) => n !== null)
        .map((n) => parseInt(n, 10)),
      pickUpDateRange: pickUpDateRange || "unselected",
      pickUpDateCustomRangeStart:
        searchParams.get("pickUpDateCustomRangeStart") || undefined,
      pickUpDateCustomRangeEnd:
        searchParams.get("pickUpDateCustomRangeEnd") || undefined,
      onlyOrdersCreatedByCurrentUser,
      deliveryDateRange: deliveryDateRange || undefined,
      deliveryDateCustomRangeStart:
        searchParams.get("deliveryDateCustomRangeStart") || undefined,
      deliveryDateCustomRangeEnd:
        searchParams.get("deliveryDateCustomRangeEnd") || undefined,
      originClientId: searchParams.get("originClientId") || undefined,
      originClientLabel: searchParams.get("originClientLabel") || undefined,
      destinationClientId: searchParams.get("destinationClientId") || undefined,
      destinationClientLabel:
        searchParams.get("destinationClientLabel") || undefined,
      consignor,
    },
    consignors,
    orderGroup,
  })

  const fetchOrders = ({ pageParam = 1 }) => {
    // @ts-expect-error problem with api sortfield
    const params: Partial<
      ApiV2.definitions["VTVSolutions.Vlms.Web.UI.Controllers.Api.V2.Orders.Models.ListOrdersCriteriaRequest"]
    > = {
      ...listParams,
      PageSize: 50,
      PageNumber: pageParam,
    }
    return request({
      queryKey: [
        "orders",
        {
          path: "/api/CustomerPortalOrders/ListOrders",
          method: "POST",
          body: params,
        },
      ],
    })
  }

  const { data, status, error, fetchNextPage, isFetchingNextPage } =
    useInfiniteQuery(["orders", listParams], fetchOrders, {
      getNextPageParam: (lastPage, _pages) => lastPage.PageNumber + 1,
      refetchOnWindowFocus: false,
    })
  // If our location has changed, close the filters menu. We want the
  // user to see the orders list rather than the view creator if they
  // use browser button navigation while the view creator is open
  useEffect(() => setFiltersViewOpen(false), [location])

  const numberOfOrders = useMemo(
    () => (data ? getOrderCountFromPages(data.pages) : null),
    [data],
  )

  const [numberOfStatuses, numberOfAppliedFilters] = useMemo(() => {
    const appliedStatuses = selectedOrderStatuses.length
    const appliedFilters = [
      "vins",
      "orderNumbers",
      "pickUpDateRange",
      "deliveryDateRange",
    ]
      .map((key) => searchParams.get(key))
      .filter((v) => v !== null && v !== "").length
    return [appliedStatuses, appliedFilters]
  }, [searchParams, selectedOrderStatuses])

  const onFiltersSave = useCallback(
    (values: any, update = false) => {
      const newSearchParams = calculateSearchQueryForFilters({
        searchParams,
        values,
        update,
      })

      // Add a new entry to history and close the filters view. The desired behavior
      // is for the back button to take us to the list view with the previous
      // filter set applied
      setSearchParams(newSearchParams)
      setFiltersViewOpen(false)
    },
    [searchParams, setSearchParams, setFiltersViewOpen],
  )

  const onFiltersClear = () => {
    setSearchParams("")
  }

  // Calculate if there's another page of results to load
  const areMoreResults =
    data &&
    data.pages[data.pages.length - 1] &&
    data.pages[data.pages.length - 1].TotalRecordCount >
      data.pages[data.pages.length - 1].StartIndex +
        data.pages[data.pages.length - 1].PageSize

  const showLoadMore = status === "success" && areMoreResults
  const listViewCreator = (
    <OrdersListViewCreator
      initialValues={{
        sortBy,
        order,
        showPriorityOnly,
        orderStatuses: selectedOrderStatuses,
        vins: searchParams.getAll("vins").join(", "),
        orderNumbers: searchParams.getAll("orderNumbers").join(", "),
        pickUpDateRange: pickUpDateRange || "unselected",
        pickUpDateCustomRangeStart:
          searchParams.get("pickUpDateCustomRangeStart") || undefined,
        pickUpDateCustomRangeEnd:
          searchParams.get("pickUpDateCustomRangeEnd") || undefined,
        deliveryDateRange: deliveryDateRange || "unselected",
        deliveryDateCustomRangeStart:
          searchParams.get("deliveryDateCustomRangeStart") || undefined,
        deliveryDateCustomRangeEnd:
          searchParams.get("deliveryDateCustomRangeEnd") || undefined,
        originClientId:
          searchParams.get("originClientId") ||
          defaultOrderListFilters.originClientId ||
          "",
        originClientLabel:
          searchParams.get("originClientLabel") ||
          defaultOrderListFilters.originClientLabel ||
          "",
        destinationClientId:
          searchParams.get("destinationClientId") ||
          defaultOrderListFilters.destinationClientId ||
          "",
        destinationClientLabel:
          searchParams.get("destinationClientLabel") ||
          defaultOrderListFilters.destinationClientLabel ||
          "",
        onlyOrdersCreatedByCurrentUser,
        consignor,
      }}
      selectableOrderStatuses={getOrderStatusesForGroup(orderGroup)}
      onClose={() => setFiltersViewOpen(false)}
      onSave={onFiltersSave}
      onClear={onFiltersClear}
      orderGroup={orderGroup}
    />
  )

  if (filtersViewOpen && isMobile) {
    return listViewCreator
  } else {
    return (
      <OrderScreenWrapper padTop={!isMobile}>
        <ShowIfDesktop>
          <Box>{listViewCreator}</Box>
        </ShowIfDesktop>
        <HideIfDesktop>
          <OrdersListNav />
        </HideIfDesktop>
        <OrderListInnerWrapper>
          <OrderListFilters
            selectedSortOption={sortBy}
            selectedOrderOption={order}
            selectableOrderStatuses={getOrderStatusesForGroup(orderGroup)}
            selectedOrderStatuses={selectedOrderStatuses}
            showPriorityOnly={showPriorityOnly}
            pickUpDateRange={pickUpDateRange}
            deliveryDateRange={deliveryDateRange}
            numberOfOrders={numberOfOrders}
            numberOfAppliedFilters={numberOfAppliedFilters}
            numberOfStatuses={numberOfStatuses}
            listParams={listParams}
            onMenuOpened={() => setFiltersViewOpen(true)}
            onSave={(values) => onFiltersSave(values, true)}
            orderGroup={orderGroup}
          />
          <Box style={{ marginTop: 50 }}>
            <OrderListGrid>
              {status === "loading" ? (
                <LoadingIndicator colorScheme="white" />
              ) : status === "error" ? (
                <ErrorIndicator error={error} />
              ) : (
                status === "success" &&
                data &&
                data.pages &&
                (numberOfOrders != null && numberOfOrders > 0 ? (
                  <OrderList
                    pages={data.pages}
                    showLoadMore={showLoadMore}
                    isFetchingNextPage={isFetchingNextPage}
                    fetchNextPage={fetchNextPage}
                  />
                ) : (
                  <EmptyListIndicator />
                ))
              )}
            </OrderListGrid>
          </Box>
        </OrderListInnerWrapper>
      </OrderScreenWrapper>
    )
  }
}

function OrderList({
  pages,
  showLoadMore,
  isFetchingNextPage,
  fetchNextPage,
}: {
  pages: OrdersListPage[]
  showLoadMore: boolean
  isFetchingNextPage: boolean
  fetchNextPage: () => void
}) {
  return (
    <>
      <ShowIfDesktop>
        <OrderListTitles>
          <Text color="white" fontSize="xxs" fontWeight="bold">
            PICK UP
          </Text>
          <Text color="white" fontSize="xxs" fontWeight="bold">
            DELIVERY
          </Text>
        </OrderListTitles>
      </ShowIfDesktop>
      <div
        style={{
          height: `calc(100vh - 296px)`,
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "inline-block",
          }}
        >
          {pages.map(({ Records }) => {
            return Records.map((flatOrder: ApiFlatOrder) => (
              <CardNavLink
                to={viewOrderPath(flatOrder.OrderId.toString())}
                key={flatOrder.OrderId}
              >
                <OrderCard flatOrder={flatOrder} />
              </CardNavLink>
            ))
          })}

          {showLoadMore && (
            <LoadMoreWrapper>
              {!isFetchingNextPage ? (
                <PrimaryButton onClick={() => fetchNextPage()}>
                  Load More
                </PrimaryButton>
              ) : (
                <LoadingIndicator />
              )}
            </LoadMoreWrapper>
          )}
        </div>
      </div>
    </>
  )
}

const OrderListTitles = styled.div({
  display: "grid",
  // Caclulated based on order card template columns
  gridTemplateColumns: "476px auto",
})

const CardNavLink = styled(NavLink)({
  "display": "flex",
  "textDecoration": "none",
  "color": "inherit",
  "&:visited": {
    color: "inherit",
  },
  "&:active": {
    color: "inherit",
  },
})

const LoadMoreWrapper = styled.div((props) => ({
  marginTop: props.theme.space.sm,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 90,
  paddingRight: "14%",
}))

const OrderListInnerWrapper = styled.div((props) => ({
  padding: props.theme.space.sm,
}))

const OrderListGrid = styled.div((props) => ({
  marginTop: props.theme.space.lg,
  display: "grid",
  gap: props.theme.space.sm,
  gridAutoFlow: "row",
}))

const OrderScreenWrapper = styled.div<{ padTop?: boolean }>((props) => ({
  paddingTop: props.padTop ? props.theme.space.sm : undefined,
  background: `url(${ur_gray}) black`,
  flexGrow: 1,

  [props.theme.mq.min.lg]: {
    display: "grid",
    gridTemplateColumns: "300px auto",
    columnGap: props.theme.space.sm,
  },
}))

const StyledNavLink = styled(NavLink)<{
  selected: boolean
}>((props) => ({
  color: props.selected ? props.theme.color.blue : props.theme.color.darkGray,
  fontWeight: props.selected
    ? props.theme.text.weights.bold
    : props.theme.text.weights.reg,
  fontSize: props.theme.text.sizes.lg,
  textDecoration: props.selected ? "underline" : "none",
  textDecorationThickness: 3,
  textDecorationSkipInk: "none",
}))

const OrdersListNavWrapper = styled.div((props) => ({
  backgroundColor: props.theme.color.white,
  padding: `${props.theme.space.sm}px 0`,
}))

const NavLinkWrapper = styled.div((props) => ({
  display: "flex",
  width: "100%",
  alignItems: "center",
  justifyContent: "space-between",
  padding: `0 ${props.theme.space.sm}px`,
}))

function OrdersListNav() {
  const location = useLocation()

  return (
    <OrdersListNavWrapper>
      <NavLinkWrapper>
        <StyledNavLink
          to={currentOrdersListPath()}
          selected={location.pathname === currentOrdersListPath()}
        >
          Current
        </StyledNavLink>
        <StyledNavLink
          to={historyOrdersListPath()}
          selected={location.pathname === historyOrdersListPath()}
        >
          History
        </StyledNavLink>
        <StyledNavLink
          to={inactiveOrdersListPath()}
          selected={location.pathname === inactiveOrdersListPath()}
        >
          Inactive
        </StyledNavLink>
      </NavLinkWrapper>
    </OrdersListNavWrapper>
  )
}

type OrderListFiltersProps = {
  selectedSortOption: SortByOption
  selectedOrderOption: OrderOption
  selectableOrderStatuses: OrderStatusOption[]
  selectedOrderStatuses: OrderStatus[]
  showPriorityOnly: boolean
  pickUpDateRange: MaybeDateRangeSelectorValue
  deliveryDateRange: MaybeDateRangeSelectorValue
  numberOfOrders: number | null
  numberOfAppliedFilters: number
  numberOfStatuses: number
  listParams: ListParams
  onMenuOpened: () => void
  onSave: (values: QuickFiltersSpec) => void
  orderGroup: OrderListGroup
}

const StyledFiltersWrapperContainer = styled(Box)({
  // position: "fixed",
  // maxWidth: "100%",
  // width: "calc(100% - 437px)",
  // minWidth: 1030,
  // backgroundColor: "#000000",
})

function OrderListFilters({
  selectedSortOption,
  selectedOrderOption,
  selectableOrderStatuses,
  selectedOrderStatuses,
  showPriorityOnly,
  pickUpDateRange,
  deliveryDateRange,
  numberOfOrders,
  numberOfAppliedFilters,
  numberOfStatuses,
  listParams,
  onMenuOpened,
  onSave,
  orderGroup,
}: OrderListFiltersProps) {
  const formik = useFormik<{
    sortBy: SortByOption
    order: OrderOption
    showPriorityOnly: boolean
    orderStatuses: string[]
  }>({
    enableReinitialize: true,
    initialValues: {
      sortBy: selectedSortOption,
      order: selectedOrderOption,
      showPriorityOnly,
      orderStatuses: selectedOrderStatuses,
    },
    onSubmit: onSave,
  })
  const navigate = useNavigate()
  const flashMessageContext = useFlashMessageContext()
  const visibleDateFilterOptions = getVisibleDateFilterOptions(orderGroup)
  const currentUserResult = useCurrentUserQuery()
  const isCreateOrderEnabled =
    (currentUserResult.data &&
      currentUserResult.data.Roles.includes("OrderEntry")) ||
    process.env.REACT_APP_RESTRICT_ORDER_ENTRY === "anyone"

  return (
    <StyledFiltersWrapperContainer>
      <OrderListFiltersWrapper>
        <ShowIfDesktop>
          <Box
            p="xs"
            bg="white"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Box flex={1} maxWidth="600px">
              <DesktopOrderSearchCombobox />
            </Box>
            <Box display="flex" alignItems="flex-end">
              <Box
                mv="xs"
                ml="sm"
                flex={1}
                minWidth="220px"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Text color="black" fontWeight="heavy" fontSize="xxs">
                    SORT BY
                  </Text>
                </Box>
                <Box mv="xs" ml="sm" flex={1}>
                  <SortBySelect
                    name="sortBy"
                    id="sortBy"
                    color="black"
                    background="white"
                    value={formik.values.sortBy}
                    onChange={(e) => {
                      const orderForSort = getSortDetails(
                        e.target.value as SortByOption,
                      ).SortAscending
                        ? "asc"
                        : "desc"
                      formik.setFieldValue("order", orderForSort)
                      formik.handleChange(e)
                      formik.submitForm()
                    }}
                  >
                    {listSortingOptions.map(({ displayName, value }) => (
                      <option key={value} value={value}>
                        {displayName}
                      </option>
                    ))}
                  </SortBySelect>
                </Box>
              </Box>
              <Box
                mv="xs"
                ml="sm"
                flex={1}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Box>
                  <Text color="black" fontWeight="heavy" fontSize="xxs">
                    ORDER
                  </Text>
                </Box>
                <Box mv="xs" ml="sm" flex={1}>
                  <SortBySelect
                    name="order"
                    id="order"
                    color="black"
                    background="white"
                    width="120px"
                    value={formik.values.order}
                    onChange={(e) => {
                      formik.handleChange(e)
                      formik.submitForm()
                    }}
                  >
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                  </SortBySelect>
                </Box>
              </Box>
            </Box>
          </Box>
        </ShowIfDesktop>

        <OrderListFiltersRowWrapper>
          <Box pv="xs">
            <Text fontSize="lg" fontWeight="med">
              {numberOfOrders != null ? (
                <>
                  {numberOfOrders.toLocaleString()}{" "}
                  {numberOfOrders === 1 ? `Order` : `Orders`}
                </>
              ) : null}
            </Text>
          </Box>
          <Box display="flex">
            <IconsWrapper>
              <StyledSecondaryButton
                onClick={() => navigate(listSavedViewsPath())}
                title="Custom Views"
              >
                <img
                  src={saveWhite}
                  width="24"
                  height="24"
                  alt="Custom Views"
                />
              </StyledSecondaryButton>
              <StyledSecondaryButton
                onClick={() => {
                  formik.setFieldValue(
                    "showPriorityOnly",
                    !formik.values.showPriorityOnly,
                  )
                  formik.submitForm()
                }}
                title="Favorite Orders"
              >
                <img
                  width={24}
                  height={24}
                  src={
                    showPriorityOnly
                      ? priority_icon_filled
                      : priority_icon_white
                  }
                  alt={
                    showPriorityOnly
                      ? "showing favorite orders"
                      : "showing all orders"
                  }
                />
              </StyledSecondaryButton>
              <EmailOrderListButton listParams={listParams} />
              <DownloadOrderListButton listParams={listParams} />
            </IconsWrapper>
            {isCreateOrderEnabled && (
              <Box ml="sm" display="flex">
                <CreateOrderLink to={newOrderPath()}>
                  Create Quote
                </CreateOrderLink>
              </Box>
            )}
          </Box>
        </OrderListFiltersRowWrapper>

        {flashMessageContext.message && (
          <Box mt="md">{flashMessageContext.message}</Box>
        )}

        <HideIfDesktop>
          <Text fontSize="xxs">
            <BottomOrderListFiltersRowWrapper>
              <FilterPillWrapper
                onClick={() => onMenuOpened()}
                colorTheme={numberOfAppliedFilters > 0 ? "dark" : "light"}
              >
                <img
                  src={numberOfAppliedFilters > 0 ? filterBlack : filterWhite}
                  height="22"
                  alt="filters"
                />
                {numberOfAppliedFilters > 0 && ` (${numberOfAppliedFilters})`}
              </FilterPillWrapper>
              <Formik
                enableReinitialize={true}
                initialValues={{
                  orderStatuses: selectedOrderStatuses,
                  pickUpDateRange,
                  deliveryDateRange,
                }}
                onSubmit={onSave}
              >
                {({ values, setValues, submitForm }) => (
                  <>
                    <Popover>
                      <Popover.Button
                        as={FilterPillWrapperArrow}
                        colorTheme={
                          numberOfStatuses !== selectableOrderStatuses.length
                            ? "dark"
                            : "light"
                        }
                      >
                        Status
                        {numberOfStatuses !== selectableOrderStatuses.length &&
                          ` (${numberOfStatuses})`}
                      </Popover.Button>
                      <StyledPopoverPanel>
                        {selectableOrderStatuses.map(
                          ({ displayName, value }) => (
                            <CheckboxLine
                              key={value}
                              name="orderStatuses"
                              displayName={displayName}
                              value={value}
                              onClick={submitForm}
                            />
                          ),
                        )}
                      </StyledPopoverPanel>
                    </Popover>
                    <Popover>
                      <Popover.Button
                        as={FilterPillWrapperArrow}
                        colorTheme={pickUpDateRange ? "dark" : "light"}
                      >
                        Pick Up Date
                      </Popover.Button>
                      <StyledPopoverPanel>
                        {visibleDateFilterOptions.map(
                          ({ displayName, value }) => (
                            <RadioLine
                              key={value}
                              name="pickUpDateRange"
                              displayName={displayName}
                              value={value}
                              checked={values.pickUpDateRange === value}
                              onClick={submitForm}
                            />
                          ),
                        )}
                        <RadioClearButton
                          displayName="Clear"
                          onClick={() => {
                            setValues({
                              ...values,
                              pickUpDateRange: "unselected",
                              pickUpDateCustomRangeStart: "",
                              pickUpDateCustomRangeEnd: "",
                            })
                            submitForm()
                          }}
                        />
                      </StyledPopoverPanel>
                    </Popover>
                    <Popover>
                      <Popover.Button
                        as={FilterPillWrapperArrow}
                        colorTheme={deliveryDateRange ? "dark" : "light"}
                      >
                        Delivery Date
                      </Popover.Button>
                      <StyledPopoverPanel>
                        {visibleDateFilterOptions.map(
                          ({ displayName, value }) => (
                            <RadioLine
                              key={value}
                              name="deliveryDateRange"
                              displayName={displayName}
                              value={value}
                              checked={value === values.deliveryDateRange}
                              onClick={submitForm}
                            />
                          ),
                        )}
                        <RadioClearButton
                          displayName="Clear"
                          onClick={() => {
                            setValues({
                              ...values,
                              deliveryDateRange: "unselected",
                              deliveryDateCustomRangeStart: "",
                              deliveryDateCustomRangeEnd: "",
                            })
                            submitForm()
                          }}
                        />
                      </StyledPopoverPanel>
                    </Popover>
                  </>
                )}
              </Formik>
            </BottomOrderListFiltersRowWrapper>
          </Text>
        </HideIfDesktop>
      </OrderListFiltersWrapper>
    </StyledFiltersWrapperContainer>
  )
}

const OrderListFiltersWrapper = styled.div((props) => ({
  color: props.theme.color.white,
}))

const OrderListFiltersRowWrapper = styled.div({
  display: "flex",
  justifyContent: "space-between",
})

const BottomOrderListFiltersRowWrapper = styled(OrderListFiltersRowWrapper)(
  (props) => ({
    display: "flex",
    justifyContent: "space-between",
    marginTop: props.theme.space.lg,
  }),
)

const IconsWrapper = styled.div((props) => ({
  fill: "white",
  stroke: "white",
  display: "grid",
  gridAutoFlow: "column",
  gap: props.theme.space.xs,
  color: "white",
  alignItems: "start",
  paddingTop: props.theme.space.xs,
  paddingBottom: props.theme.space.xs,
}))

// Default theme is "dark"
const FilterPillWrapper = styled.button<{
  colorTheme?: "light" | "dark"
}>((props) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid white",
  borderRadius: 50,
  position: "relative",
  borderColor: props.theme.color.white,
  color:
    props.colorTheme === "light"
      ? props.theme.color.white
      : props.theme.color.black,
  backgroundColor:
    props.colorTheme === "light" ? "unset" : props.theme.color.white,
  cursor: "pointer",
  paddingRight:
    props.colorTheme === "light" ? props.theme.space.xs : props.theme.space.xxs,
  paddingLeft:
    props.colorTheme === "light" ? props.theme.space.xs : props.theme.space.xxs,
}))

// Default theme is "dark"
const FilterPillWrapperArrow = styled(FilterPillWrapper)<{
  colorTheme?: "light" | "dark"
}>((props) => ({
  "paddingTop": props.theme.space.xxs,
  "paddingBottom": props.theme.space.xxs,
  "&:after": {
    content: "''",
    width: 0,
    height: 0,
    marginLeft: props.theme.space.xxs,
    position: "relative",
    top: 1,
    borderLeft: "3px solid transparent",
    borderRight: "3px solid transparent",
    borderTop: "5px solid white",
    borderTopColor:
      props.colorTheme === "light"
        ? props.theme.color.white
        : props.theme.color.black,
    color:
      props.colorTheme === "light"
        ? props.theme.color.white
        : props.theme.color.black,
  },
}))

const StyledPopoverPanel = styled(Popover.Panel)((props) => ({
  position: "absolute",
  padding: props.theme.space.sm,
  backgroundColor: "white",
  color: "black",
  border: "1px solid black",
}))

const StyledSecondaryButton = styled(SecondaryButton)({
  padding: 0,
})

function EmptyListIndicator() {
  return (
    <Text color="white">
      <Box
        mt="xxl"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        textAlign="center"
      >
        <Box mb="md">
          <Text fontSize="xxl" fontWeight="bold">
            No Results
          </Text>
        </Box>
        <Text fontSize="xl">Sorry, there were no results.</Text>
        <Text fontSize="xl">Please adjust your filters and try again.</Text>
      </Box>
    </Text>
  )
}

const CreateOrderLink = styled(Link)((props) => ({
  backgroundColor: props.theme.color.green,
  color: props.theme.color.white,
  fontFamily: props.theme.text.fonts.sans,
  fontWeight: props.theme.text.weights.med,
  border: 0,
  paddingLeft: props.theme.space.md,
  paddingRight: props.theme.space.md,
  display: "flex",
  paddingTop: `10px`, // alignItems center would make more sense but this font is weird
  textDecoration: `none`,
}))
