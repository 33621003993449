import styled from "@emotion/styled"
import { Disclosure } from "@headlessui/react"
import "@reach/dialog/styles.css"
import { useFormik } from "formik"
import { useEffect, useRef, useState } from "react"
import { useMutation } from "react-query"
import { useFlashMessageContext } from "../utils/contexts/FlashMessageContext"
import { useIsMobile } from "../utils/hooks/commonMediaQueryHooks"
import { Consignor } from "../utils/hooks/useCurrentUserQuery"
import { saveBlack } from "../utils/icons"
import {
  filterParamsToOrderListFilters,
  OrderListGroup,
  ParsedFiltersSpec,
} from "../utils/orderListFilters"
import { useMutationRequest } from "../utils/oviss"
import { Box } from "./Box"
import { PrimaryButton } from "./Buttons/PrimaryButton"
import { SecondaryButtonInline } from "./Buttons/SecondaryButtonInline"
import { Dialog } from "./Dialog"
import { ErrorIndicator } from "./ErrorIndicator"
import { FormSuccessIndicator } from "./FormSuccessIndicator"
import { Text } from "./Text"

type SaveViewButtonProps = {
  viewParams: ParsedFiltersSpec
  onSave: () => void
  consignors: Array<Consignor>
  orderGroup: OrderListGroup
}

export function SaveViewButton({
  viewParams,
  onSave,
  consignors,
  orderGroup,
}: SaveViewButtonProps) {
  const isMobile = useIsMobile()
  const customViewPanelRef = useRef<HTMLDivElement>(null)
  const flashMessage = useFlashMessageContext()
  const [showDialog, setShowDialog] = useState(false)
  const open = () => setShowDialog(true)
  const close = () => setShowDialog(false)

  const mutationRequest = useMutationRequest()

  const { mutateAsync, error } = useMutation(
    (name: string) => {
      const criteria = filterParamsToOrderListFilters({
        params: viewParams,
        consignors,
        orderGroup,
      })

      return mutationRequest({
        path: "/api/CustomerPortalSearchHistory/SaveSearchHistory",
        method: "POST",
        body: {
          Name: name,
          CustomerPortalSavedSearchDto: criteria,
        },
      })
    },
    {
      onSuccess: async () => {
        flashMessage.setMessage(<FormSuccessIndicator message="View Saved!" />)
        close()
        onSave()
      },
    },
    // No need for an error handler. We're using the error value provided by useMutation
  )

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: ({ name }) => mutateAsync(name),
  })

  useEffect(() => {
    if (showDialog && !isMobile) {
      customViewPanelRef.current?.scrollIntoView({ behavior: "smooth" })
    }
  }, [isMobile, showDialog])

  return (
    <Box>
      <StyledSecondaryButton onClick={open}>
        <Box display="flex" alignItems="center">
          <Box mr="xs">
            <img src={saveBlack} alt="save view" />
          </Box>
          Save Custom View
        </Box>
      </StyledSecondaryButton>

      <Dialog
        show={showDialog && isMobile}
        onClose={close}
        ariaLabel="enter view name"
        body={
          <>
            <Text fontSize="xxl" fontWeight="med">
              Name Your Custom View
            </Text>

            <form onSubmit={formik.handleSubmit}>
              <div style={{ width: "100%" }}>
                {error ? (
                  <Box mv="sm">
                    <ErrorIndicator error={error} />
                  </Box>
                ) : null}
              </div>

              <Box mv="lg">
                <StyledInput
                  type="text"
                  name="name"
                  id="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Box>
            </form>
          </>
        }
        confirmButton={
          <PrimaryButton type="button" onClick={formik.submitForm} width="auto">
            SAVE
          </PrimaryButton>
        }
      />

      <Disclosure>
        {showDialog && !isMobile && (
          <Disclosure.Panel static ref={customViewPanelRef}>
            <Box mt="lg" textAlign="center">
              <Text fontSize="xl" fontWeight="bold">
                Name Your Custom View
              </Text>
              <div style={{ width: "100%" }}>
                {error ? (
                  <Box mv="sm">
                    <ErrorIndicator error={error} />
                  </Box>
                ) : null}
              </div>
              <Box
                display="grid"
                mt="md"
                gridAutoFlow="row"
                gridTemplateColumns="2fr 1fr"
                alignItems="center"
                justifyContent="space-between"
              >
                <StyledInput
                  type="text"
                  name="name"
                  id="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <Box ml="sm">
                  <PrimaryButton
                    width="100%"
                    type="button"
                    onClick={formik.submitForm}
                  >
                    Save
                  </PrimaryButton>
                </Box>
                <Box textAlign="left" mt="sm">
                  <SecondaryButtonInline onClick={close}>
                    Cancel
                  </SecondaryButtonInline>
                </Box>
              </Box>
            </Box>
          </Disclosure.Panel>
        )}
      </Disclosure>
    </Box>
  )
}

const StyledSecondaryButton = styled(SecondaryButtonInline)({
  padding: 0,
  textDecoration: "none",
})

const StyledInput = styled.input((props) => ({
  margin: 0,
  padding: 0,
  color: props.theme.color.darkGray,
  fontSize: props.theme.text.sizes.xl,
  fontFamily: "'Barlow', sans-serif",
  width: "100%",
  height: "100%",
}))
