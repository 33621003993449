import styled from "@emotion/styled"
import { Color, Space, Theme } from "../emotion/theme"
import * as css from "csstype"

/**
 * Generic layout element with convenient props for padding. add more props as needed!
 *
 * @see https://chakra-ui.com/docs/layout/box
 */
export const Box = styled.div<{
  background?: css.Properties["background"]
  color?: css.Properties["color"]
  backgroundPosition?: css.Properties["backgroundPosition"]
  backgroundSize?: css.Properties["backgroundSize"]
  position?: css.Properties["position"]
  textAlign?: css.Properties["textAlign"]
  maxWidth?: css.Properties["maxWidth"]
  minWidth?: css.Properties["minWidth"]
  maxHeight?: css.Properties["maxHeight"]
  minHeight?: css.Properties["minHeight"]
  width?: css.Properties["width"]
  height?: css.Properties["height"]
  backgroundColor?: css.Properties["backgroundColor"]
  display?: "flex" | "grid" | "inline-block" | "none" | "unset"
  borderRadius?: css.Properties["borderRadius"]
  gridTemplateAreas?: css.Properties["gridTemplateAreas"]
  rowGap?: "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl"
  columnGap?: "xxs" | "xs" | "sm" | "md" | "lg" | "xl" | "xxl"
  gridAutoFlow?: "row" | "column"
  gridTemplateColumns?: css.Properties["gridTemplateColumns"]
  gridTemplateRows?: css.Properties["gridTemplateRows"]
  justifyItems?: "normal" | "stretch" | "left" | "right" | "center"
  justifyContent?:
    | "center"
    | "space-between"
    | "space-around"
    | "flex-start"
    | "flex-end"
  alignItems?: "center" | "baseline" | "stretch" | "flex-start" | "flex-end"
  alignSelf?: css.Properties["alignSelf"]
  flexDirection?: "row" | "column"
  flex?: number | "auto"
  margin?: css.Properties["margin"]
  m?: Space
  mb?: Space
  ml?: Space
  mr?: Space
  mt?: Space
  mh?: Space
  mv?: Space
  p?: Space
  pb?: Space
  pl?: Space
  pr?: Space
  pt?: Space
  ph?: Space
  pv?: Space
  bg?: Color
  gridColumns?: number
  gap?: css.Properties["gap"]
}>((props) => ({
  textAlign: props.textAlign,
  maxWidth: props.maxWidth,
  minWidth: props.minWidth,
  maxHeight: props.maxHeight,
  minHeight: props.minHeight,
  position: props.position,
  width: props.width,
  height: props.height,
  display: props.display,
  borderRadius: props.borderRadius,
  gridTemplateAreas: props.gridTemplateAreas,
  gap: props.gap,
  rowGap: props.rowGap ? props.theme.space[props.rowGap] : undefined,
  columnGap: props.columnGap ? props.theme.space[props.columnGap] : undefined,
  gridAutoFlow: props.gridAutoFlow,
  gridTemplateColumns: props.gridTemplateColumns,
  gridTemplateRows: props.gridTemplateRows,
  justifyItems: props.justifyItems,
  justifyContent: props.justifyContent,
  alignItems: props.alignItems,
  flexDirection: props.flexDirection,
  flex: props.flex,
  margin: props.margin ? props.margin : getSpace(props.theme, props.m),
  marginLeft: getSpace(props.theme, props.ml ?? props.mh),
  marginRight: getSpace(props.theme, props.mr ?? props.mh),
  marginTop: getSpace(props.theme, props.mt ?? props.mv),
  marginBottom: getSpace(props.theme, props.mb ?? props.mv),
  padding: getSpace(props.theme, props.p),
  paddingLeft: getSpace(props.theme, props.pl ?? props.ph),
  paddingRight: getSpace(props.theme, props.pr ?? props.ph),
  paddingTop: getSpace(props.theme, props.pt ?? props.pv),
  paddingBottom: getSpace(props.theme, props.pb ?? props.pv),
  background: props.background,
  color: props.color,
  backgroundPosition: props.backgroundPosition,
  backgroundSize: props.backgroundSize,
  alignSelf: props.alignSelf,
  ...(props.bg != null && { backgroundColor: props.theme.color[props.bg] }),
  ...(props.gridColumns && {
    gridTemplateColumns: `repeat(${props.gridColumns}, minmax(0, 1fr))`,
  }),
}))

function getSpace(theme: Theme, value: Space | undefined) {
  return value != null ? theme.space[value] : undefined
}
