import styled from "@emotion/styled"
import oval_dark from "./oval_dark.svg"
import oval_light from "./oval_light.svg"

type LoadingIndicatorColor = "white" | "black"

export function LoadingIndicator({
  colorScheme = "white",
  message = "Loading...",
  size = 40,
}: {
  colorScheme?: LoadingIndicatorColor
  message?: string
  size?: number
}) {
  return (
    <LoadingIndicatorWrapper colorScheme={colorScheme}>
      <LoadingIndicatorImage size={size} colorScheme={colorScheme} />
      {message}
    </LoadingIndicatorWrapper>
  )
}

export const LoadingIndicatorImage = ({
  size,
  colorScheme,
  ...rest
}: {
  colorScheme?: LoadingIndicatorColor
  size?: number
  style?: React.CSSProperties | undefined
  className?: string
}) => (
  <img
    width={size}
    height={size}
    alt="loading"
    src={colorScheme === "black" ? oval_dark : oval_light}
    {...rest}
  />
)

const LoadingIndicatorWrapper = styled.div<{
  colorScheme?: LoadingIndicatorColor
}>((props) => ({
  display: "flex",
  flexDirection: "column",
  textAlign: "center",
  justifyContent: "center",
  alignItems: "center",
  color:
    props.colorScheme === "black"
      ? props.theme.color.black
      : props.theme.color.white,
  padding: props.theme.space.md,
}))
