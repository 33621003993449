import styled from "@emotion/styled"
import { CSSProperties } from "react"
import { Link } from "react-router-dom"
import invariant from "tiny-invariant"
import { Box } from "../../components/Box"
import { ButtonAnchor, ButtonLink } from "../../components/Buttons/Button"
import { ResultName } from "../../components/ClientAutocompleteInput"
import { CustomerCareLink } from "../../components/CustomerCareLink"
import { EmailOrderListButton } from "../../components/EmailOrderListButton"
import { Text } from "../../components/Text"
import { Theme } from "../../emotion/theme"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import { myOrdersListPath, newOrderPath, viewOrderPath } from "../../paths"
import { formatUsd } from "../../utils/currency"
import { isNotNull } from "../../utils/filter"
import {
  Container,
  Title,
  VehicleBasics,
  VehicleContainer,
} from "./NewOrderScreenComponents"
import { Fields, OrderResponse, PricingResponse } from "./types"

export const OrderCreated = ({
  pricing,
  fields,
  orderResponse,
}: {
  pricing: PricingResponse
  fields: Fields
  orderResponse: OrderResponse
}) => {
  useDocumentTitle(`Order created`)
  const price = parseInt(pricing.optimal_price, 10)

  invariant(fields.originName, `expected origin name`)
  invariant(fields.destinationName, `expected destination name`)

  return (
    <Container>
      <Box p="lg" bg="white" maxWidth="1024px" width="100%">
        <Box mb="md">
          <Title mb="0">Order Confirmation</Title>
          <Text color="darkGray">
            Please call customer support at <CustomerCareLink /> to modify or
            cancel this order.
          </Text>
        </Box>
        <Table>
          <tbody>
            <tr>
              <TdLabel>Total Load Price:</TdLabel>
              <TdValue>{formatUsd(price)}</TdValue>
            </tr>
            <tr>
              <TdLabel>Origin</TdLabel>

              <TdValueNotBold>
                <ResultName name={fields.originName} />
              </TdValueNotBold>
            </tr>
            <tr>
              <TdLabel>Destination</TdLabel>
              <TdValueNotBold>
                <ResultName name={fields.destinationName} />
              </TdValueNotBold>
            </tr>
            <tr>
              <TdLabel>Vehicle Count</TdLabel>
              <TdValue>{fields.vehicles.length}</TdValue>
            </tr>
          </tbody>
        </Table>

        {fields.vehicles.map(({ vehicle }) => {
          const order = orderResponse.find((o) => o.Vin === vehicle.Vin)
          return (
            <VehicleContainer key={vehicle.Vin}>
              <VehicleBasics vehicle={vehicle} />
              <LinkBox>
                {order && order.OrderId != null ? (
                  <Link
                    to={viewOrderPath(order.OrderId.toString())}
                    target="_blank"
                  >
                    Order #{order.OrderId}
                  </Link>
                ) : (
                  <div>⚠️ Missing order for vehicle</div>
                )}
              </LinkBox>
            </VehicleContainer>
          )
        })}
        <ControlContainer>
          <ButtonAnchor href={newOrderPath()} bg="green">
            Add New Order
          </ButtonAnchor>
          <ButtonLink to={myOrdersListPath()} bg="darkGray">
            Go home
          </ButtonLink>
          <EmailOrderListButton
            iconSize={18}
            colorTheme="dark"
            listParams={{
              OrderIds: orderResponse
                .filter((o) => o.OrderType !== "LP")
                .map((o) => o.OrderId)
                .filter(isNotNull),
            }}
          >
            <Text fontWeight="bold" fontSize="med" color="darkGray">
              Email confirmation
            </Text>
          </EmailOrderListButton>
        </ControlContainer>
      </Box>
    </Container>
  )
}

const ControlContainer = styled.div((props) => ({
  flex: 1,
  display: "grid",
  paddingTop: props.theme.space.lg,
  gap: `10px`,
  gridTemplateColumns: `repeat(1, minmax(0, 1fr))`,
  [props.theme.mq.min.lg]: {
    gridTemplateColumns: `repeat(3, minmax(0, 1fr))`,
  },
}))

const Table = styled.table({
  borderCollapse: `collapse`,
  width: `100%`,
  tableLayout: `fixed`,
})

const tdShared = (theme: Theme): CSSProperties => ({
  borderColor: theme.color.lightGray,
  borderBottomWidth: `1px`,
  borderBottomStyle: `solid`,
  fontSize: `21px`,
  lineHeight: `30px`,
  paddingBottom: `10px`,
  paddingTop: `10px`,
})

const TdLabel = styled.td((props) => ({
  ...tdShared(props.theme),
  [props.theme.mq.min.lg]: {
    width: `300px`,
  },
}))

const TdValue = styled.td((props) => ({
  ...tdShared(props.theme),
  fontWeight: props.theme.text.weights.bold,
}))

const TdValueNotBold = styled.td((props) => ({
  ...tdShared(props.theme),
}))

const LinkBox = styled.div((props) => ({
  display: "flex",
  alignSelf: "center",
  gridColumn: "2 / span 6",
  [props.theme.mq.min.md]: {
    gridColumn: "6 / span 2",
  },
}))
