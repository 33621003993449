import styled from "@emotion/styled"
import { ApiOrder } from "../utils/types"
import { formatDatetimeString } from "../utils/utils"
import { GridArea } from "./GridArea"
import { Text } from "./Text"

const LocationsWrapper = styled.div((props) => ({
  display: "grid",
  columnGap: props.theme.space.sm,
  gridTemplateColumns: "0fr 1fr 1fr",
  gridTemplateRows: "0fr 1fr 0fr",
  gridTemplateAreas: `"pt1 loc1 dates1"
      "travelLine . ."
      "pt2 loc2 dates2"`,
  alignItems: "center",

  [props.theme.mq.max.lg]: {
    gridTemplateColumns: "0fr 1fr",
    gridTemplateRows: "0fr 0fr 1fr 0fr 0fr",
    gridTemplateAreas: `"pt1 loc1"
        "travelLine dates1"
        "travelLine ."
        "pt2 loc2"
        ". dates2"`,
  },
}))

const OrderLocation = styled.div((props) => ({
  fontWeight: props.theme.text.weights.med,
  fontSize: props.theme.text.sizes.lg,
  lineHeight: props.theme.text.lineHeights.med,
  whiteSpace: "nowrap",
}))

export function Locations({
  order,
  colorTheme,
}: {
  order: Pick<
    ApiOrder,
    | "Origin"
    | "Destination"
    | "ActualPickupDate"
    | "ScheduledPickupDate"
    | "EstimatedDeliveryDate"
    | "ActualDeliveryDate"
  >
  colorTheme: "light" | "dark"
}) {
  const OriginCity = order.Origin?.City
  const OriginState = order.Origin?.State
  const DestinationCity = order.Destination?.City
  const DestinationState = order.Destination?.State

  return (
    <LocationsWrapper>
      <GridArea gridArea="pt1">
        <TravelStartIcon colorTheme={colorTheme} />
      </GridArea>

      <GridArea gridArea="loc1">
        <OrderLocation>
          {OriginCity ? `${OriginCity}, ${OriginState}` : ""}
        </OrderLocation>
      </GridArea>

      <GridArea gridArea="travelLine" height="100%">
        <TravelLineIcon />
      </GridArea>

      <GridArea gridArea="dates1">
        <OrderDates
          colorTheme={colorTheme}
          estimatedDate={order.ScheduledPickupDate}
          actualDate={order.ActualPickupDate}
        />
      </GridArea>

      <GridArea gridArea="pt2">
        <TravelEndIcon colorTheme={colorTheme} />
      </GridArea>

      <GridArea gridArea="loc2">
        <OrderLocation>
          {DestinationCity ? `${DestinationCity}, ${DestinationState}` : ""}
        </OrderLocation>
      </GridArea>

      <GridArea gridArea="dates2">
        <OrderDates
          colorTheme={colorTheme}
          estimatedDate={order.EstimatedDeliveryDate}
          actualDate={order.ActualDeliveryDate}
        />
      </GridArea>
    </LocationsWrapper>
  )
}

function OrderDates({
  estimatedDate,
  actualDate,
  colorTheme,
}: {
  estimatedDate?: string
  actualDate?: string
  colorTheme: "light" | "dark"
}) {
  return (
    <OrderDatesWrapper>
      <Text
        color={colorTheme === "light" ? "xLightGray" : "darkGray"}
        fontWeight="reg"
        lineHeight="xs"
        fontSize="med"
      >
        {actualDate ? (
          <>
            Actual <b>{formatDatetimeString(actualDate)}</b>
          </>
        ) : (
          <>
            Estimated{" "}
            {estimatedDate ? <b>{formatDatetimeString(estimatedDate)}</b> : "–"}
          </>
        )}
      </Text>
    </OrderDatesWrapper>
  )
}

const OrderDatesWrapper = styled.div((props) => ({
  display: "flex",
  height: "100%",

  [props.theme.mq.min.lg]: {
    marginLeft: props.theme.space.xxl,
    justifyContent: "space-between",
  },
}))

const TravelCircle = styled.div((props) => ({
  borderRadius: 20,
  width: props.theme.space.sm,
  height: props.theme.space.sm,
}))

const TravelStartIcon = styled(TravelCircle)<{
  colorTheme: "light" | "dark"
}>((props) => ({
  backgroundColor:
    props.colorTheme === "light"
      ? props.theme.color.white
      : props.theme.color.black,
}))

const TravelEndIcon = styled(TravelCircle)<{
  colorTheme: "light" | "dark"
}>((props) => ({
  backgroundColor:
    props.colorTheme === "light"
      ? props.theme.color.borderLightGray
      : props.theme.color.medGray,
}))

const TravelLineIcon = styled.div((props) => ({
  border: `1px dashed ${props.theme.color.travelLineColor}`,
  margin: "0 auto",
  width: 0,
  height: "100%",
}))
