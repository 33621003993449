import styled from "@emotion/styled"

export const ScreenWrapper: React.FC = (props) => (
  <ContainerWrapper>
    <Container {...props} />
  </ContainerWrapper>
)

const ContainerWrapper = styled.div({
  display: "flex",
  justifyContent: "center",
  width: "100%",
})

const Container = styled.div((props) => ({
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  width: "100%",
  fontFamily: "'Barlow', sans-serif",
  position: "relative",
  [props.theme.mq.max.sm]: {
    maxWidth: "100%",
  },
}))
