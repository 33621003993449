import React from "react"
import styled from "@emotion/styled"
import { Field } from "formik"
import { Box } from "./Box"
import { Text } from "./Text"
import { check } from "../utils/icons"
import { TooltipPrompt, Tooltip } from "./Tooltip"

export const CheckboxLine = ({
  name,
  displayName,
  onClick,
  children,
  tooltipText,
  ...rest
}: {
  name: string
  displayName: string
  onClick?: any
  children?: React.ReactNode
  tooltipText?: React.ReactNode
} & JSX.IntrinsicElements["input"]) => {
  return (
    <label onClick={onClick ? onClick : null}>
      <Box display="flex" alignItems="center">
        <CheckboxStyledField type="checkbox" name={name} {...rest} />
        <Box ml="xs">
          <Text fontSize="lg">
            {displayName}
            {tooltipText != null ? (
              <>
                &nbsp;
                <Tooltip label={tooltipText}>
                  <TooltipPrompt />
                </Tooltip>
              </>
            ) : null}
          </Text>
        </Box>
        {children}
      </Box>
    </label>
  )
}

const CheckboxStyledField = styled(Field)((props) => ({
  "width": 24,
  "height": 24,
  "marginLeft": 0,
  "appearance": "none",
  "border": props.disabled
    ? `1px solid ${props.theme.color.lightGray}`
    : "1px solid #555",
  "borderRadius": "3px",
  "&:checked": {
    backgroundColor: props.disabled
      ? props.theme.color.lightGray
      : props.theme.color.blue,
    backgroundImage: `url(${check})`,
    backgroundPosition: "center",
    backgroundSize: "90%",
    backgroundRepeat: "no-repeat",
  },

  "&:checked &:hover": {
    backgroundColor: props.theme.color.blue,
  },
}))
