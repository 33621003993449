import { useLocalStorage, writeStorage } from "@rehooks/local-storage"
import { useCallback } from "react"
import { OrderListGroup } from "./orderListFilters"

export type RecentSearch = {
  search: string
  orderGroup: OrderListGroup
  orderId: string
}

export const RECENT_SEARCHES_LOCALSTORAGE_KEY = "recent-searches"

export function useSearchHistory(): [
  RecentSearch[],
  (r: RecentSearch) => void,
] {
  const [searches] = useLocalStorage<Array<RecentSearch>>(
    RECENT_SEARCHES_LOCALSTORAGE_KEY,
    [],
  )

  const appendSearch = useCallback(
    (values) => {
      if (
        searches[0] &&
        searches[0].search === values.search &&
        searches[0].orderGroup === values.orderGroup &&
        searches[0].orderId === values.orderId
      ) {
        // Don't save the same search twice in a row
        return
      }
      writeStorage(
        RECENT_SEARCHES_LOCALSTORAGE_KEY,
        [values, ...searches].slice(0, 20),
      )
    },
    [searches],
  )

  return [searches, appendSearch]
}
