import styled from "@emotion/styled"
import { Field, Formik, useFormik } from "formik"
import React, { useCallback, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { NavLink, useParams } from "react-router-dom"
import * as Yup from "yup"
import { orderVins } from "../../api/order"
import { Box } from "../../components/Box"
import {
  CancelButton,
  PrimaryButton,
} from "../../components/Buttons/PrimaryButton"
import { SecondaryButtonInline } from "../../components/Buttons/SecondaryButtonInline"
import { Container } from "../../components/Container"
import { Dialog } from "../../components/Dialog"
import { DialogLabel } from "../../components/DialogUI"
import { ErrorIndicator } from "../../components/ErrorIndicator"
import { FormSuccessIndicator } from "../../components/FormSuccessIndicator"
import {
  LoadingIndicator,
  LoadingIndicatorImage,
} from "../../components/LoadingIndicator/LoadingIndicator"
import { Locations } from "../../components/Locations"
import { OrderCardHeader } from "../../components/OrderCardHeader"
import { HorizontalDivider } from "../../components/OrderViewComponents"
import { MapMode, OsmRouteMap } from "../../components/RoutesMap"
import { Text } from "../../components/Text"
import { TogglePriorityButton } from "../../components/TogglePriorityButton"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import { viewOrderImagesPath } from "../../paths"
import { orderDetailsQueryKey } from "../../queryKeys"
import { useFlashMessageContext } from "../../utils/contexts/FlashMessageContext"
import { useLoginContext } from "../../utils/contexts/LoginContext"
import { useCurrentUserConsignors } from "../../utils/hooks/useCurrentUserQuery"
import { emailBlack, imageIcon, pdf } from "../../utils/icons"
import {
  API_ROOT,
  useFileViewRequest,
  useMutationRequest,
  useRequest,
} from "../../utils/oviss"
import { ApiOrder, OrderStatus } from "../../utils/types"
import { formatVIN, fullStreetAddress } from "../../utils/utils"

const Label = styled.label({ display: "block" })
const LabelErrorIndicator = styled.span((props) => ({
  marginLeft: props.theme.space.xs,
  color: props.theme.color.warning,
  whiteSpace: "pre-wrap",
}))
const ErrorIndicatorWithoutLabel = styled(LabelErrorIndicator)({
  marginLeft: 0,
})
const StyledTextarea = styled.textarea((props) => ({
  width: "100%",
  marginTop: 10,
  color: props.theme.color.darkGray,
}))

const StyledContactSalesRepButton = styled.button({
  color: "#000000",
  textDecoration: "none",
  background: "none",
  border: 0,
  padding: 0,
  cursor: "pointer",
  fontFamily: "Barlow, sans-serif",
  fontSize: 16,
})

const SalesRepDialog = ({
  showDialog,
  toggle,
  orderId,
  title,
}: {
  showDialog: boolean
  toggle: () => void
  orderId: number
  title: string
}) => {
  const flashContext = useFlashMessageContext()

  const mutationRequest = useMutationRequest()
  const { mutateAsync } = useMutation((values: { body: string }) => {
    return mutationRequest({
      path: `/api/ContactCustomerSalesRep/${orderId}`,
      method: "POST",
      query: {
        message: values.body,
      },
    })
  })

  const formik = useFormik({
    initialValues: {
      body: "",
    },
    validationSchema: Yup.object().shape({
      body: Yup.string().required("Required"),
    }),
    onSubmit: async (values, { setStatus }) => {
      try {
        await mutateAsync(values)
        flashContext.setMessage(
          <FormSuccessIndicator message="Email sent successfully!" />,
        )
        toggle()
      } catch (e: any) {
        setStatus({ type: "error", message: e.message })
      }
    },
  })

  return (
    <Dialog
      ariaLabel="send-email"
      onClose={toggle}
      show={showDialog}
      body={
        <>
          <Text color="darkGray" fontSize="xxl">
            {title}
          </Text>
          <Box mt="xxl">
            <form onSubmit={formik.handleSubmit}>
              <Box mb="sm" textAlign="left">
                <Label htmlFor="body">Message</Label>
                <StyledTextarea
                  id="body"
                  name="body"
                  placeholder="Write your message here..."
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.body}
                  rows={5}
                />
                <LabelErrorIndicator>
                  {formik.touched.body && formik.errors.body}
                </LabelErrorIndicator>
              </Box>

              <Box display="flex" justifyContent="center" mt="sm">
                {formik.status?.type === "error" && (
                  <ErrorIndicatorWithoutLabel>
                    Error sending email: {formik.status.message}
                  </ErrorIndicatorWithoutLabel>
                )}
              </Box>
            </form>
          </Box>
        </>
      }
      cancelButton={
        formik.isSubmitting ? (
          <LoadingIndicator colorScheme="black" />
        ) : (
          <CancelButton colorTheme="white" width="auto" onClick={toggle}>
            Cancel
          </CancelButton>
        )
      }
      confirmButton={
        formik.isSubmitting ? null : (
          <PrimaryButton
            type="button"
            width="auto"
            disabled={!formik.dirty || !formik.isValid}
            onClick={formik.submitForm}
          >
            Send
          </PrimaryButton>
        )
      }
    />
  )
}

export function OrderScreen() {
  const { id } = useParams()
  const request = useRequest()
  const [showSalesRepDialog, setShowSalesRepDialog] = useState(false)
  const toggleSalesRepDialog = () => setShowSalesRepDialog((prev) => !prev)

  const { data: orderPictures } = useQuery<Array<Record<string, any>>>(
    [
      ["order", { id }, "images"],
      {
        path: `/api/Orders/${id}/Pictures`,
        method: "GET",
      },
    ],
    request,
  )
  const mutationRequest = useMutationRequest()

  const {
    data: order,
    error,
    status: orderStatus,
    refetch,
  } = useQuery<ApiOrder, Error>(orderDetailsQueryKey(id), request)
  const [isDialogVisible, setIsDialogVisible] = useState(false)
  const flashContext = useFlashMessageContext()
  const consignors = useCurrentUserConsignors()

  useDocumentTitle(error ? `Error: ${error.message}` : `Order #${id}`)

  if (error) {
    return <ErrorIndicator error={error} />
  }

  if (orderStatus === "loading") {
    return (
      <Box pt="lg">
        <LoadingIndicator colorScheme="black" />
      </Box>
    )
  }

  if (!order) {
    return <div></div>
  }

  const vins = orderVins(order).map(formatVIN).join(", ")
  const hasSalesRep = !!order.SalesRep1Email && order.SalesRep1Name
  const salesRepContactTitle = `Contact ${
    hasSalesRep ? order.SalesRep1Name : "Customer Care"
  }`

  return (
    <>
      {flashContext.message && <Box mb="sm">{flashContext.message}</Box>}

      <OrderScreenUpperWrapper>
        <Container>
          <Box mb="sm">
            <OrderCardHeader
              id={order.OrderId}
              orderIdSize="sm"
              status={order.OrderStatus}
              showBackArrow={true}
            />
          </Box>

          {consignors.additionalConsignors &&
          consignors.additionalConsignors.length > 0 ? (
            <Box mh="sm" mb="md">
              <Text fontWeight="heavy">{order.Consignor.Name}</Text>
            </Box>
          ) : null}

          <Box mh="sm">
            <Locations order={order} colorTheme="light" />
          </Box>
          <HorizontalDivider colorTheme="light" />
          <Box mh="sm">
            <OrderSummaryWrapper>
              <NumUnitsWrapper>
                <div>
                  <div>
                    <Text fontSize="xxs" fontWeight="heavy" color="white">
                      VIN NUMBER
                    </Text>
                  </div>
                  <Box mt="xxs">
                    <Text fontSize="med">{vins}</Text>
                  </Box>
                </div>
              </NumUnitsWrapper>
              <RightStatsWrapper>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color="white"
                  lineHeight="xs"
                >
                  {order.OrderMiles.toFixed(0)} mi.
                </Text>
              </RightStatsWrapper>
            </OrderSummaryWrapper>
          </Box>

          <HorizontalDivider colorTheme="light" />

          <Box
            ml="sm"
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            <TogglePriorityButton
              colorScheme="light"
              orderID={order.OrderId}
              priority={order.IsUserPriority ? "high" : "normal"}
            >
              <Box ml="xs">
                <Text>
                  {order.IsUserPriority ? "Favorite" : "Mark as Favorite"}
                </Text>
              </Box>
            </TogglePriorityButton>

            {order.OrderStatus === "Inactive" ? (
              <SecondaryButtonInline
                onClick={() => setIsDialogVisible(true)}
                type="button"
                style={{ marginLeft: "auto", marginRight: 0 }}
              >
                Cancel Order
              </SecondaryButtonInline>
            ) : null}
          </Box>
        </Container>
      </OrderScreenUpperWrapper>
      <OrderScreenLowerWrapper>
        <Container>
          <OrderScreenLowerGrid>
            <div>
              <>
                <HorizontalDivider colorTheme="dark" />
                <Box mh="sm">
                  <StyledContactSalesRepButton onClick={toggleSalesRepDialog}>
                    <Box display="flex" alignItems="center">
                      <Box mr="xs">
                        <img src={emailBlack} alt={salesRepContactTitle} />
                      </Box>
                      {hasSalesRep
                        ? order.SalesRep1Name
                        : "Contact Customer Care"}
                    </Box>
                  </StyledContactSalesRepButton>

                  <SalesRepDialog
                    showDialog={showSalesRepDialog}
                    toggle={toggleSalesRepDialog}
                    orderId={order.OrderId}
                    title={salesRepContactTitle}
                  />
                </Box>
              </>

              <HorizontalDivider colorTheme="dark" />

              <OrderDocuments
                orderID={
                  order.OrderType === "LoadChild" ? order.LoadParentId : id
                }
                originClientId={order.Origin.ClientId}
                destinationClientId={order.Destination.ClientId}
                hasInvoice={order.InvoiceId !== 0}
              />

              {orderPictures && orderPictures.length > 0 && (
                <>
                  <DocListNavLink to={viewOrderImagesPath(id)}>
                    <Box mh="sm">
                      <Box display="flex" alignItems="center">
                        <Box mr="xs">
                          <img width={28} src={imageIcon} alt="images" />
                        </Box>
                        Images
                      </Box>
                    </Box>
                  </DocListNavLink>
                  <HorizontalDivider colorTheme="dark" />
                </>
              )}

              <Box mh="sm" mv="lg">
                <Text fontSize="xxs" fontWeight="heavy">
                  PICK UP LOCATION
                </Text>
                <Text fontSize="lg" fontWeight="bold">
                  <div>{order.Origin.Name}</div>
                  <Text fontWeight="reg">
                    <div>
                      {fullStreetAddress(
                        order.Origin.Address1,
                        order.Origin.Address2,
                      )}
                    </div>
                    <div>
                      {order.Origin.City}, {order.Origin.State}{" "}
                      {order.Origin.Zip}
                    </div>
                  </Text>
                </Text>
              </Box>

              <Box mh="sm" mv="lg">
                <Text fontSize="xxs" fontWeight="heavy">
                  DELIVERY LOCATION
                </Text>
                <Text fontSize="lg" fontWeight="bold">
                  <div>{order.Destination.Name}</div>
                  <Text fontWeight="reg">
                    <div>
                      {fullStreetAddress(
                        order.Destination.Address1,
                        order.Destination.Address2,
                      )}
                    </div>
                    <div>
                      {order.Destination.City}, {order.Destination.State}{" "}
                      {order.Destination.Zip}
                    </div>
                  </Text>
                </Text>
              </Box>
            </div>
            <Box mt="sm">
              <OsmRouteMap
                order={order}
                mode={getMapTypeForOrderStatus(order.OrderStatus)}
              />
            </Box>
          </OrderScreenLowerGrid>
        </Container>
      </OrderScreenLowerWrapper>

      <Dialog
        ariaLabel="Cancel order"
        onClose={() => setIsDialogVisible(false)}
        show={isDialogVisible}
        body={
          <div>
            <Box mb="xxl">
              <Text color="darkGray" fontSize="xxl">
                Cancel Order
              </Text>
            </Box>
            <Formik
              initialValues={{ cancelationReason: "" }}
              onSubmit={async (values, bag) => {
                try {
                  await mutationRequest({
                    path: `/api/OrderManager/StatusCancelled`,
                    method: "PATCH",
                    body: {
                      OrderId: order.OrderId,
                      CancellationComment:
                        values.cancelationReason || undefined,
                    },
                  })

                  setIsDialogVisible(false)
                  bag.resetForm()

                  await refetch()

                  setTimeout(() => {
                    window.alert(`Order canceled`)
                  }, 0)
                } catch (er) {
                  window.alert(`An error occured: ${er}`)
                }
              }}
            >
              {(formik) => (
                <Box textAlign="left">
                  <DialogLabel htmlFor="cancelationReason">
                    Cancelation Reason
                  </DialogLabel>

                  <Box mb="lg">
                    <Field
                      name="cancelationReason"
                      id="cancelationReason"
                      maxLength={120}
                      type="text"
                      as={StyledTextarea}
                    />
                  </Box>
                  <Box>
                    <PrimaryButton
                      type="submit"
                      onClick={() => formik.submitForm()}
                      width="auto"
                      disabled={formik.isSubmitting}
                    >
                      {formik.isSubmitting ? `Loading…` : `Cancel Order`}
                    </PrimaryButton>
                  </Box>
                </Box>
              )}
            </Formik>
          </div>
        }
      ></Dialog>
    </>
  )
}

function getMapTypeForOrderStatus(status: OrderStatus): MapMode {
  switch (status) {
    case "Active":
    case "PendingPickup":
      return "origin"
    case "InRoute":
      return "route"
    case "Delivered":
      return "destination"
    default:
      return "empty"
  }
}

const NumUnitsWrapper = styled.div({
  display: "flex",
  alignItems: "center",
})

const OrderScreenUpperWrapper = styled.div((props) => ({
  paddingTop: props.theme.space.sm,
  paddingBottom: props.theme.space.sm,
  backgroundColor: props.theme.color.black,
  color: "white",
}))

const OrderScreenLowerWrapper = styled.div((props) => ({
  paddingTop: props.theme.space.sm,
  paddingBottom: props.theme.space.sm,
  backgroundColor: props.theme.color.white,
  color: "black",
}))

const OrderScreenLowerGrid = styled.div((props) => ({
  [props.theme.mq.min.lg]: {
    display: "grid",
    columnGap: props.theme.space.xxl,
    gridTemplateColumns: "1fr 1fr",
  },
}))

const OrderSummaryWrapper = styled.div({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
})

const RightStatsWrapper = styled.div((props) => ({
  textAlign: "right",

  [props.theme.mq.min.lg]: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
}))

const DocumentsButton = styled(SecondaryButtonInline)({
  padding: 0,
  textDecoration: "none",
})

const DocumentsLink = styled.a({
  padding: 0,
  textDecoration: "none",
  border: "none",
  background: "none",
  textAlign: "center",
  margin: "0 auto",
  color: "inherit",
  fontSize: "inherit",
  fontWeight: "inherit",
  cursor: "pointer",
  fontFamily: "'Barlow', sans-serif",
})

type OrderDocumentsProps = {
  orderID: string | number
  hasInvoice: boolean
  originClientId: number
  destinationClientId: number
}

type ReportType = "PickupReceipt" | "DeliveryReceipt" | "Invoice"

function OrderDocuments({
  orderID,
  hasInvoice,
  originClientId,
  destinationClientId,
}: OrderDocumentsProps) {
  const request = useRequest()
  const { data: PickupDeliveryReceipt, isLoading } = useQuery(
    [
      ["order", { id: orderID.toString() }],
      {
        path: `/api/Orders/${orderID}/PickupDeliveryReceipt`,
        method: "GET",
      },
    ],
    request,
  )

  const receiptData =
    PickupDeliveryReceipt?.length > 0 ? PickupDeliveryReceipt[0] : {}

  return (
    <>
      <React.Fragment>
        {hasInvoice && (
          <>
            <OrderDocumentButton
              orderID={orderID}
              displayName="Invoice"
              reportType="Invoice"
            />
            <HorizontalDivider colorTheme="dark" />
          </>
        )}
        {!isLoading && (
          <>
            {receiptData?.OriginReceiptID != null &&
              receiptData.OriginReceiptID !== 0 && (
                <>
                  <OrderDocumentPdfButton
                    name="Pick Up Receipt"
                    orderId={orderID}
                    type="Origin"
                    clientId={originClientId}
                  />
                  <HorizontalDivider colorTheme="dark" />
                </>
              )}
            {receiptData?.DeliveryReceiptID != null &&
              receiptData.DeliveryReceiptID !== 0 && (
                <>
                  <OrderDocumentPdfButton
                    name="Delivery Receipt"
                    orderId={orderID}
                    type="Delivery"
                    clientId={destinationClientId}
                  />
                  <HorizontalDivider colorTheme="dark" />
                </>
              )}
          </>
        )}
      </React.Fragment>
    </>
  )
}

const OrderDocumentPdfButton = ({
  name,
  orderId,
  clientId,
  type,
}: {
  name: string
  orderId: number | string
  clientId: number
  type: "Origin" | "Delivery"
}) => {
  const { token } = useLoginContext()
  if (!clientId) throw new Error("Missing ClientId")
  let [state, setState] = useState<"loading" | "static">("static")

  const handleClick = async () => {
    setState("loading")

    try {
      let url = `${API_ROOT}/api/GetServiceReceiptsPdf?loadId=${orderId}&type=${type}&clientId=${clientId}`
      const resp = await fetch(url, {
        headers: { Authorization: `bearer ${token}` },
      })

      if (!resp.ok) {
        throw new Error(`Bad response (${resp.status})`)
      }

      if (resp.headers.get("content-type")?.startsWith("text/html")) {
        throw new Error(`Login required`)
      }

      const responseBlob = await resp.blob()

      const data = URL.createObjectURL(responseBlob)

      const link = document.createElement("a")
      link.href = data
      link.download = `${name}.pdf`
      document.body.appendChild(link)
      link.click()
      link.remove()

      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 500)
    } catch (err: any) {
      window.alert(`An error occured: ${err.message}`)
      throw err
    } finally {
      setState("static")
    }
  }

  return (
    <Box mh="sm">
      <DocumentsLink as="button" onClick={handleClick}>
        <Box display="flex" alignItems="center">
          <Box mr="xs">
            <img src={pdf} alt="pdf" />
          </Box>
          {name}
          {state === "loading" && (
            <LoadingIndicatorImage
              colorScheme="black"
              size={14}
              style={{ marginLeft: 10 }}
            />
          )}
        </Box>
      </DocumentsLink>
    </Box>
  )
}

type OrderDocumentButtonProps = {
  orderID: string | number
  displayName: string
  reportType: ReportType
}

function OrderDocumentButton({
  orderID,
  displayName,
  reportType,
}: OrderDocumentButtonProps) {
  const fileViewRequest = useFileViewRequest()

  const { mutateAsync, error } = useMutation(() => {
    return fileViewRequest({
      path: "/api/v2/Orders/CustomerOrderReports",
      method: "POST",
      body: {
        OrderId: orderID,
        ReportType: reportType,
      },
      query: { resultType: "File" },
    })
  })

  const onClick = useCallback(async () => {
    try {
      const pdfFileURL = await mutateAsync()
      window.open(pdfFileURL, "_blank")
    } catch (e) {
      // Catch the error just so that we don't puke it to the console.
      // We will use the error returned by `useMutation`
    }
  }, [mutateAsync])

  return (
    <Box mh="sm">
      {error && (
        <Box mb="sm">
          <ErrorIndicator
            error={`Error retreiving ${displayName}.`}
            justifyContent="flex-start"
            localMessage={true}
          />
        </Box>
      )}
      <DocumentsButton onClick={onClick}>
        <Box display="flex" alignItems="center">
          <Box mr="xs">
            <img src={pdf} alt="pdf" />
          </Box>
          {displayName}
        </Box>
      </DocumentsButton>
    </Box>
  )
}

const DocListNavLink = styled(NavLink)((props) => ({
  textDecoration: "none",
  color: props.theme.color.black,
}))
