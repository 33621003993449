export const landingPath = () => `/`
export const contactPath = () => `/contact`
export const accountPath = () => `/account`
export const supportPath = () => `/support`
export const feedbackPath = () => `/feedback`

export const currentOrdersListPath = () => `/orders/current/`
export const historyOrdersListPath = () => `/orders/history/`
export const inactiveOrdersListPath = () => `/orders/inactive/`
export const myOrdersListPath = () => `/orders/my-orders/`
export const inboundOrdersListPath = () => `/orders/inbound/`
export const outboundOrdersListPath = () => `/orders/outbound/`
export const viewOrderPath = (id: string) => `/orders/${id}`
export const viewOrderImagesPath = (id: string) => `/orders/${id}/images`
export const searchOrdersPath = () => `/orders/search`
export const newOrderPath = () => `/orders/new`

export const listSavedViewsPath = () => `/views`
export const configPath = () => `/config`
export const forgotPasswordPath = () => `/forgotpw`
export const resetPasswordPath = () => `/resetpw`
export const setPasswordPath = () => `/setpw`
export const resetExpiredPasswordPath = () => `/resetexpiredpw`
export const faqPath = () => `/faq`
export const notificationsPath = () => `/notifications`
