import { useQuery } from "react-query"
import { useRequest } from "../../utils/oviss"

import * as ApiV1 from "../../generated/ApiV1"
export const useOvissClient = (key: string, clientId: string) => {
  const request = useRequest()
  return useQuery<
    ApiV1.paths["/api/Clients/{id}"]["get"]["responses"]["200"]["schema"]
  >(
    [
      key,
      {
        path: `/api/Clients/${clientId}`,
        method: "GET",
      },
    ],
    request,
    {
      enabled: clientId !== "",
    },
  )
}
