import styled from "@emotion/styled"
import { useMemo } from "react"
import { Box } from "../components/Box"
import { OrderStatus } from "../utils/types"
import { formatStatusString } from "../utils/utils"
import { BackArrow } from "./BackArrow"
import {
  OrderCardId,
  OrderStatusProgressBar,
  OrderStatusDisplay,
} from "./OrderViewComponents"

export function OrderCardHeader({
  id,
  status,
  showBackArrow,
  orderIdSize,
}: {
  id: number
  status: OrderStatus
  showBackArrow?: boolean
  orderIdSize?: "xxs" | "xs" | "sm" | "med" | "lg" | "xl" | "xxl" | "xxxl"
}) {
  const orderStatus = useMemo(() => formatStatusString(status), [status])

  return (
    <OrderCardHeaderWrapper>
      <Box ml="sm">
        <Box mb="sm">
          <BackArrowWrapper>
            <Box mr="sm">{showBackArrow && <BackArrow theme="light" />}</Box>
            <OrderCardId fontSize={orderIdSize}>{id}</OrderCardId>
          </BackArrowWrapper>
        </Box>
        <OrderStatusProgressBar status={status} />
      </Box>
      <OrderStatusDisplay status={status}>{orderStatus}</OrderStatusDisplay>
    </OrderCardHeaderWrapper>
  )
}

const OrderCardHeaderWrapper = styled.div({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
})

const BackArrowWrapper = styled.div({
  display: "flex",
  alignItems: "center",
})
