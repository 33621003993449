import {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
} from "react"
import {
  clearTokenInLocalstorage,
  getTokenInLocalstorage,
  setTokenInLocalstorage,
} from "../oviss"

const LoginContext = createContext<{
  token: string | null
  isLoggedIn: boolean
  setToken: (token: string) => void
  logout: () => void
}>({
  token: null,
  isLoggedIn: false,
  setToken: (_s: string) => null,
  logout: () => null,
})

export const LoginContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [tokenState, setTokenState] = useState(getTokenInLocalstorage())

  const logout = useCallback(() => {
    clearTokenInLocalstorage()
    setTokenState(null)
  }, [])

  const setAndSaveToken = useCallback(
    (token) => {
      setTokenInLocalstorage(token)
      setTokenState(token)
    },
    [setTokenState],
  )

  const value = useMemo(
    () => ({
      token: tokenState,
      isLoggedIn: tokenState !== null,
      setToken: setAndSaveToken,
      logout,
    }),
    [tokenState, setAndSaveToken, logout],
  )

  return <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
}

export function useLoginContext() {
  return useContext(LoginContext)
}
