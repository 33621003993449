import styled from "@emotion/styled"
import { ur_gray } from "../../utils/backgrounds"
import { carBlack } from "../../utils/icons"
import { Box } from "../../components/Box"
import { Text } from "../../components/Text"
import { VinVehicle } from "./types"

export const Container = styled.div((props) => ({
  background: `url(${ur_gray}) black`,
  flexGrow: 1,
  display: `flex`,
  flexDirection: `column`,
  alignItems: `center`,
  paddingTop: `20px`,
  paddingLeft: `20px`,
  paddingRight: `20px`,
  paddingBottom: `100px`,
  [props.theme.mq.min.lg]: {
    paddingTop: `100px`,
  },
}))

export const Title = styled.h1<{ mb?: string }>((props) => ({
  color: props.theme.color.blue,
  fontFamily: props.theme.text.fonts.sans,
  fontWeight: props.theme.text.weights.reg,
  fontSize: `40px`,
  letterSpacing: `-1px`,
  marginBottom: props.mb ?? `38px`,
}))

export const SectionTitle = styled.h2((props) => ({
  fontSize: `21px`,
  lineHeight: `30px`,
  fontWeight: props.theme.text.weights.reg,
}))

export const Label = styled.label((props) => ({
  display: "block",
  fontWeight: props.theme.text.weights.bold,
  marginBottom: props.theme.space.xxs,
}))

export const TwoColumn = styled.div<{ centerVertical?: boolean }>((props) => ({
  ...(props.centerVertical && { alignItems: "center" }),
  [props.theme.mq.min.lg]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gap: props.theme.space.lg,
  },
}))

export const VehicleLogo = () => {
  return <VehicleLogoImg src={carBlack} width={60} alt="" />
}

export const VehicleDetails = styled.div({
  gridColumn: "2 / span 2",
})

export const VehicleContainer = styled.div((props) => ({
  display: "grid",
  gridTemplateColumns: `repeat(6, 1fr)`,
  gap: `10px`,
  boxShadow: props.theme.shadows.down,
  padding: `15px`,
  marginTop: `10px`,
  marginBottom: `10px`,
}))

export const VehicleControlContainer = styled.div((props) => ({
  display: "flex",
  alignSelf: "center",

  gridColumn: "1 / span 6",

  [props.theme.mq.min.md]: {
    gridColumn: "initial",
  },
}))

const VehicleLogoImg = styled.img((props) => ({
  margin: props.theme.space.md,
  alginSelf: `center`,
}))

export const VehicleBasics = ({ vehicle }: { vehicle: VinVehicle }) => {
  return (
    <>
      <VehicleLogo />
      <VehicleDetails>
        <Box mb="xs">
          <Text fontSize="xxs" fontWeight="reg">
            {vehicle.Vin}
          </Text>
        </Box>
        <Box mb="xxs">
          <Text fontSize="lg" fontWeight="bold">
            {vehicle.Make} {vehicle.Model}
          </Text>
        </Box>
        <Text fontSize="med" fontWeight="reg">
          {vehicle.Year}
        </Text>
      </VehicleDetails>
    </>
  )
}
