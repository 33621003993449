import { useQueries, useQuery } from "react-query"
import * as ApiV1 from "../../generated/ApiV1"
import { DeepRequired } from "../../types/DeepRequired"
import { useLoginContext } from "../contexts/LoginContext"
import { isNotNull } from "../filter"
import { useRequest } from "../oviss"
import { ApiUser } from "../types"

export function useCurrentUserQuery() {
  const { isLoggedIn } = useLoginContext()
  const request = useRequest()

  return useQuery<ApiUser>(
    [
      "user",
      {
        path: `/api/CurrentUser`,
        method: "GET",
      },
    ],
    request,
    { enabled: isLoggedIn },
  )
}

export type Consignor =
  | ApiUser["Client"]
  | ApiV1.definitions["VTVSolutions.Vlms.Dtos.Clients.ClientDto"]

export type CurrentUserConsignors = {
  error: Error | null
  loading: boolean
  primaryConsignor: Consignor | undefined
  additionalConsignors: Array<Consignor> | undefined
  allConsignors: Array<Consignor> | undefined
}

export function useCurrentUserConsignors(): CurrentUserConsignors {
  const request = useRequest()

  const currentUserResult = useCurrentUserQuery()
  const consignorResult = useQuery<
    DeepRequired<
      ApiV1.paths["/api/Users/RelatedClients"]["get"]["responses"]["200"]["schema"]
    >
  >(
    [
      "consignors",
      {
        path: `/api/Users/RelatedClients`,
        query: { userId: currentUserResult.data?.UserId },
        method: "GET",
      },
    ],
    request,
    {
      enabled: currentUserResult.data != null,
    },
  )

  let consignorIds =
    consignorResult.data?.map((entry) => entry.ClientId).filter(isNotNull) ?? []

  const consignorQueries = useQueries(
    consignorIds.map((id) => {
      return {
        queryKey: ["client", { path: `/api/clients/${id}` }],
        queryFn: request,
        enabled: consignorResult.status === "success",
      }
    }),
  )

  let isAllSuccess = consignorQueries.every(
    (entry) => entry.status === "success",
  )

  let isLoading =
    currentUserResult.isLoading ||
    consignorResult.isLoading ||
    consignorQueries.some(
      (entry) => entry.status === "loading" || entry.status === "idle",
    )

  let error: Error | null = (currentUserResult.error ??
    consignorResult.error ??
    consignorQueries.find((result) => result.error)?.error ??
    null) as Error | null

  let additionalConsignors = isAllSuccess
    ? consignorQueries.map(
        (result) =>
          result.data as ApiV1.definitions["VTVSolutions.Vlms.Dtos.Clients.ClientDto"],
      )
    : undefined

  let primaryConsignor = isAllSuccess
    ? currentUserResult.data?.Client
    : undefined

  return {
    error,
    loading: isLoading,
    primaryConsignor,
    additionalConsignors,
    allConsignors: isAllSuccess
      ? [
          ...(primaryConsignor ? [primaryConsignor] : []),
          ...(additionalConsignors ?? []),
        ]
      : undefined,
  }
}
