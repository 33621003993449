import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useMemo,
  useEffect,
} from "react"
import { useLocation } from "react-router-dom"

const FlashMessageContext = createContext<{
  message?: React.ReactNode
  setMessage: (r: React.ReactNode) => void
  clearMessage: () => void
}>({
  message: undefined,
  setMessage: () => null,
  clearMessage: () => {},
})

export const FlashMessageContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [message, setMessage] = useState<React.ReactNode | null>(null)
  const clearMessage = useCallback(() => setMessage(null), [setMessage])

  // Clear the flash message any time the pathname changes, so we don't show
  // stale form results
  const location = useLocation()
  useEffect(() => clearMessage(), [location.pathname, clearMessage])

  const value = useMemo(
    () => ({
      message,
      clearMessage,
      setMessage,
    }),
    [setMessage, clearMessage, message],
  )

  return (
    <FlashMessageContext.Provider value={value}>
      {children}
    </FlashMessageContext.Provider>
  )
}

export function useFlashMessageContext() {
  return useContext(FlashMessageContext)
}
