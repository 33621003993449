import styled from "@emotion/styled"
import { Form, Formik } from "formik"
import { useMutation, useQuery } from "react-query"
import { BackArrow } from "../../components/BackArrow"
import { Box } from "../../components/Box"
import { PrimaryButton } from "../../components/Buttons/PrimaryButton"
import { Container } from "../../components/Container"
import { HorizontalDivider } from "../../components/HorizontalDivider"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { RadioLine } from "../../components/RadioLine"
import { Text } from "../../components/Text"
import * as ApiV1 from "../../generated/ApiV1"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import { useCurrentUserQuery } from "../../utils/hooks/useCurrentUserQuery"
import { useMutationRequest, useRequest } from "../../utils/oviss"

type Section = "Email" | "SMS"
type NotificationFrequency = "RealTime" | "Daily" | "Weekly"

export function NotificationsScreen() {
  const request = useRequest()
  const mutationRequest = useMutationRequest()
  useDocumentTitle("Notifications")
  const { data: user } = useCurrentUserQuery()
  const { data: notificationsSettings } = useQuery<
    ApiV1.definitions["VTVSolutions.Vlms.Web.UI.Controllers.Api.Models.CustomerTracking.NotificationSettingsView"]
  >(
    [
      "notifications",
      {
        path: "/api/CustomerTracking/NotificationSettings",
        query: { clientId: user?.Client.ClientId },
        method: "GET",
      },
    ],
    request,
    { enabled: user != null },
  )
  const { isLoading: isSaving, mutateAsync } = useMutation(
    ({
      ConsignorInRouteEmailEnabled,
      ConsignorDeliveredEmailEnabled,
      ConsignorInRouteSmsEnabled,
      ConsignorDeliveredSmsEnabled,
    }: {
      ConsignorInRouteEmailEnabled: boolean
      ConsignorDeliveredEmailEnabled: boolean
      ConsignorInRouteSmsEnabled: boolean
      ConsignorDeliveredSmsEnabled: boolean
    }) => {
      return mutationRequest({
        path: "/api/CustomerTracking/NotificationSettings",
        method: "PUT",
        body: {
          clientId: user?.Client.ClientId,
          ConsignorInRouteEmailEnabled,
          ConsignorDeliveredEmailEnabled,
          ConsignorInRouteSmsEnabled,
          ConsignorDeliveredSmsEnabled,
        },
      })
    },
  )

  if (!notificationsSettings) {
    return <LoadingIndicator colorScheme="black" />
  }

  return (
    <Box mh="sm">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <FlexSpacer>
          <BackArrow theme="dark" />
        </FlexSpacer>
        <Text fontSize="xxl">Notification Emails</Text>
        <FlexSpacer />
      </Box>
      <Container>
        <Formik
          enableReinitialize={true}
          initialValues={{
            selection: "Email" as Section,
            email: {
              pickedUp:
                notificationsSettings &&
                notificationsSettings.ConsignorInRouteEmailEnabled
                  ? "true"
                  : "false",
              delivered:
                notificationsSettings &&
                notificationsSettings.ConsignorDeliveredEmailEnabled
                  ? "true"
                  : "false",
              frequency: "RealTime" as NotificationFrequency,
            },
            sms: {
              pickedUp:
                notificationsSettings &&
                notificationsSettings.ConsignorInRouteSmsEnabled
                  ? "true"
                  : "false",
              delivered:
                notificationsSettings &&
                notificationsSettings.ConsignorDeliveredSmsEnabled
                  ? "true"
                  : "false",
            },
          }}
          onSubmit={async (values, { setStatus }) => {
            try {
              await mutateAsync({
                ConsignorInRouteEmailEnabled: values.email.pickedUp === "true",
                ConsignorDeliveredEmailEnabled:
                  values.email.delivered === "true",
                ConsignorInRouteSmsEnabled: values.sms.pickedUp === "true",
                ConsignorDeliveredSmsEnabled: values.sms.delivered === "true",
              })
              setStatus(null)
              return true
            } catch (e) {
              setStatus(e.message)
              return false
            }
          }}
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Box
                mt="md"
                mb="lg"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <SectionSelector
                  side="left"
                  onClick={(s) => setFieldValue("selection", s)}
                  section="Email"
                  selection={values.selection}
                />
                <SectionSelector
                  side="right"
                  onClick={(s) => setFieldValue("selection", s)}
                  section="SMS"
                  selection={values.selection}
                  disabled={true}
                />
              </Box>

              <Box>
                <RadioTitle>
                  Get the information you need to track your orders. You can
                  turn off notifications at any time.
                </RadioTitle>
              </Box>

              <HorizontalDivider mv="md" color="lightGray" />

              {/* Email settings form. Hidden if the Email is not the selected section*/}
              <Box display={values.selection === "Email" ? "unset" : "none"}>
                <RadioTitle>PICKED UP</RadioTitle>
                <ThreeColumnGrid>
                  <RadioLine
                    name="email.pickedUp"
                    displayName="All Orders"
                    value="true"
                    checked={values.email.pickedUp === "true"}
                  />
                  <RadioLine
                    name="Email.PickedUp"
                    displayName="Favorite Orders"
                    value="FavoriteOrders"
                    disabled={true}
                  />

                  <RadioLine
                    name="email.pickedUp"
                    displayName="None"
                    value="false"
                    checked={values.email.pickedUp === "false"}
                  />
                </ThreeColumnGrid>
                <HorizontalDivider mv="md" color="lightGray" />

                <RadioTitle>DELIVERED</RadioTitle>
                <ThreeColumnGrid>
                  <RadioLine
                    name="email.delivered"
                    displayName="All Orders"
                    value="true"
                    checked={values.email.delivered === "true"}
                  />
                  <RadioLine
                    name="email.delivered"
                    displayName="Favorite Orders"
                    value="FavoriteOrders"
                    disabled={true}
                  />

                  <RadioLine
                    name="email.delivered"
                    displayName="None"
                    value="false"
                    checked={values.email.delivered === "false"}
                  />
                </ThreeColumnGrid>
                <HorizontalDivider mv="md" color="lightGray" />

                <RadioTitle>
                  How frequently would you like to receive notifications?
                </RadioTitle>
                <ThreeColumnGrid>
                  <RadioLine
                    name="email.frequency"
                    displayName="Real-Time"
                    value="RealTime"
                    checked={true}
                  />
                  <RadioLine
                    name="email.frequency"
                    displayName="Daily"
                    value="Daily"
                    disabled={true}
                  />

                  <RadioLine
                    name="email.frequency"
                    displayName="Weekly"
                    value="Weekly"
                    disabled={true}
                  />
                </ThreeColumnGrid>
                <HorizontalDivider mv="sm" color="lightGray" />
              </Box>

              {/* SMS settings form. Hidden if the SMS is not the selected section*/}
              <Box display={values.selection === "SMS" ? "unset" : "none"}>
                <RadioTitle>PICKED UP</RadioTitle>
                <ThreeColumnGrid>
                  <RadioLine
                    name="sms.pickedUp"
                    displayName="All Orders"
                    value="true"
                    checked={values.sms.pickedUp === "true"}
                  />
                  <RadioLine
                    name="sms.pickedUp"
                    displayName="Favorite Orders"
                    value="FavoriteOrders"
                    disabled={true}
                  />

                  <RadioLine
                    name="sms.pickedUp"
                    displayName="None"
                    value="false"
                    checked={values.sms.pickedUp === "false"}
                  />
                </ThreeColumnGrid>
                <HorizontalDivider mv="md" color="lightGray" />

                <RadioTitle>DELIVERED</RadioTitle>
                <ThreeColumnGrid>
                  <RadioLine
                    name="sms.delivered"
                    displayName="All Orders"
                    value="true"
                    checked={values.sms.delivered === "true"}
                  />
                  <RadioLine
                    name="sms.delivered"
                    displayName="Favorite Orders"
                    value="FavoriteOrders"
                    disabled={true}
                  />

                  <RadioLine
                    name="sms.delivered"
                    displayName="None"
                    value="false"
                    checked={values.sms.delivered === "false"}
                  />
                </ThreeColumnGrid>
                <HorizontalDivider mv="md" color="lightGray" />
              </Box>

              <Box display="flex" justifyContent="center" mt="xl">
                {isSaving ? (
                  <LoadingIndicator colorScheme="black" />
                ) : (
                  <PrimaryButton type="submit">Save</PrimaryButton>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Container>
    </Box>
  )
}

function SectionSelector({
  // side,
  // section,
  // selection,
  // onClick,
  // disabled,
  ..._todo
}: {
  side?: "left" | "right"
  section: Section
  selection: Section
  onClick: (s: Section) => void
  disabled?: boolean
}) {
  // TODO: Section selector hidden until sms notifications are enabled
  return null

  // return (
  //   <SectionSelectorButton
  //     onClick={() => onClick(section)}
  //     selected={selection === section}
  //     side={side}
  //     disabled={disabled}
  //   >
  //     {section}
  //   </SectionSelectorButton>
  // )
}

const SectionSelectorButton = styled.button<{
  selected: boolean
  side?: "left" | "right"
}>((props) => ({
  "borderRadius": 50,
  "borderTopRightRadius": props.side === "left" ? "unset" : undefined,
  "borderBottomRightRadius": props.side === "left" ? "unset" : undefined,
  "borderTopLeftRadius": props.side === "right" ? "unset" : undefined,
  "borderBottomLeftRadius": props.side === "right" ? "unset" : undefined,
  "backgroundColor": props.selected
    ? props.theme.color.blue
    : props.theme.color.white,
  "color": props.selected ? props.theme.color.white : props.theme.color.blue,
  "border": `1px solid ${props.theme.color.blue}`,
  "padding": props.theme.space.xxs,
  "paddingLeft": props.theme.space.sm,
  "paddingRight": props.theme.space.sm,
  "cursor": "pointer",
  "margin": 0,
  "fontSize": props.theme.text.sizes.sm,
  "&:disabled": {
    color: props.theme.color.lightGray,
    borderColor: props.theme.color.lightGray,
  },
}))

SectionSelectorButton.defaultProps = {
  type: "button",
}

const FlexSpacer = styled.div({
  flex: 1,
})

const ThreeColumnGrid = styled.div({
  display: "grid",
  gridTemplateColumns: "1fr 1fr 1fr",
})

const RadioTitle = styled.div((props) => ({
  fontSize: props.theme.text.sizes.lg,
  marginBottom: props.theme.space.sm,
}))
