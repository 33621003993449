/* eslint-disable no-unreachable */
import styled from "@emotion/styled"
import {
  Combobox,
  ComboboxList,
  ComboboxOption,
  ComboboxPopover,
} from "@reach/combobox"
import { addDays, formatISO, isBefore, parseISO, startOfDay } from "date-fns"
import {
  Field,
  FieldArray,
  Form,
  FormikProvider,
  useFormik,
  useFormikContext,
} from "formik"
import { uniqBy } from "lodash"
import { useEffect, useState } from "react"
import { useQuery } from "react-query"
import invariant from "tiny-invariant"
import { v4 as uuidv4 } from "uuid"
import { Box } from "../../components/Box"
import { Button, ButtonLink } from "../../components/Buttons/Button"
import { SecondaryButton } from "../../components/Buttons/SecondaryButton"
import { CheckboxLine } from "../../components/CheckboxLine"
import { ResultName } from "../../components/ClientAutocompleteInput"
import { CustomerCareLink } from "../../components/CustomerCareLink"
import { ErrorIndicator } from "../../components/ErrorIndicator"
import {
  StyledComboboxInput,
  StyledInput,
  StyledSelect,
} from "../../components/FormElements"
import { FormikError } from "../../components/formik"
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator"
import { Text } from "../../components/Text"
import { Tooltip, TooltipPrompt } from "../../components/Tooltip"
import * as ApiV2 from "../../generated/ApiV2"
import { useDocumentTitle } from "../../hooks/useDocumentTitle"
import { newOrderPath } from "../../paths"
import { mileageBandByUnits } from "../../quotes/mileage-bands"
import {
  useCurrentUserConsignors,
  useCurrentUserQuery,
} from "../../utils/hooks/useCurrentUserQuery"
import { useMutationRequest, useRequest } from "../../utils/oviss"
import { pluralize } from "../../utils/pluralize"
import {
  Container,
  Label,
  SectionTitle,
  Title,
  TwoColumn,
  VehicleBasics,
  VehicleContainer,
  VehicleControlContainer,
} from "./NewOrderScreenComponents"
import { Distance, Fields, PricingResponse, PricingResponses } from "./types"
import { useOvissClient } from "./useOvissClient"

const MAX_VSF = 11.0

export const NewOrderForm = ({
  onPricingCreated,
}: {
  onPricingCreated: (responses: PricingResponses, fields: Fields) => void
}) => {
  useDocumentTitle(`Create Quote`)

  const currentUser = useCurrentUserQuery()
  const consignors = useCurrentUserConsignors()

  const [error, setError] = useState<Error | null>(null)

  const formik = useFormik<Fields>({
    initialValues: {
      originId: "",
      originName: null,
      destinationId: "",
      destinationName: null,
      requestedEarliest: formatISO(addDays(new Date(), 1), {
        representation: "date",
      }),
      dueLatestRushSold: "",
      dueLatestNoRushSold: "",
      vins: "",
      vehicles: [],
      isEnclosed: false,
      isRushSold: false,
      consignorId: "",
    },
    validate: (values) => {
      if (
        vinRequestState.status === "idle" &&
        vinRequestState.vinsNotFound.length !== 0
      ) {
        setVinRequestState({
          status: "idle",
          vinsNotFound: [],
          vinsOverVsf: [],
        })
      }

      let errors: Partial<Record<keyof Fields, string>> = {}
      if (values.destinationId === "") {
        errors.destinationId = "Required"
      }
      if (values.originId === "") {
        errors.originId = "Required"
      }
      if (values.requestedEarliest === "") {
        errors.requestedEarliest = "Required"
      }

      if (values.requestedEarliest !== "") {
        const requestedEarliest = parseISO(values.requestedEarliest)
        if (isBefore(requestedEarliest, startOfDay(new Date()))) {
          errors.requestedEarliest = "Cannot be before today"
        }
      }

      if (values.vehicles.length === 0) {
        errors.vehicles = "Required"
      }

      const vsfTotal = values.vehicles.reduce((acc, vehicle) => {
        return acc + (vehicle.vehicle.Vsf ?? 0)
      }, 0)

      // https://www.pivotaltracker.com/story/show/182468555
      if (vsfTotal > MAX_VSF) {
        errors.vehicles = "Too many vehicles"
      }

      return errors
    },
    onSubmit: async (values) => {
      if (originResult.status !== "success") {
        window.alert(
          `Error getting origin details, please refresh and try again`,
        )
        return
      }

      if (destinationResult.status !== "success") {
        window.alert(
          `Error getting destination details, please refresh and try again`,
        )
        return
      }

      if (distanceResult.status !== "success") {
        window.alert(`Error getting distance, please refresh and try again`)
        return
      }

      let pricingEnv = process.env.REACT_APP_PRICING_ENV
      if (pricingEnv == null && process.env.NODE_ENV === "development") {
        pricingEnv = "staging"
      }
      invariant(pricingEnv)

      const endpointMap: Record<string, string> = {
        staging: `/pricing-api-proxy-staging/DEV/triggerpricing`,
        production: `/pricing-api-proxy-production/PROD/triggerpricing`,
      }

      const endpoint = endpointMap[pricingEnv]
      if (endpoint == null) {
        throw new Error(
          `unknown pricing environment: ${JSON.stringify(pricingEnv)}`,
        )
      }

      const randomQuoteUuid = btoa(uuidv4()).slice(0, 20)

      // https://docs.google.com/spreadsheets/d/1JhYkG2VvXT_C-satdCUVbB8EW6bbVe5Ow_RvyuFiz9I/edit#gid=0
      const data = ({ rushSold }: { rushSold: boolean }) => ({
        Records: [
          {
            user_id: "TESTING", // todo?
            quote_id: randomQuoteUuid,
            origin_zip: originResult.data?.Zip?.trim(),
            destination_zip: destinationResult.data?.Zip?.trim(),
            miles: distanceResult.data.Miles,
            routeDuration: distanceResult.data.Duration,
            number_of_vehicles: values.vehicles.length,
            enclosed_truck: values.isEnclosed ? "yes" : "no",
            due_date: null,
            customer_id: currentUser.data?.Client.ClientId,
            rushSold,
            guaranteedDelivery: false,
            guaranteedPickup: false,
            vehicle_details: values.vehicles.map((vehicle) => ({
              type: "",
              inop: vehicle.fields.inop,
              vsf: vehicle.vehicle.Vsf,
              hasKeys: vehicle.fields.hasKeys,
            })),
          },
        ],
      })

      try {
        const makeRequest = async (args: { rushSold: boolean }) => {
          const response = await fetch(endpoint, {
            headers: {
              "Content-Type": "application/json",
            },
            method: "POST",
            body: JSON.stringify(data(args)),
          })

          if (!response.ok) {
            throw new Error(`Bad response: ${response.status}`)
          }

          const responseData = (await response.json()) as PricingResponse
          return responseData
        }

        const rush = makeRequest({ rushSold: true })
        const noRush = makeRequest({ rushSold: false })

        onPricingCreated(
          {
            rushSold: await rush,
            noRushSold: await noRush,
          },
          {
            ...values,
            dueLatestRushSold: derivedDueLatestRushSold,
            dueLatestNoRushSold: derivedDueLatestNoRushSold,
            randomQuoteUuid,
            distance: distanceResult.data,
          },
        )
      } catch (err) {
        setError(
          err instanceof Error ? err : new Error(`unexpected error: ${err}`),
        )
      }
    },
  })

  useEffect(() => {
    if (formik.values.consignorId === "" && consignors.primaryConsignor) {
      formik.setFieldValue("consignorId", consignors.primaryConsignor.ClientId)
    }
  }, [consignors.primaryConsignor, formik])

  const decodedVinRequest =
    useMutationRequest<
      ApiV2.paths["/api/v2/Orders/GetDecodedVin"]["get"]["responses"]["200"]["schema"]
    >()

  const request = useRequest()

  const originResult = useOvissClient(`originResult`, formik.values.originId)

  const destinationResult = useOvissClient(
    `destinationResult`,
    formik.values.destinationId,
  )

  const distanceResult = useQuery<Distance>(
    [
      `distance`,
      {
        path: `/api/GeoServices/GetDistanceBetweenTwoPoints?${new URLSearchParams(
          {
            originId: formik.values.originId,
            destinationId: formik.values.destinationId,
          },
        )}`,
        method: "GET",
      },
    ],
    request,
    {
      enabled:
        formik.values.originId !== "" && formik.values.destinationId !== "",
    },
  )

  const [vinRequestState, setVinRequestState] = useState<
    | {
        status: "idle"
        vinsNotFound: Array<string>
        vinsOverVsf: Array<string>
      }
    | { status: "loading" }
    | { status: "error"; error: Error }
  >({ status: "idle", vinsNotFound: [], vinsOverVsf: [] })

  const handleVinAdd = async () => {
    const vinList = formik.values.vins
      .split(/[,\s]\s?/)
      .filter((x) => x.trim().length > 0)

    let newVehicles = []
    let vinsNotFound: Array<string> = []
    let vinsOverVsf: Array<string> = []

    setVinRequestState({ status: "loading" })
    try {
      for (let vin of vinList) {
        const decodedVins = await decodedVinRequest({
          path: "/api/v2/Orders/GetDecodedVin",
          query: { vin },
          method: "GET",
        })

        for (let entry of decodedVins) {
          const newValue: Fields["vehicles"][number] = {
            fields: { hasKeys: true, inop: false },
            vehicle: entry,
          }

          if (newValue.vehicle.Vin == null) continue

          // avoid duplicates
          const uniqueVehicles = uniqBy(
            [...formik.values.vehicles, ...newVehicles],
            (vehicle) => {
              return vehicle.vehicle.Vin
            },
          )

          const vsfTotal = uniqueVehicles.reduce((acc, vehicle) => {
            return acc + (vehicle.vehicle.Vsf ?? 0)
          }, 0)

          if (vsfTotal + (newValue.vehicle.Vsf ?? 0) > MAX_VSF) {
            vinsOverVsf.push(newValue.vehicle.Vin)
          } else {
            newVehicles.push(newValue)
          }
        }

        const isVinFound = decodedVins.some(
          (v) => v.Vin?.toLowerCase().trim() === vin.toLowerCase().trim(),
        )

        if (!isVinFound) {
          vinsNotFound.push(vin)
        }
      }

      // avoid duplicates
      const uniqueVehicles = uniqBy(
        [...formik.values.vehicles, ...newVehicles],
        (vehicle) => {
          return vehicle.vehicle.Vin
        },
      )

      formik.setFieldValue("vehicles", uniqueVehicles)
      formik.setFieldValue("vins", "")
      setVinRequestState({ status: "idle", vinsNotFound, vinsOverVsf })
    } catch (er) {
      setVinRequestState({ status: "error", error: er as Error })
    }
  }

  const currentUserResult = useCurrentUserQuery()

  if (currentUserResult.status === "loading") return <LoadingIndicator />

  if (currentUserResult.status === "error")
    return <ErrorIndicator error={currentUserResult.error} />

  const isCreateOrderEnabled =
    (currentUserResult.data &&
      currentUserResult.data.Roles.includes("OrderEntry")) ||
    process.env.REACT_APP_RESTRICT_ORDER_ENTRY === "anyone"

  let derivedDueLatestRushSold = ""
  let derivedDueLatestNoRushSold = ""
  if (formik.values.requestedEarliest !== "" && distanceResult.data) {
    const calculateDate = (isRushSold: boolean) => {
      const mileageBand = mileageBandByUnits(
        distanceResult.data.Miles,
        formik.values.vehicles.length,
        isRushSold,
      )
      const startDate = parseISO(formik.values.requestedEarliest)
      const endDate = addDays(startDate, mileageBand.transitTime)

      return formatISO(endDate, { representation: "date" })
    }

    derivedDueLatestRushSold = calculateDate(true)
    derivedDueLatestNoRushSold = calculateDate(false)
  }

  if (!isCreateOrderEnabled) {
    return <ErrorIndicator error={new Error("Missing OrderEntry role")} />
  }

  if (error) {
    return (
      <Container>
        <Box p="lg" bg="white" maxWidth="1024px" width="100%">
          <Title>Issue Quoting Your Order</Title>
          <SectionTitle>
            Please reach out to customer support at <CustomerCareLink /> for
            assistance
          </SectionTitle>
          <ErrorIndicator error={error} />

          <Box flex={1} display="grid" pt="lg" gridColumns={2} gap="10px">
            <ButtonLink to={newOrderPath()} bg="green">
              Create New Order
            </ButtonLink>
            <Button
              onClick={() => setError(null)}
              type="button"
              bg="transparent"
              color="darkGray"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Container>
    )
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Container>
          <Box p="lg" bg="white" maxWidth="1024px" width="100%">
            <Title>Enter the details of your quote</Title>

            {consignors.allConsignors && consignors.allConsignors.length > 0 ? (
              <TwoColumn>
                <Box flex={1}>
                  <Box mb="md">
                    <Label>Consignor</Label>

                    <Field
                      as={StyledSelect}
                      type="select"
                      name="consignorId"
                      id="consignorId"
                      onChange={(ev: any) => {
                        const dependentFields = [
                          "originId",
                          "originName",
                          "destinationId",
                          "destinationName",
                        ]

                        dependentFields.forEach((field) => {
                          formik.setFieldValue(field, "")
                        })

                        formik.handleChange(ev)
                      }}
                    >
                      {consignors.allConsignors.map((c) => (
                        <option key={c.ClientId} value={c.ClientId}>
                          {c.Name}
                        </option>
                      ))}
                    </Field>
                    <FormikError name="consignorId" />
                  </Box>
                </Box>
                <Box></Box>
              </TwoColumn>
            ) : null}

            <TwoColumn>
              <Box flex={1}>
                <SectionTitle>Location Details</SectionTitle>
                <Box mb="md">
                  <Label>
                    Origin{" "}
                    <Tooltip label="Pickup location for the units being transported">
                      <TooltipPrompt />
                    </Tooltip>
                  </Label>
                  <OriginDestinationAutocomplete
                    type="origin"
                    formikIdFieldKey="originId"
                    formikNameFieldKey="originName"
                    consignorClientId={formik.values.consignorId}
                  />
                  <FormikError name="originId" />
                </Box>

                <Box mb="md">
                  <Label>
                    Destination{" "}
                    <Tooltip label="Location where units are being delivered to or dropped off">
                      <TooltipPrompt />
                    </Tooltip>
                  </Label>
                  <OriginDestinationAutocomplete
                    type="destination"
                    formikIdFieldKey="destinationId"
                    formikNameFieldKey="destinationName"
                    consignorClientId={formik.values.consignorId}
                  />
                  <FormikError name="destinationId" />
                </Box>
              </Box>
              <Box flex={1}>
                <SectionTitle>Scheduling Details</SectionTitle>

                <Box mb="md">
                  <Label htmlFor="requestedEarliest">
                    Ready Date{" "}
                    <Tooltip label="Date the units will be ready for pick up">
                      <TooltipPrompt />
                    </Tooltip>
                  </Label>

                  <Field
                    as={StyledInput}
                    type="date"
                    name="requestedEarliest"
                    id="requestedEarliest"
                    min={formatISO(addDays(new Date(), 1), {
                      representation: "date",
                    })}
                    max={formatISO(addDays(new Date(), 7), {
                      representation: "date",
                    })}
                  />
                  <FormikError name="requestedEarliest" />
                </Box>

                <Box mb="md">
                  <Label htmlFor="dueLatest">
                    Due Date{" "}
                    <Tooltip label="Requested delivery date (transit timeline determined by mileage)">
                      <TooltipPrompt />
                    </Tooltip>
                  </Label>
                  <Field
                    as={StyledInput}
                    type="date"
                    name="dueLatest"
                    id="dueLatest"
                    disabled={true}
                    value={
                      formik.values.isRushSold
                        ? derivedDueLatestRushSold
                        : derivedDueLatestNoRushSold
                    }
                  />
                </Box>
              </Box>
            </TwoColumn>

            <TwoColumn>
              <CheckboxLine name={`isEnclosed`} displayName="Enclosed">
                <Box pl="xs">
                  <Tooltip label="Transport unit in an enclosed carrier for an additional fee">
                    <TooltipPrompt />
                  </Tooltip>
                </Box>
              </CheckboxLine>
              <CheckboxLine name={`isRushSold`} displayName="Rush Shipping?">
                <Box pl="xs">
                  <Tooltip label="Rush shipping total will be calcuated on the next screen.">
                    <TooltipPrompt />
                  </Tooltip>
                </Box>
              </CheckboxLine>
            </TwoColumn>

            <Box mt="lg">
              <SectionTitle>Vehicle Details</SectionTitle>
              <Label htmlFor="vins">
                Vehicle Identification Number (VIN){" "}
                <Tooltip
                  label={
                    <>
                      17-digit alphanumeric Vehicle Identification Number that
                      <br />
                      denotes the vehicle's unique characteristics and features,
                      <br />
                      including year, make and model.
                    </>
                  }
                >
                  <TooltipPrompt />
                </Tooltip>
              </Label>

              <Box display="flex">
                <Box display="flex" mr="sm" flex={1}>
                  <Field
                    as={StyledInput}
                    name="vins"
                    id="vins"
                    placeholder="Add Vin Number(s)"
                    disabled={vinRequestState.status === "loading"}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === "Enter") {
                        e.preventDefault()
                        handleVinAdd()
                      }
                    }}
                  />
                </Box>
                <Box display="flex">
                  <Button
                    bg="green"
                    type="button"
                    onClick={handleVinAdd}
                    disabled={vinRequestState.status === "loading"}
                  >
                    Add
                  </Button>
                </Box>
              </Box>
              <FormikError name="vehicles" />
              {vinRequestState.status === "error" ? (
                <>
                  error:
                  <ErrorIndicator error={vinRequestState.error} />
                </>
              ) : null}

              {vinRequestState.status === "idle" && (
                <>
                  {vinRequestState.vinsNotFound.length > 0 && (
                    <Text color="warning">
                      {pluralize(
                        vinRequestState.vinsNotFound.length,
                        `VIN`,
                        `VINS`,
                      )}{" "}
                      not found: {vinRequestState.vinsNotFound.join(", ")}
                    </Text>
                  )}

                  {vinRequestState.vinsOverVsf.length > 0 && (
                    <Text color="warning">
                      {pluralize(
                        vinRequestState.vinsOverVsf.length,
                        `VIN`,
                        `VINs`,
                      )}{" "}
                      {vinRequestState.vinsOverVsf.join(", ")}{" "}
                      {pluralize(
                        vinRequestState.vinsOverVsf.length,
                        `was`,
                        `were`,
                      )}{" "}
                      not added to your quote because there are too many
                      vehicles. Please remove one and try again.
                    </Text>
                  )}
                </>
              )}
            </Box>
            <FieldArray name="vehicles">
              {(arrayHelpers) => (
                <>
                  {formik.values.vehicles.map(({ vehicle }, index) => (
                    <VehicleContainer key={vehicle.Vin}>
                      <VehicleBasics vehicle={vehicle} />
                      <VehicleControlContainer>
                        <CheckboxLine
                          name={`vehicles.${index}.fields.inop`}
                          displayName="Inoperable"
                          tooltipText={
                            <>
                              Vehicle <strong>does not roll</strong>, brake or
                              steer; does not run, dead battery, out of gas, no
                              engine, etc.
                            </>
                          }
                        />
                      </VehicleControlContainer>
                      <VehicleControlContainer>
                        <CheckboxLine
                          name={`vehicles.${index}.fields.hasKeys`}
                          displayName="Has Keys"
                          tooltipText="Keys are with the vehicle"
                        />
                      </VehicleControlContainer>
                      <VehicleControlContainer>
                        <SecondaryButton
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          REMOVE
                        </SecondaryButton>
                      </VehicleControlContainer>
                    </VehicleContainer>
                  ))}
                </>
              )}
            </FieldArray>
            <Box mt="xxl" mb="xxl">
              <StyledButton
                bg="green"
                type="submit"
                disabled={formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading…" : "Get Quote"}
              </StyledButton>
            </Box>
            {/* <Box>
              <h2>DEBUG</h2>
              <pre>
                {JSON.stringify(
                  { form: formik.values, x: originResult.data ?? null },
                  null,
                  2,
                )}
              </pre>
            </Box> */}
          </Box>
        </Container>
      </Form>
    </FormikProvider>
  )
}

const StyledButton = styled(Button)({ width: "50%" })

const OriginDestinationAutocomplete = (props: {
  type: "origin" | "destination"
  formikIdFieldKey: keyof Fields
  formikNameFieldKey: keyof Fields
  consignorClientId: string
}) => {
  const formik = useFormikContext<Fields>()
  const request = useRequest()
  const currentUser = useCurrentUserQuery()

  const clientTypeMap: Record<typeof props.type, string> = {
    origin: "Origin",
    destination: "Destination",
  }
  const [term, setTerm] = useState("")

  const searchResult = useQuery<Array<{ Name: string; Id: string }>>(
    [
      ["order", { search: term }, "images"],
      {
        path: `/api/v2/Clients/ClientAutoComplete/${encodeURIComponent(term)}`,
        method: "GET",
        query: {
          clientType: clientTypeMap[props.type],
          fullInfo: "true",
          onlyClientsOnOrdersForConsignorId: props.consignorClientId,
          searchType: "contains",
          onlyClientsOnOrdersWithStatus: [],
          searchByNameAndAddress: "true",
        },
      },
    ],
    request,
    {
      enabled: currentUser.status === "success" && term.length > 0,
    },
  )

  const selectedId = formik.values[props.formikIdFieldKey]

  if (selectedId !== "") {
    let name = formik.values[props.formikNameFieldKey]
    invariant(typeof name === "string")
    return (
      <Box display="flex" justifyContent="space-between">
        <ResultName name={name} />

        <RemoveButton
          onClick={() => {
            formik.setFieldValue(props.formikIdFieldKey, "")
            formik.setFieldValue(props.formikNameFieldKey, null)
          }}
          title="Remove"
          type="button"
        >
          ✕
        </RemoveButton>
      </Box>
    )
  }

  return (
    <Combobox
      aria-label={props.type}
      onSelect={(name) => {
        const id = searchResult.data?.find((result) => result.Name === name)?.Id
        invariant(id, `expected id`)
        formik.setFieldValue(props.formikIdFieldKey, id)
        formik.setFieldValue(props.formikNameFieldKey, name)
      }}
    >
      <StyledComboboxInput
        onChange={(e) => {
          setTerm(e.target.value)
        }}
      />
      <ComboboxPopover>
        <ComboboxList>
          {searchResult.isLoading ? (
            <Box m="xs">Loading...</Box>
          ) : searchResult.isError ? (
            <ErrorIndicator error={searchResult.error} />
          ) : searchResult.data ? (
            searchResult.data.length === 0 ? (
              <Box m="xs">No matches found</Box>
            ) : (
              searchResult.data.map((entry) => (
                <ComboboxOption key={entry.Id} value={entry.Name}>
                  <ResultName name={entry.Name} />
                </ComboboxOption>
              ))
            )
          ) : null}
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  )
}

const RemoveButton = styled.button({
  border: 0,
  background: "transparent",
  marginLeft: 5,
  cursor: "pointer",
})
