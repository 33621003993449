import styled from "@emotion/styled"
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuLink,
  MenuPopover,
} from "@reach/menu-button"
// We don't need the styles for @reach/menu-button, but it sounds like it's still a good
// idea to include this CSS file for accessibility reasons
import "@reach/menu-button/styles.css"
import { useMemo } from "react"
import { useMediaQuery } from "react-responsive"
import { NavLink, useLocation } from "react-router-dom"
import { theme } from "../../emotion/theme"
import {
  accountPath,
  currentOrdersListPath,
  faqPath,
  historyOrdersListPath,
  inactiveOrdersListPath,
  listSavedViewsPath,
  myOrdersListPath,
  notificationsPath,
  searchOrdersPath,
  supportPath,
} from "../../paths"
import { useLoginContext } from "../../utils/contexts/LoginContext"
import { useCurrentUserQuery } from "../../utils/hooks/useCurrentUserQuery"
import {
  faq,
  hamburger,
  notifications,
  priority_icon_black,
  saveBlack,
  search,
  support,
} from "../../utils/icons"
import { Box } from "../Box"
import { InitialsAvatar } from "../InitialsAvatar"
import logo from "./ur_logo.png"
export const DESKTOP_NAV_HEIGHT = 64

export const NavBar = () => {
  const isMobile = useMediaQuery({
    maxWidth: theme.reactMq.max.desktop,
  })

  return isMobile ? <MobileNavBar /> : <DesktopNavBar />
}

const DesktopNavBar = () => {
  const location = useLocation()
  const { isLoggedIn } = useLoginContext()
  const isOrdersList = [
    currentOrdersListPath(),
    historyOrdersListPath(),
    inactiveOrdersListPath(),
  ].includes(location.pathname)
  const currentUserResult = useCurrentUserQuery()

  const isCreateOrderEnabled =
    (currentUserResult.data &&
      currentUserResult.data.Roles.includes("OrderEntry")) ||
    process.env.REACT_APP_RESTRICT_ORDER_ENTRY === "anyone"

  return (
    <NavBarContainer
      style={{
        position: isOrdersList ? "sticky" : "initial",
        top: "0",
        backgroundColor: "#ffffff",
        zIndex: 1000,
        height: DESKTOP_NAV_HEIGHT,
      }}
    >
      <Box mr="xxl">
        <NavLink to={currentOrdersListPath()}>
          <img height={36} src={logo} alt="United Road" />
        </NavLink>
      </Box>
      {isLoggedIn && (
        <>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            flex={1}
            style={{ marginLeft: 100 }}
          >
            {isCreateOrderEnabled ? (
              <Box mr="xxl">
                <StyledDesktopNavLink
                  to={myOrdersListPath()}
                  selected={location.pathname === myOrdersListPath()}
                >
                  My Orders
                </StyledDesktopNavLink>
              </Box>
            ) : null}
            <Box mr="xxl">
              <StyledDesktopNavLink
                to={currentOrdersListPath()}
                selected={location.pathname === currentOrdersListPath()}
              >
                Current
              </StyledDesktopNavLink>
            </Box>
            <Box mr="xxl">
              <StyledDesktopNavLink
                to={historyOrdersListPath()}
                selected={location.pathname === historyOrdersListPath()}
              >
                History
              </StyledDesktopNavLink>
            </Box>
            <Box mr="xxl">
              <StyledDesktopNavLink
                to={inactiveOrdersListPath()}
                selected={location.pathname === inactiveOrdersListPath()}
              >
                Inactive
              </StyledDesktopNavLink>
            </Box>
            {/* <Box mr="xxl">
              <StyledDesktopNavLink
                to={inboundOrdersListPath()}
                selected={location.pathname === inboundOrdersListPath()}
              >
                Inbound
              </StyledDesktopNavLink>
            </Box>
            <Box mr="xxl">
              <StyledDesktopNavLink
                to={outboundOrdersListPath()}
                selected={location.pathname === outboundOrdersListPath()}
              >
                Outbound
              </StyledDesktopNavLink>
            </Box> */}
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box mr="sm">
              <NavLink to={faqPath()}>
                <img src={faq} alt="help" />
              </NavLink>
            </Box>
            <Box mr="sm">
              <NavLink to={notificationsPath()}>
                <img src={notifications} alt="notifications" />
              </NavLink>
            </Box>
            <DesktopUserMenu />
          </Box>
        </>
      )}
    </NavBarContainer>
  )
}

const MobileNavBar = () => {
  const { isLoggedIn } = useLoginContext()
  const location = useLocation()

  const shouldShowSearch = useMemo(
    () => location.pathname !== accountPath(),
    [location],
  )

  return (
    <NavBarContainer>
      <HamburgerContainer>{isLoggedIn && <HamburgerMenu />}</HamburgerContainer>
      <NavLink to={currentOrdersListPath()}>
        <img height={36} src={logo} alt="United Road" />
      </NavLink>
      <SearchContainer>
        {isLoggedIn && shouldShowSearch && (
          <NavLink to={searchOrdersPath()}>
            <img src={search} alt="sorting and filters" />
          </NavLink>
        )}
      </SearchContainer>
    </NavBarContainer>
  )
}

const HamburgerMenu = () => {
  const { logout } = useLoginContext()
  const { data: user } = useCurrentUserQuery()

  // If we haven't fetched our user data yet, use a placeholder name
  const displayName = user ? user.FirstName : "user"

  return (
    <Menu>
      {({ isExpanded }) => (
        <>
          {isExpanded && <BlackBG />}
          <StyledMenuButton>
            <img src={hamburger} alt="Menu" />
          </StyledMenuButton>
          <StyledMenuPopover portal={false}>
            <StyledMenuItems>
              <MenuLink as={StyledNavLinkBlack} to={accountPath()}>
                Hi {displayName}!
              </MenuLink>
              <MenuLink as={ArrowNavLink} to={currentOrdersListPath()}>
                Current Orders
              </MenuLink>
              <MenuBreak />
              <MenuLink as={ArrowNavLink} to={historyOrdersListPath()}>
                Order History
              </MenuLink>
              <MenuBreak />
              <MenuLink as={ArrowNavLink} to={inactiveOrdersListPath()}>
                Inactive Orders
              </MenuLink>
              <MenuBreak />
              <ArrowMenuItem onSelect={logout}>Logout</ArrowMenuItem>
              <MenuBreak />
              <MenuLink as={StyledNavLinkMinor} to={notificationsPath()}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box mr="xs" justifyContent="center" alignItems="center">
                    <img
                      src={notifications}
                      width="24"
                      height="24"
                      alt="priority orders"
                    />
                  </Box>
                  Notifications
                </Box>
              </MenuLink>
              <MenuLink
                as={StyledNavLinkMinor}
                to={{
                  pathname: currentOrdersListPath(),
                  search: "?showPriorityOnly=true",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box mr="xs" justifyContent="center" alignItems="center">
                    <img
                      src={priority_icon_black}
                      width="24"
                      height="24"
                      alt="priority orders"
                    />
                  </Box>
                  Favorite Orders
                </Box>
              </MenuLink>
              <MenuLink as={StyledNavLinkMinor} to={listSavedViewsPath()}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box mr="xs" justifyContent="center" alignItems="center">
                    <img
                      src={saveBlack}
                      width="24"
                      height="24"
                      alt="saved views"
                    />
                  </Box>
                  Saved Views
                </Box>
              </MenuLink>
              <MenuLink as={StyledNavLinkMinor} to={supportPath()}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box mr="xs" justifyContent="center" alignItems="center">
                    <img src={support} width="24" height="24" alt="support" />
                  </Box>
                  Support
                </Box>
              </MenuLink>
              <MenuLink as={StyledNavLinkMinor} to={faqPath()}>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box mr="xs" justifyContent="center" alignItems="center">
                    <img src={faq} width="24" height="24" alt="help" />
                  </Box>
                  FAQs
                </Box>
              </MenuLink>
            </StyledMenuItems>
          </StyledMenuPopover>
        </>
      )}
    </Menu>
  )
}

function DesktopUserMenu() {
  const { logout } = useLoginContext()
  const { data: user } = useCurrentUserQuery()

  return (
    <Menu>
      <StyledMenuButton>
        <InitialsAvatar
          firstName={user?.FirstName}
          lastName={user?.LastName}
          backgroundColor="black"
        />
      </StyledMenuButton>

      <DesktopMenuPopover portal={false}>
        <StyledMenuItems>
          <MenuLink as={StyledNavLink} to={accountPath()}>
            View Profile
          </MenuLink>
          <StyledMenuItem onSelect={logout}>Logout</StyledMenuItem>
        </StyledMenuItems>
      </DesktopMenuPopover>
    </Menu>
  )
}

const NavBarContainer = styled.div((props) => ({
  margin: "0 auto",
  padding: props.theme.space.sm,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
}))

const HamburgerContainer = styled.div({
  flex: 1,
})

const SearchContainer = styled.div({
  textAlign: "right",
  flex: 1,
})

const BlackBG = styled.div({
  zIndex: 1,
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  height: "100%",
  width: "100%",
  top: 0,
  left: 0,
  position: "absolute",
})

const StyledMenuItems = styled(MenuItems)({
  backgroundColor: "white",
  width: "100%",
  opacity: "100%",
  margin: 0,
  padding: 0,
  border: "none",
})

const StyledMenuPopover = styled(MenuPopover)((props) => ({
  zIndex: 1,
  backgroundColor: "white",
  position: "absolute",
  left: 0,
  top: props.theme.navbar.heights.sm,
  height: `calc(min(100%, 100vh) - ${props.theme.navbar.heights.sm}px)`,
  fontSize: props.theme.text.sizes.xl,
  fontWeight: props.theme.text.weights.med,
}))

const DesktopMenuPopover = styled(StyledMenuPopover)((props) => ({
  height: "unset",
  border: `1px solid ${props.theme.color.medGray}`,
  right: 0,
  left: "unset",
}))

const StyledMenuItem = styled(MenuItem)((props) => ({
  padding: props.theme.space.sm,
}))

const ArrowMenuItem = styled(StyledMenuItem)({
  "paddingLeft": 19,
  "position": "relative",

  "&:after": {
    content: '""',
    position: "absolute",
    clear: "both",
    // Hand-picked values to make the arrows look approximately correct. No math, just eyeballed values
    right: 20,
    top: 18,
    bottom: 18,
    borderTop: "5px solid transparent",
    borderBottom: "5px solid transparent",
    borderLeft: "8px solid black",
  },
})

const StyledNavLink = styled(NavLink)((props) => ({
  padding: props.theme.space.sm,
}))

const ArrowNavLink = styled(StyledNavLink)({
  "paddingLeft": 19,
  "position": "relative",

  "&:after": {
    content: '""',
    position: "absolute",
    clear: "both",
    // Hand-picked values to make the arrows look approximately correct. No math, just eyeballed values
    right: 20,
    top: 18,
    bottom: 18,
    borderTop: "5px solid transparent",
    borderBottom: "5px solid transparent",
    borderLeft: "8px solid black",
  },
})

const StyledNavLinkMinor = styled(NavLink)((props) => ({
  padding: props.theme.space.sm,
  paddingLeft: 19,
  position: "relative",
  fontSize: props.theme.text.sizes.sm,
  color: props.theme.color.darkGray,
}))

const MenuBreak = styled.div((props) => ({
  backgroundColor: props.theme.color.lightGray,
  width: 252,
  height: 1,
  margin: "0 auto",
}))

const StyledMenuButton = styled(MenuButton)({
  "border": "none",
  "margin": 0,
  "padding": 0,
  "width": "auto",
  "overflow": "visible",
  "background": "transparent",
  "color": "inherit",
  "font": "inherit",
  "textAlign": "inherit",
  "lineHeight": "normal",
  "WebkitFontSmoothing": "inherit",
  "MozOsxFontSmoothing": "inherit",
  "WebkitAppearance": "none",
  "cursor": "pointer",
  "&::-moz-focus-inner": {
    border: 0,
    padding: 0,
  },
})

const StyledNavLinkBlack = styled(StyledNavLink)((props) => ({
  "paddingLeft": 19,
  "position": "relative",
  "backgroundColor": props.theme.color.black,
  "color": props.theme.color.white,
  "&:hover": {
    backgroundColor: props.theme.color.black,
  },
}))

const StyledDesktopNavLink = styled(NavLink)<{
  selected: boolean
}>((props) => ({
  color: props.selected ? props.theme.color.blue : props.theme.color.darkGray,
  fontWeight: props.selected
    ? props.theme.text.weights.bold
    : props.theme.text.weights.reg,
  fontSize: props.theme.text.sizes.lg,
  textDecoration: props.selected ? "underline" : "none",
  textDecorationThickness: 3,
  textDecorationSkipInk: "none",
}))
